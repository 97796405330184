import React, { useState, useCallback, useEffect } from "react";
import { ReactTags } from "react-tag-autocomplete";

const ObjectSelector = ({ disabled, values, suggestions, onChange }) => {
  // console.log("values", values, suggestions);

  const [selected, setSelected] = useState([]);

  // useEffect(() => {
  //   onChange(selected);
  // }, [selected]);

  useEffect(() => {
    // values updated from parent
    if (values && values.length > 0) {
      shouldUpdateSelected(values);
    }
  }, [values]);

  const shouldUpdateSelected = (parentValues) => {
    if (JSON.stringify(parentValues) !== JSON.stringify(selected)) {
      setSelected(parentValues);
    }
  };

  const onAdd = useCallback(
    (newTag) => {
      setSelected([...selected, newTag]);
    },
    [selected],
  );

  const onDelete = useCallback(
    (tagIndex) => {
      setSelected(selected.filter((_, i) => i !== tagIndex));
    },
    [selected],
  );

  const onBlur = useCallback(() => {
    onChange(selected);
  }, [selected]);

  return (
    <ReactTags
      labelText="Select objects"
      isDisabled={disabled}
      selected={selected}
      suggestions={suggestions}
      onAdd={onAdd}
      onDelete={onDelete}
      onBlur={onBlur}
      noOptionsText="No matching objects"
      placeholderText="type objects here"
      classNames={{
        root: "react-tags",
        rootIsActive: "is-active",
        rootIsDisabled: "is-disabled",
        rootIsInvalid: "is-invalid",
        label: "react-tags__label",
        tagList: "react-tags__list",
        tagListItem: "react-tags__list-item",
        tag: "react-tags__tag",
        tagName: "react-tags__tag-name",
        comboBox: "react-tags__combobox",
        input: "react-tags__combobox-input",
        listBox: "react-tags__listbox",
        option: "react-tags__listbox-option",
        optionIsActive: "is-active",
        highlight: "react-tags__listbox-option-highlight",
      }}
    />
  );
};

export default ObjectSelector;
