import styled from "styled-components/macro";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import BackgroundGradientImg from "../../assets/images/models_gradient_background.png";

const styles = {
  MobileNavContainer: styled.aside`
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    /* background: #a9a9a9; */
    background-image: url(${BackgroundGradientImg});
    transition: 0ms.1s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
    top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
  `,
  CloseIcon: styled(FaTimes)`
    color: #000000;
  `,
  Icon: styled.div`
    position: absolute;
    top: 2.3rem;
    right: 1.8rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
  `,
  MobileNavMenu: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  `,
  MobileNavLink: styled(Link)`
    font-family: CircularStdNormal;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    color: #37474f;
    cursor: pointer;

    &:hover {
      color: #000000;
      transform: 0.2s ease-in-out;
    }
  `,
  MobileNaveItem: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #fff;
    cursor: pointer;

    &:hover {
      color: #000000;
      transform: 0.2s ease-in-out;
    }
  `,
};

export default styles;
