import styled from "styled-components/macro";
import { ReactComponent as DeleteIcon } from "../../assets/icons/garbage_gray_icon.svg";

const styles = {
  DragAndDropCardContainer: styled.div`
    display: flex;
    position: relative;
    margin-bottom: 5px;
  `,
  DeleteIconContainer: styled.div`
    cursor: pointer;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: -5px;
    left: 60px;
    border: 1px solid black;
    background-color: white;
    width: 25px;
    height: 25px;
    border-radius: 50%;
  `,
  DeleteIcon: styled(DeleteIcon)`
    display: flex;
    width: 18px;
    height: 18px;
  `,
  DragAndDropCard: styled.div`
    display: flex;
    margin-right: 15px;
    width: 75px;
    height: 75px;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 5px;
    background-color: olivedrab;
    color: white;
    padding: 0px 5px 0px 5px;
    border: ${({ newCard }) => (newCard ? "2px black dashed" : "2px black solid")};
  `,
};

export default styles;
