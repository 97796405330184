import React, { useState, createContext, useContext } from "react";
import { toast } from "react-toastify";
import ToastNotification from "../components/ToastNotification";
import ModalDefault from "../components/Shared/Modal";

export const ToastModalContext = createContext();

export const useToastModal = () => useContext(ToastModalContext);

const ToastModalProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({ title: "", isInfoModal: false, func: null });

  const addToast = (title, type) => {
    switch (type) {
      case "success":
        toast.success(<ToastNotification message={title} />);
        break;
      case "error":
        toast.error(<ToastNotification message={title} />);
        break;
      case "warning":
        toast.warning(<ToastNotification message={title} />);
        break;
      default:
        toast(<ToastNotification message={title} />);
    }
  };

  const addModal = (title, isInfoModal = false, ...args) => {
    const func = args.pop();
    const extra = args.pop() || {};
    setShowModal(true);
    setModalData({ title, isInfoModal, func, extra });
  };

  const handleModalClose = () => {
    setShowModal(false);
    setModalData({ title: "", isInfoModal: false, func: null });
  };

  const handleModalForwardClick = () => {
    modalData.func && modalData.func();
    handleModalClose();
  };

  return (
    <ToastModalContext.Provider value={{ addModal, addToast }}>
      {children}
      <ModalDefault
        showModal={showModal}
        closeModal={handleModalClose}
        title={modalData.title}
        subtitle={modalData.extra?.subtitle}
        handleModalForwardClick={handleModalForwardClick}
        infoModal={modalData.isInfoModal}
      />
    </ToastModalContext.Provider>
  );
};

export default ToastModalProvider;
