import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/authContext";

const Logout = () => {
  const { handleLogOut } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    handleLogOut();
    window.location.href = "/";
  }, [handleLogOut, navigate]);

  return <div>Logout</div>;
};

export default Logout;
