import React from "react";
//Styles
import Styles from "./styles";

const FormPageLayout = (props) => {
  const { formComponent, children, isSignIn } = props;

  return (
    <Styles.PageContainer>
      <Styles.AlignmentContainer>
        <Styles.AuthWrapper isSignIn>
          {!isSignIn && <Styles.Column leftColumn>{children}</Styles.Column>}
          <Styles.Column isSignIn>{formComponent}</Styles.Column>
        </Styles.AuthWrapper>
      </Styles.AlignmentContainer>
    </Styles.PageContainer>
  );
};

export default FormPageLayout;
