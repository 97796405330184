import { ApolloClient, InMemoryCache, from, HttpLink, split } from "@apollo/client";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { RENEW_TOKEN } from "../../graphql/user";
import { getUri } from "../configEnviroment";

const getRefreshToken = () => {
  const refreshToken = localStorage.getItem("refresh_token");
  return refreshToken;
};

const getNewToken = () => {
  return apolloClient
    .query({
      query: RENEW_TOKEN,
      variables: {
        refreshToken: getRefreshToken(),
      },
      context: { clientName: "user" },
    })
    .then((response) => {
      // extract your accessToken from your response data and return it
      const { refreshToken, token, expiresIn } = response.data.renewToken;
      // store token in async
      localStorage.setItem("access_token", token);
      localStorage.setItem("expires_in", Math.round(Date.now() / 1000) + expiresIn);
      localStorage.setItem("refresh_token", refreshToken);
      return token;
    })
    .catch((error) => {
      // Handle token refresh errors e.g clear stored tokens, redirect to login
      window.location.href = "/logout";
      return;
    });
};

const wsLink = new GraphQLWsLink(
  createClient({
    url: `wss://${getUri()}/subscriptions`,
    // url: "ws://localhost:4000/subscriptions",
    connectionParams: () => {
      // Note: getSession() is a placeholder function created by you
      const token = localStorage.getItem("access_token");
      if (!token) {
        return {};
      }
      return {
        authorization: `Bearer ${token}`,
      };
    },
  }),
);

const graphLink = new HttpLink({
  uri: `https://${getUri()}/graph`,
  // uri: "http://localhost:4000/graph",
});

const userLink = new HttpLink({
  uri: `https://${getUri()}/user`,
  // uri: "http://localhost:4000/user",
});

const mergedGraphAndUserLink = split(
  (operation) => operation.getContext().clientName === "user", // Routes the query to the proper client
  userLink,
  graphLink,
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  mergedGraphAndUserLink,
);

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Extensions: ${extensions}`,
      );
    });

  if (networkError && networkError.statusCode === 400 && networkError.result.errors) {
    // get first error
    const [error] = networkError.result.errors;

    // get extensions and code
    const { extensions } = error;
    const { code } = extensions;

    if (code === "UNAUTHENTICATED") {
      window.location.href = "/logout";
      return;
    }
  }
});

function isTokenNeeded(operationName) {
  switch (operationName) {
    case "renewToken":
    case "signIn":
    case "register":
    case "forgotPassword":
    case "checkInvite":
    case "completeRegister":
    case "forgotPasswordComplete":
    case "forgotPasswordCheck":
    case "talkToUs":
    case "requestAccount":
    case "createInference":
    case "processInference":
      return true;
    default:
      return false;
  }
}

async function returnTokenDependingOnOperation(operation) {
  if (isTokenNeeded(operation.operationName)) {
    return "";
  }

  // check if token is expired
  const tokenExpiresIn = localStorage.getItem("expires_in");
  const currentNumericDate = Math.round(Date.now() / 1000);

  if (tokenExpiresIn && tokenExpiresIn < currentNumericDate) {
    // expired
    return getNewToken();
  }

  return localStorage.getItem("access_token");
}

// applies the token to the headers
const authLink = setContext(async (operation, { headers }) => {
  const token = await returnTokenDependingOnOperation(operation);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const apolloClient = new ApolloClient({
  link: from([errorLink, authLink, splitLink]),
  cache: new InMemoryCache(),
  /* defaultOptions: defaultOptions, */
});
