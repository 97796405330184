import styled from "styled-components/macro";

const styles = {
  Card: styled.div`
    background: ${(props) => (props.blueBack ? "#ACD7DE" : "#FEBCBB")};
    border-radius: 11.7143px;
    /* width: 349.09px; */
    height: 125px;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  `,
  Title: styled.h2`
    font-family: "Poppins500";
    font-size: 11.7143px;
    line-height: 18px;
    color: #6b6f7b;
  `,
  Count: styled.span`
    font-family: "Poppins600";
    font-size: 46.8571px;
    line-height: 70px;
    color: #000000;
  `,
};

export default styles;
