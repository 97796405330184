export const devUri = "apidev-internal.padme.ai";

export const prodUri = "api-internal.padme.ai";

export const rtspDomain = ".lotuslabs.co";

export const onlineThreshold = 5 * 60;

const crStatus = ["Not started", "Starting", "In progress", "Completed"];
crStatus[-1] = "Error";
crStatus[-2] = "Cancelled";

const arStatus = ["Not started", "In progress", "Completed"];
arStatus[-1] = "Cancelled";

const mtStatus = ["Pending", "Starting", "In progress", "Completed", "Deployed"];
mtStatus[-1] = "Error";
mtStatus[-2] = "Cancelled";

export const rentedStatus = ["Starting", "Running", "Stopped"];

export const basicStatus = ["Disabled", "Enabled"];

export const getUri = () =>
  window.location.href.includes("dev.padme") ||
  window.location.href.includes("localhost") ||
  window.location.href.includes("next.padme")
    ? devUri
    : prodUri;

export const modelTypes = [
  { value: "object_detection", label: "Object Detection" },
  { value: "density_estimation", label: "Density Estimation" },
];

export const machineModelData = [
  {
    title: "Crowd Estimation",
    key: "crowd_estimation",
    type: "density_estimation",
    requiresCustomModel: true,
    inferenceCPU: {
      key: "crowd_estimation_inferenceCPU",
      title: "Inference Interval (seconds)",
      defaultValue: 30,
      valueType: "seconds",
      step: 5,
      min: 1,
      max: 60,
    },
    inferenceGPU: {
      key: "crowd_estimation_inferenceGPU",
      title: "Inference Interval (seconds)",
      defaultValue: 15,
      valueType: "seconds",
      step: 5,
      min: 1,
      max: 60,
    },
    sort: 1,
    status: 1,
  },
  {
    title: "Object Counting",
    key: "object_counting",
    type: "object_detection",
    requiresCustomModel: true,
    inferenceCPU: {
      key: "object_counting_inferenceCPU",
      title: "Inference Interval (seconds)",
      defaultValue: 5,
      valueType: "seconds",
      step: 5,
      min: 1,
      max: 60,
    },
    inferenceGPU: {
      key: "object_counting_inferenceGPU",
      title: "Inference Interval (seconds)",
      defaultValue: 2,
      valueType: "seconds",
      step: 5,
      min: 1,
      max: 60,
    },
    sort: 2,
    status: 1,
  },
  {
    title: "Ingress/Egress",
    key: "ingress/egress",
    type: "object_detection",
    requiresCustomModel: true,
    inferenceCPU: {
      key: "ingress/egress_inferenceCPU",
      title: "Inference Interval (% of processed FPS)",
      defaultValue: 0.3,
      valueType: "fps",
      step: 0.1,
      min: 0.1,
      max: 1,
    },
    inferenceGPU: {
      key: "ingress/egress_inferenceGPU",
      title: "Inference Interval (% of processed FPS)",
      defaultValue: 0.5,
      valueType: "fps",
      step: 0.1,
      min: 0.1,
      max: 1,
    },
    sort: 3,
    status: 1,
  },
  {
    title: "Queue Time",
    key: "queue_time",
    type: "object_detection",
    requiresCustomModel: true,
    inferenceCPU: {
      key: "queue_time_inferenceCPU",
      title: "Inference Interval (% of processed FPS)",
      defaultValue: 0.3,
      valueType: "fps",
      step: 0.1,
      min: 0.1,
      max: 1,
    },
    inferenceGPU: {
      key: "queue_time_inferenceGPU",
      title: "Inference Interval (% of processed FPS)",
      defaultValue: 0.5,
      valueType: "fps",
      step: 0.1,
      min: 0.1,
      max: 1,
    },
    sort: 4,
    status: 1,
  },
  {
    title: "Dwell Time",
    key: "dwell_time",
    type: "object_detection",
    requiresCustomModel: false,
    inferenceCPU: {
      key: "dwell_time_inferenceCPU",
      title: "Inference Interval (% of processed FPS)",
      defaultValue: 0.3,
      valueType: "fps",
      step: 0.1,
      min: 0.1,
      max: 1,
    },
    inferenceGPU: {
      key: "dwell_time_inferenceGPU",
      title: "Inference Interval (% of processed FPS)",
      defaultValue: 0.5,
      valueType: "fps",
      step: 0.1,
      min: 0.1,
      max: 1,
    },
    sort: 5,
    status: 1,
  },
  {
    title: "Behavior Analysis",
    key: "behavior_analysis",
    type: "object_detection",
    requiresCustomModel: false,
    inferenceCPU: {
      key: "behavior_analysis_inferenceCPU",
      title: "Inference Interval (% of processed FPS)",
      defaultValue: 0.3,
      valueType: "fps",
      step: 0.1,
      min: 0.1,
      max: 1,
    },
    inferenceGPU: {
      key: "behavior_analysis_inferenceGPU",
      title: "Inference Interval (% of processed FPS)",
      defaultValue: 0.5,
      valueType: "fps",
      step: 0.1,
      min: 0.1,
      max: 1,
    },
    sort: 6,
    status: 1,
  },
  {
    title: "License Plate Recognition",
    key: "lic_plate_recog",
    type: "object_detection",
    requiresCustomModel: false,
    inferenceCPU: {
      key: "lic_plate_recog_inferenceCPU",
      title: "Inference Interval (% of processed FPS)",
      defaultValue: 0.3,
      valueType: "fps",
      step: 0.1,
      min: 0.1,
      max: 1,
    },
    inferenceGPU: {
      key: "lic_plate_recog_inferenceGPU",
      title: "Inference Interval (% of processed FPS)",
      defaultValue: 0.5,
      valueType: "fps",
      step: 0.1,
      min: 0.1,
      max: 1,
    },
    sort: 7,
    status: 1,
  },
  {
    title: "Other",
    key: "other",
    type: "object_detection",
    requiresCustomModel: false,
    inferenceCPU: {
      key: "other_inferenceCPU",
      title: "Inference Interval (seconds)",
      defaultValue: 5,
      valueType: "seconds",
      step: 5,
      min: 1,
      max: 60,
    },
    inferenceGPU: {
      key: "other_inferenceGPU",
      title: "Inference Interval (seconds)",
      defaultValue: 2,
      valueType: "seconds",
      step: 5,
      min: 1,
      max: 60,
    },
    sort: 99,
    status: 1,
  },
];

export { crStatus, arStatus, mtStatus };
