import React, { useEffect, useState } from "react";
import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import payload from "../../../data/dockerControlPayload";
import "./styles.css";
import Styles from "../styles";
import RoundButton from "../../../components/Shared/Buttons/RoundButton";
import { ReactComponent as StopOrangeIcon } from "../../../assets/icons/stop_orange_icon.svg";
import { ReactComponent as PlayOrangeIcon } from "../../../assets/icons/play_orange_icon.svg";
import { ReactComponent as RefreshIcon } from "../../../assets/icons/refresh_icon.svg";
import { CgArrowLongRight } from "react-icons/cg";
import { useSubscription } from "@apollo/client";
import { DOCKER_CONTAINER_STATUS_UPDATED } from "../../../graphql/graph";
import { useToastModal } from "../../../context/ToastModalContext";

export const DockerControlModal = ({
  showModal,
  setShowModal,
  row,
  containerList,
  setContainerList,
}) => {
  const { data: dockerContainerStatusUpdate, loading: dockerContainerLoading } = useSubscription(
    DOCKER_CONTAINER_STATUS_UPDATED,
    {
      variables: { dockerId: row?.id },
    },
  );

  let cachedContainers = null;

  const { addToast } = useToastModal();

  //Check local storage for dockerContainerStatusCache
  useEffect(() => {
    if (localStorage.getItem("dockerContainerStatusCache") !== null) {
      // console.log("got it");
      cachedContainers = JSON.parse(localStorage.getItem("dockerContainerStatusCache"));
      // console.log(cachedContainers);
      let cache = cachedContainers.filter((cached) => Object.keys(cached).indexOf(row.id) > -1);
      // console.log(cache[0]);
      if (cache.length > 0) {
        setContainerList(cache[0][row.id]);
      }
    }
  }, [row.dockerLastPingTime]);

  useEffect(() => {
    if (dockerContainerStatusUpdate && dockerContainerStatusUpdate.dockerContainerStatusUpdated) {
      const { dockerContainerStatusUpdated } = dockerContainerStatusUpdate;
      const dockerContainerStatus = JSON.parse(dockerContainerStatusUpdated.data);

      // console.log("dockerContainerStatus", dockerContainerStatus);
      setContainerList(dockerContainerStatus);
      //update dockerContianerStatusCache
      localStorage.setItem(
        "dockerContainerStatusCache",
        JSON.stringify([{ [row.id]: dockerContainerStatus }]),
      );
      addToast("Containers Fetched Successfully", "success");
    }
  }, [dockerContainerStatusUpdate, dockerContainerLoading]);

  //To convert last pinged time into local time
  const timestamp = row?.dockerLastPingTime;
  const isMilliseconds = String(timestamp).length === 13;
  const date = isMilliseconds ? new Date(timestamp) : new Date(timestamp * 1000);

  const today = new Date();

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);

  const formattedTime = `${hours}:${minutes}`;
  const formattedDate =
    today.toDateString() === date.toDateString() ? "" : `${day}:${month}:${year}`;
  return (
    <ModalWithChildren
      showModal={showModal}
      setShowModal={setShowModal}
      title={`${row.venueName} Docker Control`}
    >
      <div className="docker-ctrl-controller">
        <div className="info-container">
          <Styles.Text>{`Docker: ${row.dockerName}`}</Styles.Text>
          <Styles.Text>{`Last Pinged: ${formattedTime} ${formattedDate} `}</Styles.Text>
        </div>

        {containerList ? (
          <>
            <div className="table-container">
              <table className="docker-ctrl-table">
                <thead>
                  <tr>
                    <th>Container Name</th>
                    <th>Memory</th>
                    <th>CPU</th>
                  </tr>
                </thead>
                <tbody>
                  {containerList?.activeContainerList.map((container) => (
                    <tr key={container.id}>
                      <td>{container.name}</td>
                      <td>{container.stats.mem}</td>
                      <td>{container.stats.cpu}</td>
                      <td>
                        <RoundButton icon={<RefreshIcon />} tooltipText={"Refresh Container"} />
                      </td>
                      <td>
                        <RoundButton icon={<StopOrangeIcon />} tooltipText={"Stop Container"} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="table-container">
              <table className="docker-ctrl-table">
                <thead>
                  <tr>
                    <th>Container Name</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {containerList?.nonActiveContainerList.map((container) => (
                    <tr key={container.id}>
                      <td>{container.name}</td>
                      <td>{container.status}</td>
                      <td>
                        <RoundButton icon={<PlayOrangeIcon />} tooltipText={"Run Container"} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="btngrp">
              <button>Add Task</button>
              <button>Remove Task</button>
              <button>Restart All</button>
              <button>Start All</button>
              <button>Stop All</button>
            </div>
          </>
        ) : (
          <>
            <Styles.LoadingMessage>{`Fetching ${row.dockerName} Containers...`}</Styles.LoadingMessage>
            <Styles.InfoMessage>
              {"Note: Please Do not close while connection with docker is being established..."}
            </Styles.InfoMessage>
          </>
        )}
      </div>
    </ModalWithChildren>
  );
};
