import React from "react";
//Styles
import Styles from "./styles";
//Tools
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import usePreventEnterAction from "../../hooks/PreventCloseOnEnterHook";

const tryModelFormlSchema = yup.object().shape({
  userUrl: yup.string().notRequired(),
});

const TryModelForm = ({ setUserUrl, setShowModal }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(tryModelFormlSchema),
  });

  const submitForm = (tryModelFormData) => {
    setShowModal((prev) => !prev);
    setUserUrl(tryModelFormData.userUrl);
    reset();
  };

  usePreventEnterAction(submitForm);

  return (
    <Styles.Form onSubmit={handleSubmit(submitForm)}>
      <Styles.InputAndErrorContainer>
        <Styles.Input
          type="text"
          name="userUrl"
          placeholder="Image Url ..."
          {...register("userUrl")}
        />
        <Styles.SubmitError>{errors.userUrl?.message}</Styles.SubmitError>
      </Styles.InputAndErrorContainer>
      <Styles.ButtonsContainer>
        <Styles.SubmitInput type="submit" id="submit" value="Try Model" dark />
      </Styles.ButtonsContainer>
    </Styles.Form>
  );
};

export default TryModelForm;
