import React from "react";
import { SVGHelper } from "./SVGHelpers";

export const withDraggable = (Component) =>
  class DraggableHOC extends React.Component {
    ref = React.createRef();
    svg = new SVGHelper(() => this.ref?.current?.ownerSVGElement);
    dragLastPosition = null;
    wasMoved = false;
    render() {
      const { draggable, onDrag, onDragStart, onDragEnd, ...rest } = this.props;
      return <Component ref={this.ref} {...rest} draggable={draggable} />;
    }
    componentDidUpdate(prevProps) {
      if (prevProps.draggable && !this.props.draggable) {
        // cleanup after props.draggable changed to false
        if (this.dragLastPosition && this.wasMoved) {
          if (this.props.onDragEnd) {
            this.props.onDragEnd({
              x: this.dragLastPosition.x,
              y: this.dragLastPosition.y,
            });
          }
          this.dragLastPosition = null;
          this.wasMoved = false;
        }
      }
    }
    componentDidMount() {
      window.addEventListener("mousedown", this.onMouseTouchDown, true);
      window.addEventListener("mousemove", this.onMouseTouchMove, true);
      window.addEventListener("mouseup", this.onMouseTouchUp, true);
      window.addEventListener("touchstart", this.onMouseTouchDown, true);
      window.addEventListener("touchmove", this.onMouseTouchMove, true);
      window.addEventListener("touchend", this.onMouseTouchUp, true);
    }
    componentWillUnmount() {
      window.removeEventListener("mousedown", this.onMouseTouchDown);
      window.removeEventListener("mousemove", this.onMouseTouchMove);
      window.removeEventListener("mouseup", this.onMouseTouchUp);
      window.removeEventListener("touchstart", this.onMouseTouchDown);
      window.removeEventListener("touchmove", this.onMouseTouchMove);
      window.removeEventListener("touchend", this.onMouseTouchUp);
    }
    getMousePosition(ev) {
      const e = ev;
      return this.svg.getMouseCoordinates(e);
    }
    onMouseTouchDown = (e) => {
      if (e.target === this.ref.current && this.props.draggable) {
        e.stopImmediatePropagation();
        e.preventDefault();
        const target = e.target;
        this.dragLastPosition = this.getMousePosition(e);
        if (target.ownerSVGElement) {
          target.ownerSVGElement.focus({ preventScroll: true });
        }
      }
    };
    onMouseTouchMove = (e) => {
      if (this.dragLastPosition) {
        e.stopImmediatePropagation();
        e.preventDefault();
        const { x, y } = this.getMousePosition(e);
        const dx = x - this.dragLastPosition.x;
        const dy = y - this.dragLastPosition.y;
        if (!this.wasMoved && this.props.onDragStart) {
          this.props.onDragStart({
            x: this.dragLastPosition.x,
            y: this.dragLastPosition.y,
            dx,
            dy,
          });
        }
        if (this.props.onDrag) {
          this.props.onDrag({ dx, dy });
        }
        this.dragLastPosition = { x, y };
        this.wasMoved = true;
      }
    };
    onMouseTouchUp = (e) => {
      if (this.dragLastPosition && this.wasMoved) {
        e.stopImmediatePropagation();
        e.preventDefault();
        if (e instanceof MouseEvent || !e.touches) {
          window.addEventListener("click", (e) => e.stopPropagation(), {
            capture: true,
            once: true,
          });
        }
        if (this.props.onDragEnd) {
          this.props.onDragEnd({
            x: this.dragLastPosition.x,
            y: this.dragLastPosition.y,
          });
        }
      }
      this.dragLastPosition = null;
      this.wasMoved = false;
    };
  };
