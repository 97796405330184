import React, { useState } from "react";
//Styles
import Styles from "./styles";
//Icons
import { BsEyeFill } from "react-icons/bs";
import { BsEyeSlashFill } from "react-icons/bs";
import { IconContext } from "react-icons";
//Tools
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//Components
import { FormGroup } from "../Shared/FormGroup";
import SpinnerComponent from "../Loader";
import usePreventEnterAction from "../../hooks/PreventCloseOnEnterHook";

const validateInviteSchema = yup.object().shape({
  userFirstName: yup.string().required("First Name is a required field."),
  userLastName: yup.string().required("Last Name is a required field."),
  userPhone: yup.string(), //Validate phone number with regex ?
  /* companyName: yup.string().required("Company Name is a required field."), */
  /* email: yup.string().email().required("Email is a required field."), */
  userPassword: yup.string().min(4).max(15).required(),
  confirmPassword: yup.string().oneOf([yup.ref("userPassword"), null]),
});

const ValidateInvite = ({
  completeInvite,
  inviteCode,
  registrationError,
  completeInviteLoading,
  userEmailAndCompany,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validateInviteSchema),
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const submitForm = (validateFormData) => {
    completeInvite({
      variables: {
        inviteCode: inviteCode,
        userFirstName: validateFormData.userFirstName,
        userLastName: validateFormData.userLastName,
        userPhone: validateFormData.userPhone,
        userPassword: validateFormData.userPassword,
      },
    });
    reset();
  };
  usePreventEnterAction(submitForm);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Styles.AuthContainer>
      <Styles.FormContainer>
        <Styles.Title>Validate Your Account</Styles.Title>
        <Styles.Form onSubmit={handleSubmit(submitForm)}>
          <FormGroup
            labelText={"Enter Your First Name"}
            errorMessage={errors.userFirstName?.message}
          >
            <Styles.Input type="text" name="userFirstName" {...register("userFirstName")} />
          </FormGroup>
          <FormGroup labelText={"Enter Your Last Name"} errorMessage={errors.userLastName?.message}>
            <Styles.Input type="text" name="userLastName" {...register("userLastName")} />
          </FormGroup>
          <FormGroup labelText={"Enter Your Phone Number"} errorMessage={errors.userPhone?.message}>
            <Styles.Input type="text" name="userPhone" {...register("userPhone")} />
          </FormGroup>
          <FormGroup labelText={"Company Name"} errorMessage={errors.companyName?.message}>
            <Styles.Input
              type="text"
              name="companyName"
              disabled
              placeholder={`${userEmailAndCompany.companyName}`}
              /* {...register("companyName")} */
            />
          </FormGroup>
          <FormGroup labelText={"Email Address"} errorMessage={errors.email?.message}>
            <Styles.Input
              type="text"
              name="userEmail"
              disabled
              placeholder={`${userEmailAndCompany.userEmail}`}
              /* {...register("userEmail")} */
            />
          </FormGroup>
          <FormGroup labelText={"Type your password"} errorMessage={errors.userPassword?.message}>
            <Styles.Input
              type={showPassword ? "text" : "password"}
              name="userPassword"
              {...register("userPassword")}
            />
            <IconContext.Provider value={{ size: 30, color: "#37474f" }}>
              <Styles.IconWrapper onClick={handleShowPassword}>
                {showPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
              </Styles.IconWrapper>
            </IconContext.Provider>
          </FormGroup>
          <FormGroup
            labelText={"Confirm password"}
            errorMessage={errors?.confirmPassword && "Passwords must match!"}
          >
            <Styles.Input
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              {...register("confirmPassword")}
            />
            <IconContext.Provider value={{ size: 30, color: "#37474f" }}>
              <Styles.IconWrapper onClick={handleShowConfirmPassword}>
                {showConfirmPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
              </Styles.IconWrapper>
            </IconContext.Provider>
          </FormGroup>
          {registrationError ? (
            <Styles.SubmitServerError>{registrationError}</Styles.SubmitServerError>
          ) : null}
          {completeInviteLoading ? (
            <SpinnerComponent />
          ) : (
            <Styles.SubmitInput type="submit" value="Validate Account" />
          )}
        </Styles.Form>
      </Styles.FormContainer>
    </Styles.AuthContainer>
  );
};

export default ValidateInvite;
