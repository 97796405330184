import styled, { css } from "styled-components";

export const Button = styled.button`
  background: ${({ whitebg }) => (whitebg ? "#FFFFFF" : "#5ca6b3")};
  border: ${({ hasBorder }) => (hasBorder ? "1px solid #5ca6b3" : "none")};
  border-radius: 100px;
  ${({ width }) => {
    if (width) {
      return css`
        width: ${width}px;
      `;
    }
    return css`
      width: 210px;
    `;
  }}
  ${({ height }) => {
    if (height) {
      return css`
        height: ${height}px;
      `;
    }
    return css`
      height: 40px;
    `;
  }}
  text-decoration: none;
  color: ${({ blueText }) => (blueText ? "#5ca6b3" : "#FFFFFF")};
  ${({ fontFamily }) => {
    if (fontFamily) {
      return css`
        font-family: ${fontFamily};
      `;
    }
    return css`
      font-family: Poppins600;
    `;
  }}
  ${({ fontSize }) => {
    if (fontSize) {
      return css`
        font-size: ${fontSize}px;
      `;
    }
    return css`
      font-size: 14px;
    `;
  }}
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;
