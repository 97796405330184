import React from "react";
import Styled from "./UseCaseGrid.styled";
import Icon from "components/Shared/Icon/Icon";

const UseCaseGrid = ({ useCases, selectedUseCase, onSelect }) => {
  return (
    <Styled.GridContainer>
      {useCases.map((useCase) => {
        const iconName = useCase.value.replace(/\//g, "_");

        return (
          <Styled.UseCaseContainer
            key={useCase.value}
            isSelected={selectedUseCase === useCase.value}
            onClick={() => onSelect(useCase.value)}
          >
            <Icon name={iconName} size="48px" />
            <Styled.UseCaseLabel>{useCase.label}</Styled.UseCaseLabel>
          </Styled.UseCaseContainer>
        );
      })}
    </Styled.GridContainer>
  );
};

export default UseCaseGrid;
