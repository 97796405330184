import styled from "styled-components/macro";
import { containerScrollbar } from "../../../../styles/globalStyles";

const styles = {
  CamerasMainContainer: styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* overflow-y: auto; */
  `,
  AddAreasContainer: styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #c4c4c4;
    @media screen and (max-width: 1175px) {
      flex-direction: column;
      gap: 1rem;
    }
  `,
  VenueNameAndDockerLinkContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  `,
  AddDockerLink: styled.span`
    font-family: "PoppinsRegular";
    font-size: 13px;
    color: #0000ee;
    text-decoration: underline;
    cursor: pointer;
  `,
  CompanyName: styled.h2`
    margin: 0;
    font-family: "Poppins700";
    font-size: 20px;
    color: #263238;
  `,
  AreasWrapper: styled.div`
    height: 100%;
    width: 100%;
    margin-top: 2rem;
    /* overflow-y: auto;
    ${containerScrollbar} */
  `,
  AreaContainer: styled.div`
    margin-bottom: 2rem;
    padding-right: 1rem;
  `,
  AreaHeader: styled.div`
    padding: 1rem 0;
    display: flex;
    gap: 1rem;
  `,
  NameAndIcons: styled.div`
    display: flex;
    gap: 3rem;
  `,
  AreaName: styled.h3`
    margin: 0;
    font-family: "Poppins700";
    font-size: 15px;
    color: #455a64;
  `,
  ActionIconsWrapper: styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
  `,
  CamerasRow: styled.div`
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0.5rem 0;
    ${containerScrollbar}
  `,
  AreasHeader: styled.div``,
  NameWrapper: styled.div``,
  ActionButtonsWrapper: styled.div`
    display: flex;
    gap: 1rem;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 1rem;
      align-items: center;
    }
  `,
  SmallButtonsContainer: styled.div`
    display: flex;
    gap: 1rem;
  `,
  AddButtonsContainer: styled.div`
    display: flex;
    gap: 1rem;
  `,
  SelectAllContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
  `,
  CheckBoxLabel: styled.label`
    font-family: "SFProText";
    font-size: 14px;
    color: #888888;
  `,
  CheckboxNameContainer: styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
  `,
  AreaIconsContainer: styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
  `,
  LoaderContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  EmptyStateContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
  `,
  EmptyStateText: styled.div`
    font-family: "Poppins600";
    font-size: 15px;
    color: #455a64;
  `,
};

export default styles;
