import React from "react";
import MaskingRow from "./MaskingRow";

//Styles
import Styles from "./styles";

const MaskingTable = ({
  padmeModelColors,
  padmeModelTypes,
  showWaitTime,
  dataRows,
  selectMask,
  activeMaskId,
  deleteMask,
  updateMask,
}) => {
  return (
    <>
      <Styles.MaskingTable>
        <Styles.TableHeader>
          <Styles.TableHeaderEntriesContainer>
            <Styles.TableHeaderEntries>Name</Styles.TableHeaderEntries>
            <Styles.TableHeaderEntries>Type</Styles.TableHeaderEntries>
            <Styles.TableHeaderEntries>Color</Styles.TableHeaderEntries>
            <Styles.TableHeaderEntries>Wait time</Styles.TableHeaderEntries>
            <Styles.TableHeaderEntries>Threshold</Styles.TableHeaderEntries>
            <Styles.TableHeaderEntries>Notes</Styles.TableHeaderEntries>
            <Styles.TableHeaderEntries>Options</Styles.TableHeaderEntries>
          </Styles.TableHeaderEntriesContainer>
        </Styles.TableHeader>
        <Styles.TableRow>
          {dataRows?.map((row) => (
            <MaskingRow
              padmeModelColors={padmeModelColors}
              padmeModelTypes={padmeModelTypes}
              data={row}
              key={row.maskId}
              showWaitTime={showWaitTime}
              activeMaskId={activeMaskId}
              deleteMask={deleteMask}
              selectMask={selectMask}
              updateMask={updateMask}
            />
          ))}
        </Styles.TableRow>
      </Styles.MaskingTable>
    </>
  );
};

export default MaskingTable;
