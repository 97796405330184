import styled from "styled-components/macro";

const styles = {
  ProgressBarContainer: styled.div`
    width: 100%;
    height: 10px;
    background-color: lightblue;
  `,
  ProgressBar: styled.div`
    width: ${({ progress }) => `${progress}%`};
    height: 10px;
    background-color: blue;
    overflow: hidden;
  `,
};

export default styles;
