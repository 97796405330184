import React, { useRef, useEffect, useCallback } from "react";
import { MACHINE_THUMBNAIL_UPDATED_SUBSCRIPTION } from "../../../graphql/graph";
import { useSubscription } from "@apollo/client/react";

import Styles from "../styles";
import SpinnerComponent from "../../../components/Loader";

const MASKING_WIDTH = 960;
const MASKING_HEIGHT = 540;

const StaticPreview = ({
  selectedCamera,
  updateMachineResolution,
  width,
  height,
  updateStaticUrl,
}) => {
  // console.log(`StaticPreview: ${selectedCamera} ${width} ${height}`);
  const [staticRTSPImage, setStaticRTSPImage] = React.useState(null);
  const [previewLoading, setPreviewLoading] = React.useState(true);

  const { data: machineThumbnailData } = useSubscription(MACHINE_THUMBNAIL_UPDATED_SUBSCRIPTION, {
    variables: { id: selectedCamera.id },
  });

  useEffect(() => {
    if (machineThumbnailData && machineThumbnailData.machineThumbnailUpdated) {
      const { machineThumbnailURL } = machineThumbnailData.machineThumbnailUpdated;
      if (machineThumbnailURL) {
        setStaticRTSPImage(machineThumbnailURL);
        updateStaticUrl && updateStaticUrl(machineThumbnailURL);
      }
      setPreviewLoading(false);
    }
  }, [machineThumbnailData]);

  const updateMachineDims = useCallback(
    (deviceWidth, deviceHeight) => {
      // calculate machine yOffSet
      const maskingScale = String(MASKING_WIDTH / parseInt(deviceWidth));

      // setPreviewIsPlaying(true);
      // setPreviewLoading(false);

      // only update if needed
      const { machineWidth, machineHeight, machineMaskingScale } = selectedCamera;

      if (
        machineWidth !== deviceWidth ||
        machineHeight !== deviceHeight ||
        machineMaskingScale !== maskingScale
      ) {
        updateMachineResolution({
          variables: {
            id: selectedCamera.id,
            machineWidth: deviceWidth,
            machineHeight: deviceHeight,
            machineMaskingScale: maskingScale,
          },
        });
      }
    },
    [selectedCamera, updateMachineResolution],
  );

  // updateMachineDims
  const onImgLoad = ({ target: img }) => {
    if (img.naturalWidth && img.naturalHeight) {
      // write size to parent
      updateMachineDims(String(img.naturalWidth), String(img.naturalHeight));
    }
  };

  if (previewLoading || !staticRTSPImage) {
    return (
      <Styles.SpinnerContainer>
        <SpinnerComponent width={"5rem"} height={"5rem"} />
      </Styles.SpinnerContainer>
    );
  }

  return (
    <img src={staticRTSPImage} alt="staticRTSPImage" onLoad={onImgLoad} style={{ width, height }} />
  );
};

export default StaticPreview;
