import styled from "styled-components";

export const Button = styled.button`
  background: ${({ whitebg, orangebg }) =>
    whitebg ? "#FFFFFF" : orangebg ? "#E6866E" : "#5ca6b3"};
  border: ${({ orangeborder }) => (orangeborder ? "1px solid #E6866E" : "none")};
  border-radius: 100px;
  width: ${({ smallsize, sidebarSize, mediumsize }) =>
    smallsize ? "130px" : sidebarSize ? "96px" : mediumsize ? "165px" : "210px"};
  height: ${({ sidebarSize }) => (sidebarSize ? "30px" : "40px")};
  text-decoration: none;
  color: ${({ orangecolor }) => (orangecolor ? "#E6866E" : "#FFFFFF")};
  font-family: Poppins600;
  font-size: ${({ sidebarSize }) => (sidebarSize ? "10px" : "14px")};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;
