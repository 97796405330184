import styled from "styled-components/macro";

const styles = {
  GridContainer: styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  `,
  UseCaseContainer: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px;
    background-color: ${({ theme }) => theme.colors.neutral.white};
    border-radius: 8px;
    cursor: pointer;
    transition: border 0.1s ease-in-out;
    border: ${({ isSelected, theme }) =>
      isSelected ? `2px solid ${theme.colors.primary.blue800}` : "none"};
  `,
  UseCaseLabel: styled.p`
    font-size: ${({ theme }) => theme.fontSizes.field};
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    text-align: center;
    margin-top: 15px;
    white-space: nowrap;
    overflow: hidden;
  `,
};

export default styles;
