import styled from "styled-components/macro";
import { MdClose } from "react-icons/md";

const styles = {
  Background: styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  `,

  ModalWrapper: styled.div`
    padding: 3rem 5rem;
    max-width: 750px;
    /* min-width: 400px;
    min-height: 300px; */
    background: #ffffff;
    border-radius: 10px;
    position: relative;
    @media screen and (max-width: 768px) {
      padding: 3rem 2rem;
    }
  `,

  ModalContent: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  `,
  CloseModalButton: styled(MdClose)`
    cursor: pointer;
    position: absolute;
    color: #464646;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
  `,

  Title: styled.h1`
    font-family: "Poppins700";
    font-size: 20px;
    color: #464646;
    margin: 0;
    text-align: center;
  `,
  SubTitle: styled.h1`
    font-family: "Poppins600";
    font-size: 18px;
    color: #464646;
    margin: 0;
    text-align: center;
  `,
  ButtonsContainer: styled.div`
    display: flex;
    gap: 1rem;
  `,
};

export default styles;
