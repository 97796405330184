import React from "react";
import Styles from "./styles";

const RadioButton = ({ disabled, id, name, value, onChange, checked = true }) => {
  return (
    <Styles.RadioButton
      id={id}
      value={value}
      name={name}
      type="radio"
      onChange={onChange}
      checked={checked}
      disabled={disabled}
    />
  );
};

export const RadioButtonWithLabel = ({ id, name, onChange, checked = true, label }) => {
  return (
    <Styles.RadioButtonContainer>
      <RadioButton id={id} name={name} onChange={onChange} checked={checked} />
      <Styles.RadioButtonLabel>{label}</Styles.RadioButtonLabel>
    </Styles.RadioButtonContainer>
  );
};

export const RadioButtonesWithLabels = ({ radioButtons, title, onChange }) => {
  if (!radioButtons || !radioButtons.length) return null;

  return (
    <Styles.Container>
      <Styles.Label>{title}</Styles.Label>
      <Styles.BoxContainer>
        {radioButtons.map((btn) => (
          <RadioButtonWithLabel
            key={btn.label}
            id={btn.label}
            name={btn.label}
            onChange={onChange}
            checked={btn.checked}
            label={btn.label}
          />
        ))}
      </Styles.BoxContainer>
    </Styles.Container>
  );
};

export default RadioButton;
