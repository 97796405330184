import React, { useEffect, createContext } from "react";
import {
  CREATE_MACHINE,
  LIST_AREAS,
  LIST_MODELS,
  UPDATE_MACHINE,
  DELETE_MACHINES,
  START_ALL_INFERENCE,
  STOP_ALL_INFERENCE,
  PREVIEW_RTSP,
  DELETE_AREAS,
} from "../graphql/graph";
import { useMutation, useSubscription } from "@apollo/client/react";
import { useToastModal } from "./ToastModalContext";

export const GraphQLHelperContext = createContext();

const GraphQLHelperProvider = ({ children }) => {
  const { addToast } = useToastModal();
  const [previewRTSP] = useMutation(PREVIEW_RTSP, {
    context: { clientName: "graph" },
  });

  const [
    createMachine,
    { data: createMachineData, error: createMachineError, reset: createMachineReset },
  ] = useMutation(CREATE_MACHINE, {
    context: { clientName: "graph" },
  });

  const [
    updateMachine,
    { data: updateMachineData, error: updateMachineError, reset: updateMachineReset },
  ] = useMutation(UPDATE_MACHINE, {
    context: { clientName: "graph" },
  });

  const [
    startAllInference,
    { data: startAllInferenceData, error: startAllInferenceError, reset: startAllInferenceReset },
  ] = useMutation(START_ALL_INFERENCE, {
    context: { clientName: "graph" },
  });

  const [
    stopAllInference,
    { data: stopAllInferenceData, error: stopAllInferenceError, reset: stopAllInferenceReset },
  ] = useMutation(STOP_ALL_INFERENCE, {
    context: { clientName: "graph" },
  });

  const [
    deleteMachines,
    { data: deleteMachinesData, error: deleteMachinesError, reset: deleteMachinesReset },
  ] = useMutation(DELETE_MACHINES, {
    context: { clientName: "graph" },
  });

  const [deleteAreas, { data: deleteAreasData, error: deleteAreasError, reset: deleteAreasReset }] =
    useMutation(DELETE_AREAS, {
      context: { clientName: "graph" },
    });

  // handle inference status
  useEffect(() => {
    if (startAllInferenceData) {
      addToast("Inference started successfully!", "success");
    }
    if (startAllInferenceError) {
      addToast("Something went wrong! Inference did not start", "error");
    }
    startAllInferenceReset();
  }, [startAllInferenceData, startAllInferenceError]);

  useEffect(() => {
    if (stopAllInferenceData) {
      addToast("Inference stopped successfully!", "success");
    }
    if (stopAllInferenceError) {
      addToast("Something went wrong! Inference did not stop", "error");
    }
    stopAllInferenceReset();
  }, [stopAllInferenceData, stopAllInferenceError]);

  useEffect(() => {
    if (deleteMachinesData) {
      addToast("Camera deleted successfully!", "success");
    }
    if (deleteMachinesError) {
      addToast("Something went wrong! Camera did not delete", "error");
    }
    deleteMachinesReset();
  }, [deleteMachinesData, deleteMachinesError]);

  useEffect(() => {
    if (deleteAreasData) {
      addToast("Areas deleted successfully!", "success");
    }
    if (deleteAreasError) {
      addToast("Something went wrong! Areas did not delete!", "error");
    }
    deleteAreasReset();
  }, [deleteAreasData, deleteAreasError]);

  useEffect(() => {
    if (createMachineData) {
      addToast("Camera created successfully!", "success");
    }
    if (createMachineError) {
      addToast("Something went wrong! camera did not create!", "error");
    }
    createMachineReset();
  }, [createMachineData, createMachineError]);

  useEffect(() => {
    if (updateMachineData) {
      addToast("Camera updated successfully!", "success");
    }
    if (updateMachineError) {
      addToast("Something went wrong! camera did not update!", "error");
    }
    updateMachineReset();
  }, [updateMachineData, updateMachineError]);

  return (
    <GraphQLHelperContext.Provider
      value={{
        startAllInference,
        stopAllInference,
        deleteMachines,
        deleteAreas,
        previewRTSP,
        createMachine,
        updateMachine,
      }}
    >
      {children}
    </GraphQLHelperContext.Provider>
  );
};

export default GraphQLHelperProvider;
