import styled from "styled-components/macro";

const styles = {
  ButtonsContainer: styled.div`
    display: flex;
    gap: 1rem;
  `,
  Form: styled.form`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    align-items: center;
  `,
  InputAndErrorContainer: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  /* Input: styled.input`
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    height: 30px;
    width: 100%;
    background: transparent;
    font-family: "Roboto400";
    font-size: 14px;
    padding: 0 0.5rem;
    color: #37474f;
    outline: none;
  `, */
  Input: styled.input`
    border: 1px solid #c4c4c4;
    height: 41px;
    background: transparent;
    font-family: "Roboto400";
    font-size: 21px;
    padding: 0 1rem;
    color: #37474f;
    border-radius: 10px;
    outline: none;
  `,
  SubmitInput: styled.input`
    background: #5ca6b3;
    border: none;
    border-radius: 100px;
    width: 210px;
    height: 40px;
    text-decoration: none;
    color: #ffffff;
    font-family: Poppins600;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  `,
  SubmitError: styled.span`
    font-family: "Roboto400";
    font-size: 14px;
    color: #ff3333;
    padding-left: 5px;
    padding-top: 5px;
  `,
};

export default styles;
