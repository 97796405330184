import styled from "styled-components/macro";
//Icons
import { ReactComponent as DeleteIcon } from "../../../assets/icons/garbage_gray_icon.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit_icon.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow_down_gray.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow_up_gray.svg";
import { ReactComponent as SettingsIcon } from "../../../assets/icons/settings_wheel_gray.svg";

const styles = {
  //Icons
  DeleteIcon: styled(DeleteIcon)`
    cursor: pointer;
  `,
  EditIcon: styled(EditIcon)`
    cursor: pointer;
  `,
  ArrowDown: styled(ArrowDown)`
    cursor: pointer;
  `,
  ArrowUp: styled(ArrowUp)`
    cursor: pointer;
  `,
  SettingsIcon: styled(SettingsIcon)`
    cursor: pointer;
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
    /* border-bottom: 1px solid #c4c4c4; */
    @media screen and (max-width: 480px) {
      flex-direction: column;
      gap: 1rem;
    }
  `,
  Title: styled.h1`
    font-family: Poppins600;
    font-size: 20px;
    color: #455a64;
    margin-top: 1rem;
  `,
  MaskingTable: styled.table`
    background: #ffffff;
    padding: 1rem;
    table-layout: fixed;
    width: 100%;
    height: 250px;
    overflow-y: scroll;
  `,
  TableHeader: styled.thead``,
  TableHeaderEntriesContainer: styled.tr`
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #c4c4c4;
    margin-bottom: 0.75rem;
  `,
  TableHeaderEntries: styled.th`
    width: 100%;
    text-align: left;
    font-family: "Poppins700";
    font-size: 14px;
    color: #263238;
    padding: 1%;
  `,
  TableRow: styled.tbody`
    width: 100%;
  `,
  TableRowEntriesContainer: styled.tr`
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #c4c4c4;
    background-color: ${(props) => (props.active ? "#bddbe0" : "#ffffff")};
    &:nth-child(even) {
      background-color: ${(props) => (props.active ? "#bddbe0" : "#efefef")};
    }
  `,
  TableRowEntries: styled.td`
    width: 100%;
    font-family: "PoppinsRegular";
    font-size: 16px;
    color: #455a64;
    text-align: left;
    padding: 0.75%;
  `,
  RowActionsContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2rem;
    padding-left: 1rem;
  `,
  NoMaskingText: styled.span`
    color: #888888;
    font-family: "Poppins700";
    font-size: 14px;
  `,
  LoaderContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  ActionIconsContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
  `,
  StatusCircle: styled.span`
    height: 12px;
    width: 12px;
    background-color: ${(props) => (props.active ? "#28BC4E" : "#EC5F5D")};
    border-radius: 50%;
    display: inline-block;
    /* animation: blinker 1.5s linear infinite;
    @keyframes blinker {
      50% {
        opacity: 0.2;
      }
    } */
  `,
  Input: styled.input`
    height: 2rem;
    width: ${(props) => (props.slim ? "3rem" : "6rem")};
    border: 1px solid #c4c4c4;
    background: white;
    outline: none;
    border-radius: 5px;
    padding: 0.2rem;
    margin-right: 0.2rem;
  `,
  Select: styled.select`
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
    border-radius: 8px;
    height: 30px;
    width: 100%;
    background: white;
    font-family: "Roboto400";
    font-size: 14px;
    padding: 0 0.3rem;
    color: #37474f;
    outline: none;
  `,
  StatusContainer: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
  `,
};

export default styles;
