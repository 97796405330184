import styled from "styled-components/macro";
import { MdClose } from "react-icons/md";

const styles = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    width: ${({ wide }) => (wide ? "100%" : undefined)};
  `,
  Table: styled.table`
    background: ${({ theme }) => theme.colors.neutral.white};
    padding: 1rem;
    table-layout: fixed;
    width: 800px;
    height: 40vh;
    overflow-y: scroll;
  `,
  TableHeader: styled.thead``,
  TableHeaderEntriesContainer: styled.tr`
    display: flex;
    justify-content: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.grey300};
    margin-bottom: 0.75rem;
  `,
  TableHeaderEntries: styled.th`
    width: 100%;
    text-align: left;
    font-size: 13px;
  `,
  TableRow: styled.tbody`
    width: 100%;
  `,
  TableRowEntriesContainer: styled.tr`
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.grey300};

    &:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.neutral.grey200};
    }
  `,
  TableRowEntries: styled.td`
    width: 100%;
    font-size: 12px;
    text-align: left;
    cursor: pointer;
  `,
  Background: styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  `,
  ModalWrapper: styled.div`
    padding: ${({ slim }) => (slim ? "0.5rem 3rem" : "2rem 5rem")};
    max-width: ${({ maxWidth50, maxWidth500 }) =>
      maxWidth50 ? "50%" : maxWidth500 ? "500px" : null};
    background: ${({ theme }) => theme.colors.neutral.white};
    border-radius: 10px;
    position: relative;
    @media screen and (max-width: 768px) {
      padding: 2rem 3rem;
    }
    max-height: 100vh;
    width: ${({ width }) => (width ? `${width}px` : undefined)};
  `,
  ModalContent: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    max-height: 95vh;
    overflow-y: scroll;
  `,
  CloseModalButton: styled(MdClose)`
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
  `,
  Title: styled.h1`
    font-size: 20px;
    margin: 0;
  `,
};

export default styles;
