import { useEffect } from "react";

const usePreventEnterAction = (onEnter) => {
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.target.type !== "textarea") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
};

export default usePreventEnterAction;
