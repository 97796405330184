import React from "react";
import { withDraggable } from "./withDraggable";

export const SVGPolyline = withDraggable(
  React.forwardRef(function SVGPolyline(
    { path, animate, draggable, color, onClickTouchEvent, addPointToPath, pathIndex, activeIndex },
    ref,
  ) {
    return (
      <polyline
        ref={ref}
        style={{ cursor: draggable ? "move" : "" }}
        points={path.map(({ x, y }) => `${x},${y}`).join(" ")}
        fill="rgba(0,0,0,0)"
        stroke={color || "white"}
        strokeWidth={activeIndex === pathIndex ? "3.5px" : "2px"}
        shapeRendering="geometricPrecision"
        strokeDasharray={activeIndex === pathIndex ? "0" : "3"}
        strokeDashoffset="0"
        vectorEffect="non-scaling-stroke"
        onClick={(e) => {
          // Add point to path
          if (draggable && pathIndex === activeIndex) {
            addPointToPath(pathIndex, {
              x: e.nativeEvent.layerX,
              y: e.nativeEvent.layerY,
            });
          }
        }}
      >
        {animate && (
          <animate
            attributeName="stroke-dashoffset"
            values="0;1000;0"
            dur="100s"
            repeatCount="indefinite"
          />
        )}
      </polyline>
    );
  }),
);
