import styled, { css } from "styled-components";

export const sharedTableStyles = {
  TableContainer: styled.div`
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 16px;
    background: ${({ theme }) => theme.colors.neutral.white};
    border-radius: 12px;
  `,
  Table: styled.table`
    width: 100%;
    border-collapse: collapse;

    &.api-table {
      table-layout: fixed;
    }
  `,
  TableHeader: styled.thead``,
  TableHeaderEntriesContainer: styled.tr`
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary.blue300};

    ${({ clickable }) =>
      clickable
        ? css`
            cursor: pointer;
            &:hover {
              background: ${({ theme }) => theme.colors.primary.lightBlue};
            }
          `
        : css`
            cursor: default;
          `}
  `,
  TableHeaderEntries: styled.th`
    background: ${({ theme }) => theme.colors.neutral.white};
    font-size: ${({ theme }) => theme.fontSizes.field};
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    padding: 0px 16px 12px 16px;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary.blue300};
    opacity: 0.6;
  `,
  TableRow: styled.tbody`
    width: 100%;
    &:not(:last-child) {
      border-bottom: 1px solid ${({ theme }) => theme.colors.primary.blue300};
    }
  `,
  TableRowEntriesContainer: styled.tr`
    ${({ clickable }) =>
      clickable
        ? css`
            cursor: pointer;
            &:hover {
              background: ${({ theme }) => theme.colors.primary.lightBlue};
            }
          `
        : css`
            cursor: default;
          `}
  `,
  TableRowEntries: styled.td`
    height: 40px;
    padding: 12px;
    text-align: left;
    font-size: ${({ theme }) => theme.fontSizes.field};
    font-weight: ${({ theme }) => theme.fonts.weights.regular};
    vertical-align: middle;
  `,
  StatusContainer: styled.div`
    display: inline-flex;
    padding: 8px 10px 8px 8px;
    gap: 7px;
    border-radius: 180px;
    background: ${({ theme }) => theme.surfaces.background};
    border: 1px solid ${({ theme, isActive }) => (isActive ? theme.camera.active : "none")};
  `,
  EmptyMessage: styled.div`
    padding: 16px;
    color: ${({ theme }) => theme.colors.neutral.grey500};
  `,
};

const sharedStyles = {
  CompanyDashboardContainer: styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
  `,
  Header: styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 1.5rem;
  `,
  SubTitle: styled.h3`
    font-size: ${({ theme }) => theme.fontSizes.dashboardHeadline};
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};
    margin: 0;
  `,
  DockerContainer: styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  IconButton: styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
  `,
  Section: styled.div`
    margin-bottom: 1rem;
  `,
  TableTitle: styled.h3`
    font-size: ${({ theme }) => theme.fontSizes.dashboardHeadline};
    font-weight: ${({ theme }) => theme.fonts.weights.semibold};
    margin: 0;
  `,
  Actions: styled.div`
    display: flex;
    gap: 10px;
    flex-display: flex-col;
    flex-grow: 1;
    justify-content: flex-end;
  `,
};

export default sharedStyles;
