import React from "react";
import Styles from "./styles";

const GalleryImage = ({ file, deleteFile, allowDelete }) => {
  return (
    <Styles.ImageContainer>
      <Styles.Image
        hideDownload
        small={file.fileSignedUrl}
        large={file.fileSignedUrl}
        alt={file.fileAssetUrl}
      />
      {allowDelete && (
        <Styles.DeleteIconContainer onClickCapture={() => deleteFile(file.id)}>
          <Styles.DeleteIcon />
        </Styles.DeleteIconContainer>
      )}
    </Styles.ImageContainer>
  );
};

const ImageGallery = ({ deleteFile, title, allowDelete = false, images = [] }) => {
  const renderFiles = () => {
    return images.map((file) => (
      <GalleryImage key={file.id} deleteFile={deleteFile} file={file} allowDelete={allowDelete} />
    ));
  };

  return (
    <Styles.Container>
      <Styles.Label>{title || "Captured Frames"}</Styles.Label>
      <Styles.ImageContainerOuter>{renderFiles()}</Styles.ImageContainerOuter>
    </Styles.Container>
  );
};

export default ImageGallery;
