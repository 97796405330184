import React, { useEffect, useState } from "react";
//Styles
import Styles from "./styles";
//Tools
import { useParams } from "react-router-dom";
//Graphql
import { RESET_PASSWORD, FORGOT_PASSWORD_CHECK } from "../../graphql/user";
import { useMutation, useQuery } from "@apollo/client";
//Components
import ResetPasswordForm from "../../components/ResetPasswordForm";
import SpinnerComponent from "../../components/Loader";

const ResetPassword = () => {
  const { resetPassCode } = useParams();
  const [validResetPassCode, setValidResetPassCode] = useState(false);
  const [invalidCodeError, setInvalidCodeError] = useState("");

  const {
    data: forgotPassCodeCheckData,
    loading: forgotPassCodeCheckLoading,
    error: forgotPassCodeCheckError,
  } = useQuery(FORGOT_PASSWORD_CHECK, {
    variables: { resetCode: resetPassCode },
    context: { clientName: "user" },
  });

  const [
    resetPasswordMutation,
    { data: resetPasswordData, loading: resetPasswordLoading, error: resetPasswordError },
  ] = useMutation(RESET_PASSWORD, { context: { clientName: "user" } });

  useEffect(() => {
    if (forgotPassCodeCheckData) {
      if (forgotPassCodeCheckData.forgotPasswordCheck.resetValid) {
        setValidResetPassCode(true);
        return;
      }
    }
    if (forgotPassCodeCheckError) {
      setInvalidCodeError(forgotPassCodeCheckError.message);
      console.error("forgotPassCodeCheckError: ", forgotPassCodeCheckError.message);
    }
  }, [forgotPassCodeCheckData, forgotPassCodeCheckError]);

  return (
    <Styles.PageContainer>
      <Styles.AlignmentContainer>
        {!forgotPassCodeCheckLoading && validResetPassCode ? (
          <Styles.ResetPasswordWrapper>
            <ResetPasswordForm
              resetPasswordMutation={resetPasswordMutation}
              resetPasswordLoading={resetPasswordLoading}
              resetPassCode={resetPassCode}
              resetPasswordData={resetPasswordData}
              resetPasswordError={resetPasswordError}
            />
          </Styles.ResetPasswordWrapper>
        ) : invalidCodeError ? (
          <Styles.ErrorAndLoadingContainer>
            <Styles.ErrorMessage>{invalidCodeError}!</Styles.ErrorMessage>
          </Styles.ErrorAndLoadingContainer>
        ) : (
          <Styles.ErrorAndLoadingContainer>
            <SpinnerComponent width={100} height={100} />
          </Styles.ErrorAndLoadingContainer>
        )}
      </Styles.AlignmentContainer>
    </Styles.PageContainer>
  );
};

export default ResetPassword;
