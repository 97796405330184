import React from "react";
// Styles
import { sharedTableStyles as Styled } from "../../Shared/Shared.styled";
// Components
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";
// Utils
import { timeAgo } from "../../../../configs/helpers";
import { mtStatus } from "../../../../configs/configEnviroment";
import { timeStampToDateString } from "../../../../configs/helpers";
import Icon from "components/Shared/Icon/Icon";

const Row = ({ requestItem, updateItem }) => {
  const startDate = timeStampToDateString(requestItem.mtStartedDate);

  const endDate = timeStampToDateString(requestItem.mtCompleteDate);

  const timeAgoText = requestItem.mtStartedDate ? timeAgo(requestItem.mtStartedDate) : null;
  return (
    <Styled.TableRowEntriesContainer>
      <Styled.TableRowEntries>{requestItem.mtName}</Styled.TableRowEntries>
      <Styled.TableRowEntries>{startDate}</Styled.TableRowEntries>
      <Styled.TableRowEntries>{endDate}</Styled.TableRowEntries>
      <Styled.TableRowEntries>{timeAgoText}</Styled.TableRowEntries>
      <Styled.TableRowEntries>{mtStatus[requestItem.mtStatus]}</Styled.TableRowEntries>
      <Styled.TableRowEntries style={{ textAlign: "right" }}>
        <Icon
          name={"menu"}
          className="action-icon"
          size="15px"
          onClick={() => updateItem(requestItem)}
        />
      </Styled.TableRowEntries>
    </Styled.TableRowEntriesContainer>
  );
};

export const TrainingRequests = ({ requestsList, updateItem }) => {
  const renderNestedRows = () => {
    return requestsList.map((requestItem) => {
      return (
        <Styled.TableRow key={requestItem.id}>
          <Row requestItem={requestItem} updateItem={updateItem} />
        </Styled.TableRow>
      );
    });
  };

  return (
    <Styled.TableContainer>
      <Styled.Table>
        <Styled.TableHeader>
          <Styled.TableHeaderEntriesContainer>
            <Styled.TableHeaderEntries>Name</Styled.TableHeaderEntries>
            <Styled.TableHeaderEntries>Start</Styled.TableHeaderEntries>
            <Styled.TableHeaderEntries>End</Styled.TableHeaderEntries>
            <Styled.TableHeaderEntries>Elapsed</Styled.TableHeaderEntries>
            <Styled.TableHeaderEntries>Status</Styled.TableHeaderEntries>
            <Styled.TableHeaderEntries narrow="true"></Styled.TableHeaderEntries>
          </Styled.TableHeaderEntriesContainer>
        </Styled.TableHeader>

        {requestsList.length > 0 ? (
          renderNestedRows()
        ) : (
          <Styled.EmptyMessage>No records found</Styled.EmptyMessage>
        )}
      </Styled.Table>
    </Styled.TableContainer>
  );
};

export default TrainingRequests;
