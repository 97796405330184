import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
//Styles
import Styles from "./styles";
//Components
import VideoPreview from "./VideoPreview";
import SpinnerComponent from "../../components/Loader";
import { useToastModal } from "../../context/ToastModalContext";
//Graphql
import { useQuery } from "@apollo/client";
import { RETRIEVE_MACHINE, RETRIEVE_VENUE } from "../../graphql/graph";

const PlayerContainer = () => {
  const { cameraId, venueId } = useParams();
  const [cameraPayload, setCameraPayload] = useState(null);

  const {
    data: retrieveMachineData,
    loading: retrieveMachineLoading,
    error: retrieveMachineError,
  } = useQuery(RETRIEVE_MACHINE, {
    context: { clientName: "graph" },
    variables: { id: cameraId },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const {
    data: retrieveVenueData,
    loading: retrieveVenueLoading,
    error: retrieveVenueError,
  } = useQuery(RETRIEVE_VENUE, {
    context: { clientName: "graph" },
    variables: { id: venueId },
  });

  useEffect(() => {
    if (retrieveMachineData && retrieveVenueData) {
      // new payload
      const cameraPayload = {
        ...retrieveMachineData.retrieveMachine.data,
        venueDockerURL: retrieveVenueData.retrieveVenue.data.venueDockerURL,
        venueDockerIP: retrieveVenueData.retrieveVenue.data.venueDockerIP,
      };

      setCameraPayload(cameraPayload);
    }
  }, [retrieveMachineData, retrieveVenueData]);

  const { addToast } = useToastModal();

  if (retrieveMachineError || retrieveVenueError) {
    addToast("Something went wrong!", "error");
  }

  return (
    <>
      {(retrieveMachineLoading || retrieveVenueLoading) && (
        <Styles.SpinnerContainer>
          <SpinnerComponent width={100} height={100} />
        </Styles.SpinnerContainer>
      )}
      {cameraPayload && <VideoPreview selectedCamera={cameraPayload} />}
    </>
  );
};

export default PlayerContainer;
