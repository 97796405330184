import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components/macro";
import ReactLassoSelect from "./lasso";
import { DashboardContext } from "../../../context/DashboardContext";
import MaskingTable from "./MaskingTable";

const MaskingContainer = styled.div`
  position: relative;
  border: 0.3px solid #bbbbbb;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  width: ${({ width }) => `${width || 960}px`};
  height: ${({ height }) => `${height || 540}px`};
`;

const MaskingButton = styled.button`
  background: #5ca6b3;
  border: none;
  color: #ffffff;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  /* padding: 5px; */
  border: 4px solid transparent;
`;

const MaskingButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const convertVectorToPoints = (vectors) => {
  const vectorsObj = JSON.parse(vectors);
  return vectorsObj.map((row) => {
    return {
      closed: true,
      ...row,
    };
  });
};

export default function MultiSelect({
  inferenceModel,
  vectors,
  width,
  height,
  src,
  saveMask,
  closeMaskingModal,
}) {
  const [activeMaskId, setActiveMaskId] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [pointsArray, setPointsArray] = useState([]);
  const { setMaskUpdated, padmeModelColors, padmeModelTypes } = useContext(DashboardContext);

  useEffect(() => {
    // on mount, set active mask id if there are any
    const newPointsArray = convertVectorToPoints(vectors);

    if (newPointsArray.length) {
      setActiveMaskId(newPointsArray[0].maskId);
      setActiveIndex(0);
      setPointsArray(newPointsArray);
    }
  }, [setPointsArray]);

  const NEW_POLY_TEMPLATE = (maskId) => ({
    maskId,
    maskName: `Mask ${maskId}`,
    maskType: padmeModelTypes[0].value,
    maskColor: padmeModelColors[maskId - 1].label,
    maskStrokeColor: padmeModelColors[maskId - 1].stroke,
    maskFillColor: padmeModelColors[maskId - 1].fill,
    maskNote: "",
    maskWaitTime: "1",
    maskThreshold1: "",
    maskThreshold2: "",
  });

  const saveAndClose = async () => {
    // write to parent
    await saveMask(pointsArray);
    setMaskUpdated((prev) => !prev);
    closeMaskingModal();
  };

  const saveOnly = async () => {
    // write to parent
    await saveMask(pointsArray);
  };

  const createNewPoly = () => {
    // create new polygon and set that as active
    const { nextMaskId, nextMaskIndex } = findNextMaskId(pointsArray);
    setPointsArray([
      ...pointsArray,
      {
        closed: false,
        points: [{ x: 100, y: 100 }],
        ...NEW_POLY_TEMPLATE(nextMaskId),
      },
    ]);

    // apply new indez
    setTimeout(() => {
      setActiveIndex(nextMaskIndex);
      setActiveMaskId(nextMaskId);
    }, 250);
  };

  const findNextMaskId = (currentPointsArray) => {
    const lastArray = currentPointsArray[currentPointsArray.length - 1];
    const nextMaskIndex = currentPointsArray.length;
    // try to start from 1
    if (!lastArray) return { nextMaskId: 1, nextMaskIndex: 0 };

    const lastMaskId = lastArray.maskId;
    return { nextMaskId: lastMaskId + 1, lastMaskId, nextMaskIndex };
  };

  const createRectangle = () => {
    // create new polygon and set that as active
    const { nextMaskId, nextMaskIndex } = findNextMaskId(pointsArray);
    setPointsArray([
      ...pointsArray,
      {
        closed: true,
        points: [
          { x: 50, y: 50 },
          { x: 700, y: 50 },
          { x: 700, y: 400 },
          { x: 50, y: 400 },
        ],
        ...NEW_POLY_TEMPLATE(nextMaskId),
      },
    ]);

    // apply new indez
    setTimeout(() => {
      setActiveIndex(nextMaskIndex);
      setActiveMaskId(nextMaskId);
    }, 250);
  };

  const selectMask = (mask) => {
    const { maskId } = mask;
    // find index of mask
    const nextMaskIndex = pointsArray.findIndex((row) => row.maskId === maskId);
    setActiveIndex(nextMaskIndex);
    setActiveMaskId(maskId);
  };

  const updateMask = (mask) => {
    // replace matching mask, and update state
    const newPointsArray = pointsArray.map((row) => {
      if (row.maskId === mask.maskId) {
        return mask;
      }
      return row;
    });

    setPointsArray(newPointsArray);
  };

  const deleteMask = (mask) => {
    // get maskId from mask
    const { maskId } = mask;

    // filter out mask via mask id
    const newPolyArray = pointsArray.filter((row) => row.maskId !== maskId);

    const { lastMaskId, nextMaskIndex } = findNextMaskId(newPolyArray);

    // empty array
    if (!newPolyArray.length) {
      setActiveIndex(0);
      setActiveMaskId(null);

      setTimeout(() => {
        setPointsArray([]);
      }, 250);
    } else {
      setActiveIndex(nextMaskIndex - 1);
      setActiveMaskId(lastMaskId);

      setTimeout(() => {
        setPointsArray(newPolyArray);
      }, 250);
    }
  };

  const handleLassOnChange = (value) => {
    // update points array
    const newPointsArray = [...pointsArray];
    newPointsArray[activeIndex].points = value[activeIndex].points;
    newPointsArray[activeIndex].closed = value[activeIndex].closed;
    // console.log("newPointsArray", newPointsArray);
    setPointsArray(newPointsArray);
  };

  // console.log(`MultiSelect ${inferenceModel}`, pointsArray);

  return (
    <div>
      <MaskingContainer width={width} height={height}>
        <div
          style={{
            padding: 0,
            width,
            height,
          }}
          className="lasso-wrapper"
        >
          {pointsArray.length > 0 && (
            <ReactLassoSelect
              activeIndex={activeIndex}
              value={pointsArray}
              src={src}
              width={width}
              height={height}
              onActiveIndexChange={setActiveIndex}
              onChange={handleLassOnChange}
              onComplete={(value) => {
                if (!value.length) return;
              }}
            />
          )}
        </div>
      </MaskingContainer>
      <MaskingButtonGroup>
        <div
          style={{ display: "flex", width: "75%", marginTop: 10, justifyContent: "space-between" }}
        >
          <MaskingButton onClick={createRectangle}>Rectangle</MaskingButton>
          <MaskingButton onClick={createNewPoly}>New Poly</MaskingButton>
          <MaskingButton onClick={saveOnly}>Save Changes</MaskingButton>
          <MaskingButton onClick={saveAndClose}>Save and Close</MaskingButton>
          <MaskingButton
            onClick={() => {
              setMaskUpdated((prev) => !prev);
              closeMaskingModal();
            }}
          >
            Cancel and Close
          </MaskingButton>
        </div>
        <MaskingTable
          padmeModelColors={padmeModelColors}
          padmeModelTypes={padmeModelTypes}
          dataRows={pointsArray}
          activeMaskId={activeMaskId}
          selectMask={selectMask}
          deleteMask={deleteMask}
          updateMask={updateMask}
          showWaitTime={inferenceModel === "queue_time" || inferenceModel === "dwell_time"}
        />
      </MaskingButtonGroup>
    </div>
  );
}
