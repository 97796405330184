import styled from "styled-components/macro";

const styles = {
  CheckBoxLabel: styled.label`
    font-family: "SFProText";
    font-size: 14px;
    color: #888888;
  `,
  CheckboxContainer: styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: 45%;
    margin-top: 3px;
    margin-bottom: 3px;
  `,
  Checkbox: styled.input`
    cursor: "pointer";
    // margin: 0;
    accent-color: "#5CA6B3";
    margin-right: 3;
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Label: styled.label`
    font-family: "Poppins500";
    font-size: 14px;
    color: #37474f;
  `,
  BoxContainer: styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 270px;
    justify-content: space-between;
  `,
};

export default styles;
