import React from "react";
//Styles
import "react-toastify/dist/ReactToastify.css";
//GraphQL
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./configs/apollo/client";
//Components
import AppRoutes from "./routes";
import { ToastContainer } from "react-toastify";
//Context
import AuthContextProvider from "./context/authContext";
import DashboardContextProvider from "./context/DashboardContext";
import ToastModalContextProvider from "./context/ToastModalContext";
import GraphQLHelperContextProvider from "./context/GraphQLHelperContext";

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <AuthContextProvider>
        <ToastModalContextProvider>
          <DashboardContextProvider>
            <GraphQLHelperContextProvider>
              <AppRoutes />
              <ToastContainer />
            </GraphQLHelperContextProvider>
          </DashboardContextProvider>
        </ToastModalContextProvider>
      </AuthContextProvider>
    </ApolloProvider>
  );
}

export default App;
