import React, { useState, useEffect } from "react";
import Styles from "../styles";
//Tools
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//Components
import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import DefaultDashBtn from "../../../components/Shared/Buttons/DefaultDashBtn";
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "components/Shared/Buttons/SecondaryBtn/SecondaryBtn";

const inviteUserSchema = yup.object().shape({
  userEmail: yup
    .string()
    .email("Please type a valid email.")
    .required("Email is a required field."),
  /* permissionType: yup.string().required("Permission name is a required field."), */
});

const InviteUserGeneralModal = ({
  showModal,
  setShowModal,
  title,
  handleModalForwardClick,
  companyId,
  venues,
  inviteUser,
}) => {
  const [selectedAccessLevel, setSelectedAccessLevel] = useState("");
  const [chosenVenue, setChosenVenue] = useState("");
  const [chosenVenuePermission, setChosenVenuePermission] = useState("");
  const [assignedVenues, setAssignedVenues] = useState([]);
  const [venueIdsToSubmit, setVenueIdsToSubmit] = useState("");

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(inviteUserSchema),
  });

  useEffect(() => {
    const venueIds = assignedVenues.map((venue) => {
      return {
        id: venue.venueId,
        permissionType: venue.permission,
      };
    });
    setVenueIdsToSubmit(JSON.stringify(venueIds));
  }, [assignedVenues]);

  const handleClickCancelBtn = () => {
    reset();
    setShowModal((prev) => !prev);
    setSelectedAccessLevel("");
    setAssignedVenues([]);
  };

  const handleCorrectVenueIds = () => {
    if (assignedVenues.length > 0) {
      return venueIdsToSubmit;
    }

    if (chosenVenue && chosenVenuePermission) {
      return JSON.stringify([{ id: chosenVenue, permissionType: chosenVenuePermission }]);
    }

    return null;
  };

  //Handle submit Form, update Area
  const submitForm = (inviteUserFormData) => {
    inviteUser({
      variables: {
        companyId: companyId,
        userEmail: inviteUserFormData.userEmail,
        permissionType:
          selectedAccessLevel === "BY_COMPANY"
            ? inviteUserFormData.companyPermissionType
            : inviteUserFormData.accessLevel,
        venueIds: handleCorrectVenueIds(),
      },
    });
    reset();
    setShowModal((prev) => !prev);
    setSelectedAccessLevel("");
    setAssignedVenues([]);
    setChosenVenue("");
    setChosenVenuePermission("");
    setVenueIdsToSubmit("");
  };

  usePreventEnterAction(submitForm);

  const handleAccessLevelChange = (e) => {
    setSelectedAccessLevel(e.target.value);
  };

  const findVenueName = (venueId) => {
    const venue = venues.find((venue) => venue.id === venueId);
    return venue?.venueName;
  };

  const handleClicAssingNewVenue = () => {
    if (chosenVenue && chosenVenuePermission) {
      setAssignedVenues((prev) => [
        ...prev,
        {
          venueId: chosenVenue,
          permission: chosenVenuePermission,
          venueName: findVenueName(chosenVenue),
        },
      ]);
    }
  };

  const handleRemoveVenue = (venueId) => {
    //remove venue from assignedVenues that matched venueId
    setAssignedVenues((prev) => prev.filter((venue) => venue.venueId !== venueId));
  };

  const renderAssignedVenue = (venue) => {
    return (
      <div
        style={{
          display: "flex",
          gap: "0.5rem",
          alignItems: "center",
        }}
      >
        <Styles.AddedVenueContainer>{venue.venueName}</Styles.AddedVenueContainer>
        <Styles.AddedVenueContainer>{venue.permission}</Styles.AddedVenueContainer>
        <div>
          <Styles.RemoveVenueIcon onClick={() => handleRemoveVenue(venue.venueId)} />
        </div>
      </div>
    );
  };

  return (
    <>
      <ModalWithChildren
        showModal={showModal}
        setShowModal={setShowModal}
        title={title}
        handleModalForwardClick={handleModalForwardClick}
        maxWidth500={true}
      >
        <Styles.Form onSubmit={handleSubmit(submitForm)}>
          <Styles.InnerFormContainer>
            <Styles.InputAndErrorContainer>
              <Styles.Label>User email</Styles.Label>
              <Styles.Input
                type="text"
                name="userEmail"
                /* placeholder="User Email ..." */
                {...register("userEmail")}
              />
              <Styles.SubmitError>{errors.userEmail?.message}</Styles.SubmitError>
            </Styles.InputAndErrorContainer>

            <Styles.InputAndErrorContainer>
              <Styles.Label>Access Level</Styles.Label>
              <Styles.Select
                name="accessLevel"
                id="accessLevel"
                {...register("accessLevel")}
                onChange={(e) => handleAccessLevelChange(e)}
                defaultValue=""
              >
                <option value="" disabled>
                  Select user access level
                </option>
                <option value="BY_COMPANY">Assign to company *</option>
                <option value="BY_VENUE">Assign to venue(s)</option>
              </Styles.Select>
              <Styles.SubmitError>{errors.accessLevel?.message}</Styles.SubmitError>
            </Styles.InputAndErrorContainer>

            {selectedAccessLevel === "BY_COMPANY" ? (
              <Styles.InputAndErrorContainer>
                <Styles.Label>Permission type</Styles.Label>
                <Styles.Select
                  name="companyPermissionType"
                  id="companyPermissionType"
                  {...register("companyPermissionType")}
                  defaultValue=""
                >
                  <option value="" disabled>
                    Select company permission type
                  </option>
                  <option value="ADMIN">Admin</option>
                  <option value="VIEW_ONLY">View Only</option>
                </Styles.Select>
                <Styles.SubmitError>{errors.companyPermissionType?.message}</Styles.SubmitError>
              </Styles.InputAndErrorContainer>
            ) : selectedAccessLevel === "BY_VENUE" ? (
              <>
                {assignedVenues.length > 0
                  ? assignedVenues.map((venue) => renderAssignedVenue(venue))
                  : null}
                <div
                  style={{
                    display: "flex",
                    gap: "0.5rem",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Styles.InputAndErrorContainer>
                    <Styles.Select
                      name="venues"
                      id="venues"
                      defaultValue=""
                      onChange={(e) => setChosenVenue(e.target.value)}
                    >
                      <option value="" disabled>
                        Venue
                      </option>
                      {venues.map((venue) => (
                        <option key={venue.id} value={venue.id}>
                          {venue.venueName}
                        </option>
                      ))}
                    </Styles.Select>
                    <Styles.SubmitError>{errors.venues?.message}</Styles.SubmitError>
                  </Styles.InputAndErrorContainer>

                  <Styles.InputAndErrorContainer>
                    <Styles.Select
                      name="venuePermissionType"
                      id="venuePermissionType"
                      defaultValue=""
                      onChange={(e) => setChosenVenuePermission(e.target.value)}
                    >
                      <option value="" disabled>
                        Venue permission type
                      </option>
                      <option value="Admin">Admin</option>
                      <option value="ViewOnly">View Only</option>
                    </Styles.Select>
                    <Styles.SubmitError>{errors.companyPermissionType?.message}</Styles.SubmitError>
                  </Styles.InputAndErrorContainer>
                </div>

                <Styles.AddNewVenueContainer>
                  <Styles.AddNewVenueText onClick={handleClicAssingNewVenue}>
                    + More venues
                  </Styles.AddNewVenueText>
                </Styles.AddNewVenueContainer>
              </>
            ) : null}

            {selectedAccessLevel === "BY_COMPANY" ? (
              <Styles.Text>
                * User will have access to all current/future venues and access to company dashboard
              </Styles.Text>
            ) : null}
          </Styles.InnerFormContainer>

          <Styles.ButtonsContainer>
            <SecondaryBtn
              label="Cancel"
              withIcon={false}
              width="420px"
              height="40px"
              onClick={handleClickCancelBtn}
            />
            <PrimaryBtn
              label="Invite"
              withIcon={false}
              width="420px"
              height="40px"
              type="submit"
              id="submit"
            />
          </Styles.ButtonsContainer>
        </Styles.Form>
      </ModalWithChildren>
    </>
  );
};

export default InviteUserGeneralModal;
