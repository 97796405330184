import styled from "styled-components";
import { Link } from "react-router-dom";

export const Button = styled(Link)`
  border-radius: 50px;
  width: ${({ big }) => (big ? "219px" : "180px")};
  height: ${({ big }) => (big ? "60px" : "40px")};
  border: 1px solid #37474f;
  text-decoration: none;
  background: ${({ dark }) => (dark ? "#37474F" : "#FBFBFB")};
  color: ${({ dark_text }) => (dark_text ? "#37474F" : "#EFEFEF")};
  font-family: Poppins600;
  font-size: ${({ big }) => (big ? "16px" : "14px")};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
`;
