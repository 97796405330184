import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { AiFillCaretDown } from "react-icons/ai";

const styles = {
  Nav: styled.nav`
    height: 105px;
    display: flex;
    justify-content: center;
    background-color: #fbfbfb;

    @media screen and (max-width: 900px) {
      transition: 0.8s all ease;
    }
  `,
  NavbarContainer: styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 40px;
    max-width: 1440px;
    /* @media screen and (max-width: 768px) {
      padding: 0 20px;
    } */
    @media screen and (max-width: 480px) {
      padding: 0 20px;
    }
  `,
  BrandContainer: styled.div`
    display: flex;
    align-items: center;
    gap: 30px;
    text-decoration: none;
    border-bottom: ${(props) => (props.activeTab ? "4px solid #37474f" : "4px solid #fbfbfb")};
    @media screen and (max-width: 300px) {
      gap: 15px;
    }
  `,
  BrandLink: styled.a`
    cursor: pointer;
    display: flex;
    align-items: center;
    text-decoration: none;
    gap: 20px;
    border-bottom: ${(props) => (props.activeTab ? "4px solid #37474f" : "4px solid #fbfbfb")};
  `,
  PadmeText: styled.span`
    font-family: Poppins800;
    font-size: 40px;
    line-height: 35px;
    color: #37474f;
    padding-top: 13px;
  `,
  NavMenu: styled.div`
    display: flex;
    gap: 2rem;
    @media screen and (max-width: 950px) {
      display: none;
    }
  `,
  NavItem: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 4px solid #fbfbfb;
    border-bottom: ${(props) => (props.activeTab ? "4px solid #37474f" : "4px solid #fbfbfb")};
  `,
  ProfileContainer: styled.div`
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
    gap: 0.25rem;
    height: 100%;
    cursor: pointer;
  `,
  MobileIcon: styled.div`
    display: none;

    @media screen and (max-width: 950px) {
      display: block;

      position: absolute;
      top: 18px;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
      color: #000000;
    }
  `,
  DropdownIcon: styled(AiFillCaretDown)`
    color: #37474f;
    width: 11px;
    height: 11px;
  `,
  TalkToUsBtn: styled.a`
    display: flex;
    width: 180px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background-color: #37474f;
    font-family: Poppins600;
    font-size: 14px;
    color: #fbfbfb;
    font-weight: 600;
    text-decoration: none;
  `,
};

export default styles;
