import React from "react";
import PropTypes from "prop-types";

import { SVGPolyline } from "./SVGPolyline";
import { SVGGroup } from "./SVGGroup";
import { SVGPoint } from "./SVGPoint";
import { SVGHelper } from "./SVGHelpers";

import {
  roundPointCoordinates,
  arePathPointListEqual,
  arePointListEqual,
  objectToClassName,
  getClippedImageCanvas,
  approximateToAnAngleMultiplicity,
  approximateToAngles,
  calculateAnglesBeetwenPoints,
  findPointByPosition,
  pointsDistance,
} from "./helpers";

import { pathsReducer, pathsActions } from "./pathsReducer";

import { useToastModal } from "../../../../context/ToastModalContext";

export class ReactLasso extends React.Component {
  state;
  imageRef = React.createRef();
  svgRef = React.createRef();
  svg = new SVGHelper(() => this.svgRef?.current);
  pathAngles = [];
  paths = [{ closed: false, points: [] }];
  lastUpdatedPoints = [];
  lastEmittedPathsPoints = [];
  lastUpdatedPathsPoints = [];
  imgError = false;
  setPathFromPropsOnMediaLoad = true;

  constructor(props) {
    super(props);
    this.state = {
      paths: [{ closed: false, points: [] }],
      pointer: {
        x: props.viewBox.width / 2,
        y: props.viewBox.width / 2,
      },
    };
  }

  render() {
    // console.log("ReactLasso render", this.state);
    return (
      <div
        className={objectToClassName({
          ReactFreeSelect__Component: true,
          ReactFreeSelect__Disabled: this.props.disabled,
        })}
        style={{
          display: "inline-block",
          position: "relative",
          width: "100%",
          height: "100%",
          margin: "0",
          padding: "0",
          fontSize: "0",
          cursor: this.props.disabled ? "not-allowed" : "default",
          ...this.props.style,
        }}
      >
        <img
          ref={this.imageRef}
          src={this.props.src}
          alt={this.props.imageAlt}
          crossOrigin={this.props.crossOrigin}
          style={this.props.imageStyle}
          onLoad={this.onMediaLoaded}
          onError={this.onMediaError}
        />
        <svg
          ref={this.svgRef}
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            overflow: "hidden",
            userSelect: "none",
            touchAction: "none",
          }}
          viewBox={`0 0 ${this.props.width} ${this.props.height}`}
          onMouseMove={this.onMouseTouchMove}
          onTouchMove={this.onMouseTouchMove}
          onClick={this.onClick}
          onTouchEnd={this.onTouchEnd}
          onContextMenu={this.onContextMenu}
          onMouseLeave={this.hidePointer}
        >
          {/* <rect visibility="hidden" /> */}
          {this.renderPathsLines()}
          {this.renderActivePathsLines()}
        </svg>
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.disabled && this.props.disabled && !this.paths[this.props.activeIndex].closed) {
      this.hidePointer();
    }
    if (prevProps.src && prevProps.src !== this.props.src) {
      // source change causes reset
      this.dispatchPathAction({ type: pathsActions.RESET });
    } else if (!arePathPointListEqual(prevProps.value, this.props.value)) {
      if (this.isLoaded()) {
        // load new paths
        this.setPathStateFromProps();
      } else {
        this.setPathFromPropsOnMediaLoad = true;
      }
    }
  }

  renderActivePathsLines() {
    const pathIndex = this.props.activeIndex;
    const currentActivePath = this.state.paths[pathIndex];
    const roundedPoints = currentActivePath.points.map((point) => roundPointCoordinates(point));

    return (
      <SVGGroup key={this.props.activeIndex}>
        <SVGPolyline
          key={this.props.activeIndex}
          color={currentActivePath.maskStrokeColor}
          draggable={currentActivePath.closed}
          onDrag={this.onShapeDrag}
          onDragEnd={this.onDragEnd}
          animate={this.props.activeIndex === pathIndex}
          path={roundedPoints.concat(
            currentActivePath.closed ? roundedPoints[0] : roundPointCoordinates(this.state.pointer),
          )}
          activeIndex={this.props.activeIndex}
          pathIndex={pathIndex}
          addPointToPath={this.addPointToPath}
        />
        {this.renderPathsPoint(currentActivePath, pathIndex)}
      </SVGGroup>
    );
  }

  renderPathsLines() {
    return this.state.paths.map((path, pathIndex) => {
      if (pathIndex === this.props.activeIndex) return null;

      const roundedPoints = path.points.map((point) => roundPointCoordinates(point));
      return (
        <SVGGroup key={pathIndex}>
          <SVGPolyline
            key={pathIndex}
            color={path.maskStrokeColor}
            onDrag={this.onShapeDrag}
            onDragEnd={this.onDragEnd}
            path={roundedPoints.concat(
              path.closed ? roundedPoints[0] : roundPointCoordinates(this.state.pointer),
            )}
            pathIndex={pathIndex}
            // addPointToPath={this.addPointToPath}
          />
        </SVGGroup>
      );
    });
  }

  renderPathsPoint(path, pathIndex) {
    return path.points.map((point, idx) => {
      const { x, y } = roundPointCoordinates(point);
      return (
        <SVGPoint
          key={idx}
          color={path.maskStrokeColor}
          x={x}
          y={y}
          draggable={!this.props.disabled}
          style={{
            cursor: !idx && path.points.length > 2 && !path.closed ? "pointer" : undefined,
          }}
          onDrag={({ dx, dy }) => this.onPointDrag(idx, { dx, dy })}
          onDragEnd={this.onDragEnd}
          onClickTouchEvent={() => this.onPointClick(idx)}
          idx={idx}
          pathIndex={pathIndex}
          deletePointFromPath={this.deletePointFromPath}
        />
      );
    });
  }

  addPointToPath = (pathIndex, pointOrigin) => {
    //create new points array from this.state.paths[pathIndex].points
    // add only to active and closed paths
    const paths = [...this.paths];
    const points = [...paths[pathIndex].points];

    // find the closest polyline to origin point
    const result = points
      .map((point, index) => {
        const nextPointIndex = index + 1 === points.length ? 0 : index + 1;
        return {
          lineIndex: index,
          ...point,
          x1: point.x,
          y1: point.y,
          x2: points[nextPointIndex].x,
          y2: points[nextPointIndex].y,
          distance: pointsDistance(
            pointOrigin.x,
            pointOrigin.y,
            point.x,
            point.y,
            points[nextPointIndex].x,
            points[nextPointIndex].y,
          ),
        };
      })
      .sort((a, b) => a.distance - b.distance);

    const indexToInsertNewPoint = result[0].lineIndex + 1;

    //insert new point origin to points array on indexToInsertNewPoint
    points.splice(indexToInsertNewPoint, 0, pointOrigin);

    paths[pathIndex].points = points;
    this.paths = paths;

    this.dispatchPathAction({
      type: pathsActions.CHANGE,
      payload: paths,
    });
  };

  deletePointFromPath = (pathIndex, pointIndex) => {
    const { addToast } = useToastModal(); // TODO: check
    const paths = [...this.paths];

    const points = [...paths[pathIndex].points];

    if (points.length >= 4) {
      //delete point from points array
      points.splice(pointIndex, 1);

      paths[pathIndex].points = points;

      this.paths = paths;

      this.dispatchPathAction({
        type: pathsActions.CHANGE,
        payload: paths,
      });
    } else {
      addToast("The polygon can't have less than 3 points!", "error");
    }
  };

  convertPoints(points) {
    const aspectRatio = this.getAspectRatio();
    return this.svg.convertViewboxPointsToReal(points).map(({ x, y }) => ({
      x: Math.round(x / aspectRatio.x),
      y: Math.round(y / aspectRatio.y),
    }));
  }

  checkIfPathsUpdated(wasClosedBefore) {
    const currentPath = this.paths[this.props.activeIndex];
    if (currentPath.closed || wasClosedBefore) {
      const convertedPoints = this.convertPoints(currentPath.points);
      if (!arePointListEqual(convertedPoints, this.lastUpdatedPoints)) {
        // complete update
        this.emitOnComplete(convertedPoints);
        this.lastUpdatedPoints = convertedPoints.map(({ x, y }) => ({ x, y }));
      }
    }
  }

  emitOnActiveIndexChange(activeIndex) {
    if (this.props.onActiveIndexChange) {
      this.props.onActiveIndexChange(activeIndex);
    }
  }

  emitOnChange(paths) {
    if (this.props.onChange) {
      const convertedPaths = paths.map((path) => {
        const { closed, points, ...restOfPath } = path;
        return {
          closed: closed,
          points: this.convertPoints(points),
          ...restOfPath,
        };
      });
      this.lastEmittedPathsPoints = convertedPaths;
      this.props.onChange(convertedPaths);
    }
  }

  emitOnComplete(convertedPoints) {
    if (this.props.onComplete) {
      this.props.onComplete(convertedPoints);
    }
  }

  setPointer({ x, y }, force = false) {
    if (force || !this.props.disabled) {
      this.setState({
        paths: this.paths,
        pointer: { x, y },
      });
    }
  }

  hidePointer = () => {
    const currentPath = this.paths[this.props.activeIndex];
    const lastPoint = currentPath.points[currentPath.points.length - 1] || {
      x: 0,
      y: 0,
    };
    this.setPointer({ ...lastPoint }, true); // tricky way to hide pointer line
  };

  dispatchPathAction(action) {
    // console.log("dispatchPathAction", action);
    const pathWasClosedBefore = this.paths[this.props.activeIndex].closed;

    const [newPathsState, pathsModified] = pathsReducer(this.paths, this.props.activeIndex, action);

    // console.log("dispatchPathAction", newPathsState, pathsModified);

    if (pathsModified) {
      // copy state to local variable
      this.paths = newPathsState;

      this.setState({
        pointer: action.pointer ||
          this.paths[this.props.activeIndex].points[
            this.paths[this.props.activeIndex].points.length - 1
          ] || { x: 0, y: 0 },
        paths: newPathsState,
      });

      this.pathAngles[this.props.activeIndex] = calculateAnglesBeetwenPoints(
        newPathsState[this.props.activeIndex].points,
      );

      this.emitOnChange(this.paths);
      if (![pathsActions.MODIFY, pathsActions.MOVE].includes(action.type)) {
        this.checkIfPathsUpdated(pathWasClosedBefore); // optimized version of onChange
      }
    }
  }

  isLoaded() {
    if (this.imgError || !this.svgRef.current) return false;
    const svg = this.svgRef.current;
    return !!(svg.width.baseVal.value && svg.height.baseVal.value);
  }

  getAspectRatio() {
    if (!this.imageRef.current) {
      return { x: NaN, y: NaN };
    }
    // original * aspectRatio = size
    return {
      x: this.imageRef.current.clientWidth / this.imageRef.current.naturalWidth,
      y: this.imageRef.current.clientHeight / this.imageRef.current.naturalHeight,
    };
  }

  setPathStateFromProps() {
    // apply data from parent
    if (arePathPointListEqual(this.lastEmittedPathsPoints, this.props.value)) return;
    const aspectRatio = this.getAspectRatio();

    // rebuild new paths
    const value = this.props.value.map((row) => {
      const { closed, points, ...rest } = row;
      return {
        closed: closed,
        points: this.svg.convertRealPointsToViewbox(
          points.map(({ x, y }) => ({
            x: x * aspectRatio.x,
            y: y * aspectRatio.y,
          })),
        ),
        ...rest,
      };
    });

    this.dispatchPathAction({
      type: pathsActions.CHANGE,
      payload: value,
    });
  }

  getBorder() {
    return this.svg
      .getBorderPoints()
      .map((point) => roundPointCoordinates(point))
      .map(({ x, y }) => ({ x: x - 1, y: y + 1 })); // fishy bug here so i have to margin area
  }

  getMousePosition(e, lookupForNearlyPoints = true, lookupForApproximation = true) {
    let pointer = this.svg.getMouseCoordinates(e);
    if (lookupForApproximation) {
      const ctrlCmdPressed = navigator.platform.includes("Mac") ? e.metaKey : e.ctrlKey;
      const lastPoint =
        this.paths[this.props.activeIndex].points[
          this.paths[this.props.activeIndex].points.length - 1
        ];
      // straighten path from last point
      if (ctrlCmdPressed && lastPoint) {
        if (e.shiftKey) {
          // lookup for parallel lines
          pointer = approximateToAngles(lastPoint, pointer, this.angles);
        } else {
          // angle approximation to 15 deg
          pointer = approximateToAnAngleMultiplicity(lastPoint, pointer, Math.PI / 12);
        }
      }
    }
    const { point, index } = findPointByPosition(
      this.paths[this.props.activeIndex].points,
      pointer,
      10,
    );
    if (lookupForNearlyPoints && index > -1) {
      pointer = { ...point };
    }
    return [pointer, { point, index }];
  }

  // Events
  onShapeDrag = ({ dx, dy }) => {
    const newPath = this.paths[this.props.activeIndex].points.map(({ x, y }) => ({
      x: x + dx,
      y: y + dy,
    }));
    if (!newPath.some((point) => this.svg.isAboveTheBorder(point))) {
      this.dispatchPathAction({
        type: pathsActions.MOVE,
        payload: { x: dx, y: dy },
      });
    }
  };

  onPointDrag = (idx, { dx, dy }) => {
    const point = { ...this.paths[this.props.activeIndex].points[idx] };
    point.x += dx;
    point.y += dy;
    if (!this.svg.isAboveTheBorder(point)) {
      this.dispatchPathAction({
        type: pathsActions.MODIFY,
        payload: { ...point, index: idx },
      });
    }
  };

  onPointClick = (idx) => {
    if (this.isLoaded() && !this.props.disabled && !this.paths[this.props.activeIndex].closed) {
      this.dispatchPathAction({
        type: pathsActions.ADD,
        payload: this.paths[this.props.activeIndex].points[idx],
      });
    }
  };

  onDragEnd = () => {
    this.checkIfPathsUpdated(false);
  };

  onMediaLoaded = (e) => {
    if (this.setPathFromPropsOnMediaLoad) {
      this.setPathStateFromProps();
      this.setPathFromPropsOnMediaLoad = false;
    }
    this.imgError = false;
    this.props.onImageLoad(e);
  };

  onMediaError = (e) => {
    this.dispatchPathAction({ type: pathsActions.RESET });
    this.imgError = true;
    this.props.onImageError(e);
  };

  onClickTouchEvent = (e) => {
    if (this.isLoaded() && !this.props.disabled) {
      // check if current path is closed
      const currentPath = this.paths[this.props.activeIndex];
      if (currentPath.closed) {
        // if closed, do nothing
        /*
        if (e.target === this.svgRef.current) {
          this.dispatchPathAction({
            type: pathsActions.RESET
          });
        }
        */
        return;
      }

      const [pointer] = this.getMousePosition(e);
      if (!this.svg.isAboveTheBorder(pointer)) {
        // first creation
        // console.log("onClickTouchEvent", roundPointCoordinates(pointer, 1e3));
        this.dispatchPathAction({
          type: pathsActions.ADD,
          payload: roundPointCoordinates(pointer, 1e3),
          pointer,
        });
      } else {
        this.hidePointer();
      }
    }
  };

  onClick = (e) => {
    this.onClickTouchEvent(e);
  };

  onTouchEnd = (e) => {
    if (e.cancelable) {
      e.preventDefault();
      this.onClickTouchEvent(e);
    }
    this.hidePointer();
  };

  onMouseTouchMove = (e) => {
    if (this.isLoaded()) {
      const [pointer] = this.getMousePosition(e);
      this.setPointer(pointer);
    }
  };

  // delete point when path is not closed
  onContextMenu = (e) => {
    if (this.isLoaded()) {
      e.preventDefault();
      if (!this.props.disabled && !this.paths[this.props.activeIndex].closed) {
        const [pointer, { index }] = this.getMousePosition(e);
        if (index > -1) {
          this.dispatchPathAction({
            type: pathsActions.DELETE,
            payload: index,
            pointer,
          });
        } else {
          this.setPointer(pointer);
        }
      }
    }
  };

  static propTypes = {
    value: PropTypes.arrayOf(
      PropTypes.shape({
        closed: PropTypes.bool,
        points: PropTypes.arrayOf(
          PropTypes.exact({
            x: PropTypes.number.isRequired,
            y: PropTypes.number.isRequired,
          }),
        ),
      }),
    ),
    style: PropTypes.shape({}),
    viewBox: PropTypes.exact({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onComplete: PropTypes.func,
    src: PropTypes.string.isRequired,
    imageAlt: PropTypes.string,
    crossOrigin: PropTypes.string,
    imageStyle: PropTypes.shape({}),
    onImageLoad: PropTypes.func,
    onImageError: PropTypes.func,
  };
  static defaultProps = {
    value: [],
    style: {},
    imageStyle: {},
    viewBox: { width: 1e3, height: 1e3 },
    disabled: false,
    onImageError: Function.prototype,
    onImageLoad: Function.prototype,
  };
}

export { ReactLasso as default, ReactLasso as Component, getClippedImageCanvas as getCanvas };
