import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import { RiDashboardLine } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";

const styles = {
  DashboardIcon: styled(RiDashboardLine)`
    width: 1rem;
    height: 1.5rem;
  `,
  LogoutIcon: styled(MdLogout)`
    width: 1rem;
    height: 1.5rem;
  `,
  ProfileIcon: styled(CgProfile)`
    width: 1rem;
    height: 1.5rem;
  `,
  DropDownContainer: styled.div`
    background: #ffffff;
    border: 1px solid #e5e4e2;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    width: 9rem;
    position: absolute;
    top: 5.5rem;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 1000;

    /* @media screen and (max-width: 768px) {
      width: 100%;
      position: absolute;
      top: 515px;
      border: none;
      box-shadow: none;
      background-color: transparent;
      align-items: center;
    } */
  `,
  DropDownItemContainer: styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0.7rem 0.5rem;
    width: 100%;
    gap: 0.5rem;
    color: #37474f;
    cursor: pointer;
    :hover {
      background: #37474f;
      color: #ffffff;
      border-radius: ${({ borderbottom }) => (borderbottom ? "0 0 5px 5px" : "none")};
    }

    /*  @media screen and (max-width: 768px) {
      font-size: 1.5rem;
      list-style: none;
      transition: 0.2s ease-in-out;
      color: #fff;
    } */
  `,
  DropDownItem: styled.span`
    cursor: pointer;
    font-family: "Poppins600";
    font-size: 14px;
  `,
  LinkText: styled.span`
    cursor: pointer;
    font-family: "Poppins600";
    font-size: 14px;
  `,
  DropDownLink: styled(Link)`
    cursor: pointer;
    padding: 0.7rem 0.5rem;
    text-decoration: none;
    font-family: "Poppins600";
    font-size: 14px;
    color: #37474f;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    &:hover {
      color: #ffffff;
      background-color: #37474f;
      border-radius: ${({ bordertop }) => (bordertop ? "5px 5px 0 0" : "none")};
    }
    &:focus,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }
  `,
};

export default styles;
