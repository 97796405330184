import styled from "styled-components/macro";

const styles = {
  PageContainer: styled.div`
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 105px);
    background: radial-gradient(
      circle at 36% 0%,
      rgba(0, 188, 212, 0.05) 0%,
      rgba(239, 239, 239, 1) 100%
    );
    @media screen and (max-width: 480px) {
      align-items: flex-start;
    }
  `,
  AlignmentContainer: styled.div`
    width: 100%;
    max-width: 1440px;
    padding: 0 4rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width: 1024px) {
      justify-content: center;
    }
    @media screen and (max-width: 768px) {
      padding: 0 2rem;
    }
    @media screen and (max-width: 480px) {
      padding: 0 1rem;
    }
  `,
  ImageContainer: styled.div`
    z-index: 10;
    position: relative;
    top: 180px;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  `,
  ErrorAndLoadingContainer: styled.div`
    margin: 2rem 0;
    width: 50%;
    height: 50%;
    border-radius: 30px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  ErrorMessage: styled.span`
    font-family: PoppinsRegular;
    font-size: 14px;
    margin-bottom: 1rem;
    color: #ff3333;
  `,
};

export default styles;
