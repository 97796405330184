import styled from "styled-components/macro";

const styles = {
  PageContainer: styled.div`
    min-height: calc(100vh - 105px);
    display: flex;
    justify-content: center;
    background-color: "#FFFFFF";
    background: radial-gradient(
      circle at 0% 51%,
      rgba(55, 158, 165, 0.12) 0%,
      rgba(229, 229, 229, 1) 100%
    );
  `,
  AlignmentContainer: styled.div`
    width: 100%;
    max-width: 1440px;
    padding: 0 4rem;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 768px) {
      padding: 0 2rem;
    }
    @media screen and (max-width: 480px) {
      padding: 0 1rem;
    }
  `,
  SectionsContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    gap: 5rem;
    margin: 3rem 0;
  `,
  UploadImageSection: styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  `,
  TitleAndDescription: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  ModelTitle: styled.h1`
    font-family: CircularStd700;
    font-size: 32px;
    color: #000000;
    margin: 0;
  `,
  ModelDescription: styled.p`
    font-family: "Roboto400";
    font-size: 21px;
    color: #000000;
  `,
  ImagesAndForm: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
  `,
  TryOtherModelsSection: styled.section`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
  `,
  TryOtheresTitle: styled.h2`
    font-family: CircularStd700;
    font-size: 38px;
    color: #37474f;
    margin: 0;
  `,
  SwiperContainer: styled.div`
    width: 100%;
    & .swiper-pagination {
      display: flex;
      justify-content: flex-end;
    }
    & .swiper-wrapper {
      margin-bottom: 45px;
    }
    & .swiper-pagination-bullet-active {
      background: #37474f !important;
      height: 15px !important;
      width: 15px !important;
    }
    & .swiper-pagination-bullet {
      background: #37474f !important;
      height: 15px !important;
      width: 15px !important;
    }
  `,
  PreviwImageContainer: styled.div`
    width: 50%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  `,
  ImagePreview: styled.img`
    width: 100%;
    height: auto;
    border: 1px solid #37474f;
  `,
  ImageThumbNail: styled.img`
    width: 20%;
    height: auto;
    border: ${({ selected }) => (selected ? "3px solid #37474f;" : "none")};
    cursor: pointer;
  `,
  UploadImageContainer: styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  `,

  ImagesThumbNailContainer: styled.div`
    display: flex;
    gap: 0.5rem;
  `,
  ChooseImageText: styled.span`
    font-family: "Roboto400";
    font-size: 17px;
    color: #000000;
    line-height: 25px;
  `,
};

export default styles;
