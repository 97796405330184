import styled from "styled-components/macro";

const styles = {
  ManageCompaniesContainer: styled.div`
    display: flex;
    flex-direction: column;
  `,
  Header: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1.5rem;
  `,
  Title: styled.h1`
    font-family: Poppins600;
    font-size: 20px;
    color: #455a64;
    margin: 0;
  `,
  CardsContainer: styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  `,
};

export default styles;
