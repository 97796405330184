import React from "react";
// Styles
import Styled from "./PrimaryBtn.styled";
// Components
import Icon from "components/Shared/Icon/Icon";

const PrimaryBtn = ({
  label,
  withIcon = true,
  width,
  height,
  padding,
  handleClick,
  icon,
  iconSize,
  ...props
}) => {
  return (
    <Styled.StyledButton
      width={width}
      height={height}
      padding={padding}
      onClick={handleClick}
      {...props}
    >
      {/* Only render Icon if the icon name is provided */}
      {withIcon && icon && (
        <Icon name={icon} size={iconSize} className="tab-icon" isActive={true} />
      )}
      <Styled.ButtonLabel>{label}</Styled.ButtonLabel>
    </Styled.StyledButton>
  );
};

export default PrimaryBtn;
