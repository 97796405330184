import React, { useState, useEffect, createContext } from "react";

//Graphql
import { useLazyQuery, useMutation } from "@apollo/client";
import { SIGN_IN, SIGN_UP } from "../graphql/user";
import { ME } from "../graphql/graph";

export const AuthContext = createContext({});

const AuthContextProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem("access_token"));
  const [loggedUserAttributes, setLoggedUserAttributes] = useState(
    JSON.parse(localStorage.getItem("user_attributes")),
  );
  const [loggedUserId, setLoggedUserId] = useState(localStorage.getItem("user_id"));
  const [isSuperAdmin, setIsSuperAdmin] = useState(
    JSON.parse(localStorage.getItem("is_super_admin")),
  );
  const [userGroupId, setUserGroupId] = useState(localStorage.getItem("user_group_id"));
  const [signInGraphQlError, setSignInGraphQlError] = useState("");

  //GraphQl

  const [signIn, { data: sigInData, loading: singInLoading, error: signInError }] = //TODO Handle graphql errors and loading
    useLazyQuery(SIGN_IN, { context: { clientName: "user" } });

  const [meQuery, { data: meQueryData /* loading: meQueryLoading, error: meQueryError */ }] = //TODO Handle graphql errors and loading
    useLazyQuery(ME, { context: { clientName: "graph" } });

  const [
    signUp /* ,
    { data: sigUpData, loading: signUpLoading, error: signUpError }, */, //TODO Handle graphql errors and loading
  ] = useMutation(SIGN_UP, { context: { clientName: "user" } });

  // directly parsed local storage for data needed to establish persistent user session
  //Get token and user attributes on refresh page to persist session
  //TODO set timmer to logout and clear local storage after 90 minutes.
  // useEffect(() => {
  //   const storedToken = localStorage.getItem("access_token");
  //   const storeduserId = localStorage.getItem("user_id");
  //   const storedAttributes = JSON.parse(
  //     localStorage.getItem("user_attributes")
  //   );

  //   const storedUserGroupId = localStorage.getItem("user_group_id");
  //   const storedIsSuperAdmin = JSON.parse(localStorage.getItem("is_super_admin"));

  //   if (storedToken) {
  //     setIsAuthenticated(true);
  //     setLoggedUserId(storeduserId);
  //     setLoggedUserAttributes(storedAttributes);
  //     setUserGroupId(storedUserGroupId);
  //     setIsSuperAdmin(storedIsSuperAdmin);
  //   }
  // }, []);

  useEffect(() => {
    if (signInError) {
      setSignInGraphQlError(signInError.graphQLErrors[0].message);
    }
  }, [signInError]);

  useEffect(() => {
    if (meQueryData) {
      // regrab super admin flag, and include iwth user_attributes
      const userSuperAdmin = JSON.parse(localStorage.getItem("is_super_admin"));
      const newUserAttributes = {
        ...meQueryData.me.data,
        isSuperAdmin: userSuperAdmin,
      };

      localStorage.setItem("user_attributes", JSON.stringify(newUserAttributes));
      // console.log(meQueryData.me.data);
      setLoggedUserAttributes(newUserAttributes);
    }
  }, [meQueryData, sigInData]);

  //Handlers
  const handleSignUp = async (firstName, lastName, email, password) => {
    return signUp({
      variables: {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        password: password.trim(),
      },
    }).then((data) => {
      if (data.data.register.errors?.length > 0) {
        return data.data.create.errors[0].detail;
      }
    });
  };

  const handleSignIn = async (email, password) => {
    return signIn({
      variables: {
        email: email.trim(),
        password: password.trim(),
      },
    }).then((data) => {
      if (data.error) {
        return data.error.message;
      }
      setIsAuthenticated(true);
      localStorage.setItem("access_token", data.data.login.token);
      localStorage.setItem("expires_in", Math.round(Date.now() / 1000) + data.data.login.expiresIn);
      localStorage.setItem("refresh_token", data.data.login.refreshToken);
      localStorage.setItem("user_id", data.data.login.user.id);
      setLoggedUserId(data.data.login.user.id);
      if (data.data.login.isSuperAdmin) {
        setIsSuperAdmin(true);
        localStorage.setItem("is_super_admin", data.data.login.isSuperAdmin);
      }
      meQuery();
    });
  };

  const handleLogOut = () => {
    localStorage.clear();
    setIsAuthenticated(false);
    setLoggedUserAttributes("");
    setLoggedUserId("");
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        loggedUserAttributes,
        loggedUserId,
        isSuperAdmin,
        userGroupId,
        signInGraphQlError,
        setLoggedUserAttributes,
        handleSignUp,
        handleSignIn,
        handleLogOut,
        singInLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
