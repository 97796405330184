import React from "react";
import Styles from "./styles";

const Checkbox = ({ id, name, onChange, disabled, checked = true }) => {
  return (
    <Styles.Checkbox
      id={id}
      name={name}
      type="checkbox"
      disabled={disabled}
      onChange={onChange}
      checked={checked}
    />
  );
};

export const CheckboxWithLabel = ({ id, name, onChange, checked = true, label }) => {
  return (
    <Styles.CheckboxContainer>
      <Checkbox id={id} name={name} onChange={onChange} checked={checked} />
      <Styles.CheckBoxLabel>{label}</Styles.CheckBoxLabel>
    </Styles.CheckboxContainer>
  );
};

export const CheckboxesWithLabels = ({ checkboxes, title, onChange }) => {
  if (!checkboxes || !checkboxes.length) return null;

  return (
    <Styles.Container>
      <Styles.Label>{title}</Styles.Label>
      <Styles.BoxContainer>
        {checkboxes.map((checkbox) => (
          <CheckboxWithLabel
            key={checkbox.label}
            id={checkbox.label}
            name={checkbox.label}
            onChange={onChange}
            checked={checkbox.checked}
            label={checkbox.label}
          />
        ))}
      </Styles.BoxContainer>
    </Styles.Container>
  );
};

export default Checkbox;
