import React, { useState } from "react";
//Styles
import Styles from "./styles";
//Components
import ProfileImage from "../../../../components/ProfileImage";
import VenuesAndRole from "./VenuesAndRole";
import { useToastModal } from "../../../../context/ToastModalContext";

const UserRow = ({
  user,
  setShowAddVenueToUserModal,
  setClickedUserId,
  setShowVenuePermissionTypeModal,
  setUserCurrentVenuePermission,
  modalTitles,
  companyId,
  setShowCompanyPermissionTypeModal,
  setUserCompanyVariables,
  checkUserPermissionIsAdmin,
  loggedUserAttributes,
  setSelectedUserEmail,
  removeUserFromVenue,
  removeUserFromCompany,
}) => {
  const [rowToggle, setRowToggle] = useState(false);
  const [activeRowId, setActiveRowId] = useState("");
  const { addToast, addModal } = useToastModal();

  const handleOpenToggle = (userId) => {
    setRowToggle(!rowToggle);
    setActiveRowId(userId);
  };

  const handleCloseToggle = (userId) => {
    setRowToggle(!rowToggle);
    setActiveRowId(userId);
  };

  const handleClickRemoveUserFromCompany = (userId) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId)) {
      addModal(modalTitles.removeUserFromCompanyConfirmation, false, () => {
        removeUserFromCompany(userId);
      });
    } else {
      addToast("You must be a company Admin to remove this user!", "error");
    }
  };

  const handleClickEditCompanyPermission = (id, permissionType) => {
    if (checkUserPermissionIsAdmin(loggedUserAttributes, companyId)) {
      setUserCompanyVariables({
        id,
        permissionType,
      });
      setShowCompanyPermissionTypeModal((prev) => !prev);
    } else {
      addToast("You must be a company Admin to update this user permissions!", "error");
    }
  };

  return (
    <>
      <Styles.UserRowContainer rowToggle={rowToggle ? +true : +false}>
        <Styles.UserInfoWrapper>
          <ProfileImage size={40} />
          <Styles.DetailsContainer>
            <Styles.NameAndRole>
              <Styles.Name>{user.userFirstName}</Styles.Name>
              <Styles.Name>{user.userLastName}</Styles.Name>
              <Styles.Role>{user.permissionType}</Styles.Role>
              <Styles.Name></Styles.Name>
            </Styles.NameAndRole>
            <Styles.Email>{user.userEmail}</Styles.Email>
          </Styles.DetailsContainer>
        </Styles.UserInfoWrapper>
        <Styles.ActionIconsContainer>
          <Styles.EditIcon
            onClick={() => handleClickEditCompanyPermission(user.id, user.permissionType)}
          />
          <Styles.DeleteIcon onClick={() => handleClickRemoveUserFromCompany(user.userId)} />
          {rowToggle && user.userId === activeRowId ? (
            <Styles.ArrowUp
              onClick={() => handleCloseToggle(user.userId, user.id)}
              style={{ cursor: "pointer" }}
            />
          ) : (
            <Styles.ArrowDown
              onClick={() => handleOpenToggle(user.userId, user.id)}
              style={{ cursor: "pointer" }}
            />
          )}
        </Styles.ActionIconsContainer>
      </Styles.UserRowContainer>
      {rowToggle && user.userId === activeRowId && (
        <VenuesAndRole
          rowId={user.userId}
          activeRowId={activeRowId}
          rowToggle={rowToggle}
          user={user}
          setUserCurrentVenuePermission={setUserCurrentVenuePermission}
          setShowAddVenueToUserModal={setShowAddVenueToUserModal}
          setClickedUserId={setClickedUserId}
          setShowVenuePermissionTypeModal={setShowVenuePermissionTypeModal}
          modalTitles={modalTitles}
          companyId={companyId}
          checkUserPermissionIsAdmin={checkUserPermissionIsAdmin}
          loggedUserAttributes={loggedUserAttributes}
          setSelectedUserEmail={setSelectedUserEmail}
          removeUserFromVenue={removeUserFromVenue}
        />
      )}
    </>
  );
};

export default UserRow;
