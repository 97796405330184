import React, { useState, useEffect, useRef } from "react";
import { useMutation } from "@apollo/client/react";
import Styles from "../styles";
//Tools
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//Components
import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import DefaultDashBtn from "../../../components/Shared/Buttons/DefaultDashBtn";
import SpinnerComponent from "../../../components/Loader";
import FileUpload from "../../../components/FileUpload";
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";
import Checkbox from "../../../components/Checkbox";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "components/Shared/Buttons/SecondaryBtn/SecondaryBtn";

import { LIST_MODELS, CREATE_MODEL_VERSION } from "../../../graphql/graph";

//needed inside to dynamically set required field based on modal type
const inputFormSchema = yup.object().shape({
  modelName: yup.string().required("Model name is a required field."),
});

const fileTypes = [
  "PT",
  "PTH",
  "H5",
  "CKPT",
  "ONNX",
  "WEIGHTS",
  "TFLITE",
  "PB",
  "BIN",
  "ENGINE",
  "TRT",
  "XML",
  "CFG",
  "JSON",
  "PNG",
];

const AddModelVersionModal = ({ showModal, setShowModal, modelToUpdate, setModelToUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [selectedFilesError, setSelectedFilesError] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const uploaderRef = useRef();

  const { register, watch, setValue, handleSubmit, reset, unregister, formState } = useForm(
    {
      resolver: yupResolver(inputFormSchema),
    },
    {
      defaultValues: {
        modelVersionStatus: 1,
        modelVersionNotes: "",
      },
    },
  );

  const modelWatch = watch();

  useEffect(() => {
    reset(modelToUpdate);
  }, [reset, modelToUpdate]);

  const [addModelVersionMutation] = useMutation(CREATE_MODEL_VERSION, {
    context: { clientName: "graph" },
    refetchQueries: [
      {
        query: LIST_MODELS,
        context: { clientName: "graph" },
        variables: { listAll: 1 },
      },
    ],
  });

  const handleCheckboxChange = (event) => {
    const target = event.target;
    const value = target.checked ? 1 : 0;
    setValue("modelVersionStatus", value);
  };

  const handleClickCancelBtn = () => {
    reset();
    setShowModal((prev) => !prev);
  };

  const handleFileUpload = async (modelVersionId) => {
    console.log(uploaderRef.current);
    if (uploaderRef && uploaderRef.current) {
      await uploaderRef.current.handleModelFileUpload(modelVersionId);
    }
  };

  //Handle submit Form, create model
  const submitForm = async (inputForm) => {
    console.log("inputForm", inputForm);

    if (loading) return;

    setLoading(true);

    if (selectedFiles.length === 0) {
      setSelectedFilesError("Please select at least one file");
      setLoading(false);
      return;
    }

    const addResult = await addModelVersionMutation({
      variables: {
        modelId: modelToUpdate.id,
        modelVersionStatus: inputForm.modelVersionStatus,
        modelVersionNotes: inputForm.modelVersionNotes,
      },
    });

    console.log("addResult", addResult);

    const modelVersionId = addResult.data?.createModelVersion?.data?.id;

    // add model will create version as well, upload file once id is obtained
    await handleFileUpload(modelVersionId);

    setTimeout(async () => {
      setLoading(false);
      reset();
      setShowModal((prev) => !prev);
      setModelToUpdate(null);
    }, 1000);
  };

  usePreventEnterAction(submitForm);

  const modalTitle = `Add Version to ${modelToUpdate.modelName}`;

  return (
    <>
      <ModalWithChildren showModal={showModal} setShowModal={setShowModal} title={modalTitle}>
        <Styles.Form wide onSubmit={handleSubmit(submitForm)}>
          <Styles.InnerFormContainer>
            <FileUpload
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              selectedFilesError={selectedFilesError}
              ref={uploaderRef}
            />

            <Styles.InputAndErrorContainer>
              <Styles.Label>Version Description</Styles.Label>
              <Styles.TextArea
                type="text"
                name="modelVersionNotes"
                {...register("modelVersionNotes")}
              />
            </Styles.InputAndErrorContainer>

            <Styles.CheckboxAndIconContainer>
              <Styles.Label>Version Status</Styles.Label>
              <Styles.Label>
                <Checkbox
                  name="modelVersionStatus"
                  id="modelVersionStatus"
                  onChange={handleCheckboxChange}
                  checked={modelWatch.modelVersionStatus}
                />
                Enabled
              </Styles.Label>
            </Styles.CheckboxAndIconContainer>
          </Styles.InnerFormContainer>

          {loading ? (
            <>
              <Styles.SpinnerContainer>
                <SpinnerComponent width={"5rem"} height={"5rem"} />
              </Styles.SpinnerContainer>
            </>
          ) : (
            <Styles.ButtonsContainer>
              <SecondaryBtn
                label="Cancel"
                withIcon={false}
                width="420px"
                height="40px"
                onClick={handleClickCancelBtn}
              />
              <PrimaryBtn
                label="Add Version"
                withIcon={false}
                width="420px"
                height="40px"
                type="submit"
                id="submit"
              />
            </Styles.ButtonsContainer>
          )}
        </Styles.Form>
      </ModalWithChildren>
    </>
  );
};

export default AddModelVersionModal;
