import React, { useState, useEffect, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Styles from "../styles";
import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import ObjectSelector from "../../../components/ObjectSelector";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "components/Shared/Buttons/SecondaryBtn/SecondaryBtn";
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";
import { DashboardContext } from "../../../context/DashboardContext";

import { GET_VENUE, LIST_DOCKERS, LIST_MODELS } from "../../../graphql/graph";
import { GraphQLHelperContext } from "../../../context/GraphQLHelperContext";
import { jsonParse } from "../../../configs/helpers";
import Icon from "components/Shared/Icon/Icon";
import UseCaseGrid from "./UseCaseGrid/UseCaseGrid";

// Step-based validation schema
/* eslint-disable indent */
const generateAddCameraSchema = (addNewArea) => {
  const commonSchema = {
    machineName: yup.string().required("Camera name is a required field."),
    machineRTSPUrl: yup.string().required("Camera Url is a required field."),
    machineCustomId: yup.string().typeError("Custom Id must be a number.").notRequired(),
    machineModel: yup.string().required("Model type is a required field."),
    modelVersionId: yup.string().required("Model file is a required field for this model type"),
    dockerId: yup.string().required("Please provide a Docker Id"),
  };

  const areaSchema = addNewArea
    ? {
        newArea: yup.string().required("New area name is required"),
      }
    : {
        areaId: yup.string().nullable().required("Please select an area"),
      };

  return yup.object().shape({ ...commonSchema, ...areaSchema });
};
/* eslint-enable indent */

const AddUpdateCamera = ({ handleModalForwardClick, showModal, setShowModal, cameraObj }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedUseCase, setSelectedUseCase] = useState(null);
  const [addNewArea, setAddNewArea] = useState(false); // Tracks if "Add New Area" button was clicked
  const [areaOnChange, setAreaOnChange] = useState(""); // Tracks selected area

  const isUpdate = cameraObj?.id ? true : false;

  const { companyId } = useParams();
  const { padmeModels, objectDetectionItems } = useContext(DashboardContext);
  const { createMachine, updateMachine } = useContext(GraphQLHelperContext);

  const { data: modelData } = useQuery(LIST_MODELS, {
    context: "graph",
    variables: {
      companyId,
    },
  });

  const { data: dockersData } = useQuery(LIST_DOCKERS, {
    context: { clientName: "graph" },
    variables: {
      companyId,
    },
  });

  const [mlModels, setMlModels] = useState([]);
  const [sliderValue, setSliderValue] = useState(padmeModels[0].inferenceCPU.defaultValue);

  const [selectedModel, setSelectedModel] = useState(() => {
    if (!isUpdate) {
      return { ...padmeModels[0].inferenceCPU, type: padmeModels[0].type };
    }
    const model = padmeModels.find(({ value }) => value === cameraObj?.machineModel);
    return cameraObj?.machineType === "cuda"
      ? { ...model.inferenceGPU, type: model.type }
      : { ...model.inferenceCPU, type: model.type };
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    unregister,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(generateAddCameraSchema(addNewArea)),
    defaultValues: {
      machineName: "",
      machineRTSPUrl: "",
      machineCustomId: "",
      machineType: "cpu",
      machineOverlayLabel: "",
      machineInferenceInterval: padmeModels[0].inferenceCPU.defaultValue,
      machineSelectedObjects: [],
      machineIntervalType: "",
      modelVersionId: "",
      dockerId: "",
      areaId: null,
      areaName: null,
      machineModel: padmeModels.length && padmeModels[0].value,
    },
  });

  const watchAll = watch();

  useEffect(() => {
    if (cameraObj.id) {
      setValue("machineName", cameraObj.machineName);
      setValue("machineRTSPUrl", cameraObj.machineRTSPUrl);
      setValue("machineCustomId", cameraObj.machineCustomId);
      setValue("machineType", cameraObj.machineType);
      setValue("machineOverlayLabel", cameraObj.machineOverlayLabel);
      setValue("machineSelectedObjects", jsonParse(cameraObj.machineSelectedObjects));
      setValue("machineInferenceInterval", cameraObj.machineInferenceInterval);
      setValue("machineIntervalType", cameraObj.machineIntervalType);
      setValue("modelVersionId", cameraObj.modelVersionId);
      setValue("dockerId", cameraObj.dockerId);
      setValue("areaId", cameraObj.areaId);
      setValue("machineModel", cameraObj.machineModel);
      setSelectedUseCase(cameraObj.machineModel);
    } else {
      setSelectedUseCase(padmeModels[0].value);
    }
  }, [cameraObj]);

  const handleSelectedModelUpdate = useCallback(() => {
    if (watchAll.machineType && watchAll.machineModel) {
      const model = padmeModels.find(({ value }) => value === watchAll.machineModel);
      const selectThisModel =
        watchAll.machineType === "cuda"
          ? { ...model.inferenceGPU, type: model.type }
          : { ...model.inferenceCPU, type: model.type };
      if (selectThisModel.key !== selectedModel.key) {
        setSelectedModel(selectThisModel);
        setValue("machineInferenceInterval", selectThisModel.defaultValue);
      }
    }
  }, [watchAll, setSelectedModel]);

  const objectSuggestions = objectDetectionItems
    .map((item) => ({
      value: item.objectDetectionIndex,
      label: item.objectDetectionLabel,
    }))
    .toSorted((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (modelData?.listModel?.data && watchAll) {
      let newModelsList = [];
      const selectedModel = padmeModels.find((model) => model.value === watchAll.machineModel);
      if (selectedModel?.type === "density_estimation") {
        newModelsList = modelData.listModel.data.filter(
          (row) => row.modelType === selectedModel.type,
        );
      } else if (watchAll.machineSelectedObjects) {
        newModelsList = modelData?.listModel?.data.filter((row) => {
          const isSelectedModel = row.modelType === selectedModel?.type;
          const rowModelObjects = jsonParse(row.modelDetectionObjects).map(
            (object) => object.label,
          );
          const selectedObjects = watchAll?.machineSelectedObjects?.map((object) => object.label);
          const hasSelectedObjectsInRow = selectedObjects.every((sel) =>
            rowModelObjects.includes(sel),
          );
          return isSelectedModel && hasSelectedObjectsInRow;
        });
      }
      setMlModels(newModelsList);
      if (newModelsList.length) {
        setTimeout(() => {
          setValue("modelVersionId", watchAll.modelVersionId);
        }, 300);
      }
    }
  }, [modelData, watchAll.machineModel, setMlModels, watchAll.machineSelectedObjects]);

  useEffect(() => {
    handleSelectedModelUpdate();
  }, [watchAll.machineModel, watchAll.machineType]);

  useEffect(() => {
    const initialValue = cameraObj.machineInferenceInterval || selectedModel.defaultValue;
    const displayValue = selectedModel.valueType === "seconds" ? initialValue : initialValue * 100;

    if (cameraObj.id) {
      setValue(
        "machineInferenceInterval",
        cameraObj.machineInferenceInterval || selectedModel.defaultValue,
      );
      setSliderValue(displayValue);
    } else {
      setValue("machineInferenceInterval", selectedModel.defaultValue);
      setSliderValue(displayValue);
    }
  }, [cameraObj, selectedModel, setValue, setSliderValue]);

  const handleNextStep = (data) => {
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevStep = () => {
    setCurrentStep((prev) => prev - 1);
  };

  const submitForm = (cameraFormData) => {
    // Check if the user is adding a new area or selecting an existing one
    const isAddingNewArea = addNewArea && cameraFormData.newArea;

    if (!cameraObj.id) {
      createMachine({
        variables: {
          venueId: cameraObj.venueId,
          machineName: cameraFormData.machineName,
          machineRTSPUrl: cameraFormData.machineRTSPUrl,
          machineCustomId: cameraFormData.machineCustomId,
          machineType: cameraFormData.machineType,
          machineOverlayLabel: cameraFormData.machineOverlayLabel,
          machineInferenceInterval: cameraFormData.machineInferenceInterval,
          machineSelectedObjects: JSON.stringify(cameraFormData.machineSelectedObjects),
          machineIntervalType: selectedModel.valueType,
          modelVersionId: cameraFormData.modelVersionId,
          dockerId: cameraFormData.dockerId,
          // Pass the new area name if adding a new area, otherwise pass the selected areaId
          areaId: isAddingNewArea ? null : cameraFormData.areaId,
          areaName: isAddingNewArea ? cameraFormData.newArea : null, // Only pass newArea if a new area is being added
          machineModel: cameraFormData.machineModel,
        },
        refetchQueries: [{ query: GET_VENUE, variables: { id: cameraObj.venueId } }],
      });
    } else {
      updateMachine({
        variables: {
          id: cameraObj.id,
          machineName: cameraFormData.machineName,
          machineRTSPUrl: cameraFormData.machineRTSPUrl,
          machineCustomId: cameraFormData.machineCustomId,
          machineType: cameraFormData.machineType,
          machineOverlayLabel: cameraFormData.machineOverlayLabel,
          machineInferenceInterval: cameraFormData.machineInferenceInterval,
          machineSelectedObjects: JSON.stringify(cameraFormData.machineSelectedObjects),
          machineIntervalType: selectedModel.valueType,
          dockerId: cameraFormData.dockerId,
          modelVersionId: cameraFormData.modelVersionId,
          // Pass the new area name if adding a new area, otherwise pass the selected areaId
          areaId: isAddingNewArea ? null : cameraFormData.areaId,
          areaName: isAddingNewArea ? cameraFormData.newArea : null, // Only pass newArea if a new area is being added
          machineModel: cameraFormData.machineModel,
        },
      });
    }

    reset();
    setAreaOnChange("");
    setShowModal();
  };

  usePreventEnterAction(submitForm);

  const handleAreaOnChange = (e) => {
    setAreaOnChange(e.target.value);
    setAddNewArea(false); // Clear "Add New Area" input when an area is selected
    clearErrors("areaId");
    clearErrors("newArea");
  };

  const handleObjectChange = (objects) => {
    setValue("machineSelectedObjects", objects);
  };

  const handleSliderChange = (value) => {
    const title = selectedModel.valueType === "seconds" ? value : `${value * 100}%`;
    setSliderValue(title);
  };

  const Track = (props, state) => <Styles.SliderTrack {...props} index={state.index} />;
  const Thumb = (props, state) => {
    return <Styles.SliderThumb {...props}></Styles.SliderThumb>;
  };

  const stepTitles = [
    { title: "Add Your Camera", subtitle: "STEP 1 OF 4" },
    { title: "Select a Use Case", subtitle: "STEP 2 OF 4" },
    { title: "Choose a Model", subtitle: "STEP 3 OF 4" },
    { title: "Last Details", subtitle: "STEP 4 OF 4" },
  ];

  const venueAreas = cameraObj?.venueAreas || [];
  const dockersList = dockersData?.listDocker?.data || [];

  const handleUseCaseSelect = (value) => {
    setSelectedUseCase(value);
    setValue("machineModel", value);
  };

  return (
    <ModalWithChildren
      showModal={showModal}
      setShowModal={setShowModal}
      title={stepTitles[currentStep - 1].title}
      subtitle={stepTitles[currentStep - 1].subtitle}
      handleModalForwardClick={handleModalForwardClick}
    >
      <Styles.Form wide onSubmit={handleSubmit(submitForm)}>
        <Styles.CameraFormContainer>
          {/* Step 1: Camera Details */}
          {currentStep === 1 && (
            <>
              <div style={{ alignSelf: "center", margin: "20px" }}>
                <Icon name={"camera_form_icon"} size="129px" />
              </div>

              <Styles.InputAndErrorContainer>
                <Styles.Input
                  type="text"
                  name="machineName"
                  placeholder="Camera Name"
                  {...register("machineName")}
                />
                <Styles.SubmitError>{errors.machineName?.message}</Styles.SubmitError>
              </Styles.InputAndErrorContainer>

              <Styles.InputAndErrorContainer>
                <Styles.Input
                  type="text"
                  name="machineRTSPUrl"
                  placeholder="RTSP URL"
                  {...register("machineRTSPUrl")}
                />
                <Styles.SubmitError>{errors.machineRTSPUrl?.message}</Styles.SubmitError>
              </Styles.InputAndErrorContainer>

              <Styles.InputAndErrorContainer>
                <Styles.Input
                  type="text"
                  name="machineCustomId"
                  {...register("machineCustomId")}
                  placeholder="Camera ID (optional)"
                />
                <Styles.SubmitError>{errors.machineCustomId?.message}</Styles.SubmitError>
              </Styles.InputAndErrorContainer>
            </>
          )}

          {/* Step 2: Select Use Case */}
          {currentStep === 2 && (
            <>
              <UseCaseGrid
                useCases={padmeModels}
                selectedUseCase={selectedUseCase}
                onSelect={handleUseCaseSelect}
              />
            </>
          )}

          {/* Step 3: Choose a Model */}
          {currentStep === 3 && (
            <>
              {selectedModel?.type === "object_detection" && (
                <Styles.InputAndErrorContainer>
                  <Styles.RadioContainer>
                    <Styles.Label>Objects to count</Styles.Label>

                    <ObjectSelector
                      values={watchAll.machineSelectedObjects || []}
                      onChange={handleObjectChange}
                      suggestions={objectSuggestions}
                    />
                  </Styles.RadioContainer>
                </Styles.InputAndErrorContainer>
              )}

              <Styles.InputAndErrorContainer>
                <Styles.RadioContainer>
                  <Styles.Label>ML Model</Styles.Label>
                  {mlModels.map((model) => {
                    return model.ModelVersions.map((modelVersion) => (
                      <Styles.RadioInputLabelContainer key={modelVersion.id}>
                        <div>
                          <Styles.RadioInput
                            type="radio"
                            id={modelVersion.id}
                            value={modelVersion.id}
                            {...register("modelVersionId")}
                          />
                        </div>

                        <Styles.RadioLabel htmlFor={modelVersion.id}>
                          {model.modelName}, Ver.{modelVersion.modelVersionNo}
                        </Styles.RadioLabel>
                      </Styles.RadioInputLabelContainer>
                    ));
                  })}
                </Styles.RadioContainer>
                <Styles.SubmitError>{errors.modelVersionId?.message}</Styles.SubmitError>
              </Styles.InputAndErrorContainer>

              <Styles.InputAndErrorContainer>
                <Styles.RadioContainer>
                  <Styles.Label>Machine Type</Styles.Label>
                  <Styles.RadioInputLabelContainer>
                    <div>
                      <Styles.RadioInput
                        type="radio"
                        id="openvino"
                        value="openvino"
                        {...register("machineType")}
                      />
                    </div>
                    <Styles.RadioLabel htmlFor="openvino">CPU (Intel OpenVINO)</Styles.RadioLabel>
                  </Styles.RadioInputLabelContainer>

                  <Styles.RadioInputLabelContainer>
                    <div>
                      <Styles.RadioInput
                        type="radio"
                        id="cuda"
                        value="cuda"
                        {...register("machineType")}
                      />
                    </div>
                    <Styles.RadioLabel htmlFor="cuda">GPU (NVIDIA CUDA)</Styles.RadioLabel>
                  </Styles.RadioInputLabelContainer>

                  <Styles.RadioInputLabelContainer>
                    <div>
                      <Styles.RadioInput
                        type="radio"
                        id="cpu"
                        value="cpu"
                        {...register("machineType")}
                      />
                    </div>
                    <Styles.RadioLabel htmlFor="cpu">CPU (Most Compatible)</Styles.RadioLabel>
                  </Styles.RadioInputLabelContainer>
                </Styles.RadioContainer>
              </Styles.InputAndErrorContainer>

              <Styles.InputAndErrorContainer>
                <Styles.RadioContainer>
                  <Styles.Label>{selectedModel.title}</Styles.Label>

                  <Styles.SliderContainer>
                    <Styles.Slider
                      defaultValue={selectedModel.defaultValue}
                      value={watchAll.machineInferenceInterval}
                      step={selectedModel.step}
                      max={selectedModel.max}
                      min={selectedModel.min}
                      onChange={(value) => {
                        setValue("machineInferenceInterval", value);
                        handleSliderChange(value);
                      }}
                      renderTrack={Track}
                      renderThumb={Thumb}
                    />
                    <Styles.SliderValue>{sliderValue}</Styles.SliderValue>
                  </Styles.SliderContainer>
                </Styles.RadioContainer>
              </Styles.InputAndErrorContainer>
            </>
          )}

          {/* Step 4: Last Details */}
          {currentStep === 4 && (
            <>
              <Styles.InputAndErrorContainer>
                <Styles.RadioContainer>
                  <Styles.Label>Docker ID</Styles.Label>

                  {dockersList.map((docker) => (
                    <Styles.RadioInputLabelContainer key={docker.id}>
                      <Styles.RadioInput
                        type="radio"
                        id={docker.id}
                        value={docker.id}
                        {...register("dockerId")}
                      />
                      <Styles.RadioLabel htmlFor={docker.id}>{docker.dockerName}</Styles.RadioLabel>
                    </Styles.RadioInputLabelContainer>
                  ))}
                </Styles.RadioContainer>

                <Styles.SubmitError>{errors.dockerId?.message}</Styles.SubmitError>
              </Styles.InputAndErrorContainer>

              <Styles.InputAndErrorContainer>
                <Styles.RadioContainer>
                  <Styles.Label>Area</Styles.Label>

                  {venueAreas.map((area) => (
                    <Styles.RadioInputLabelContainer key={area.id}>
                      <div>
                        <Styles.RadioInput
                          type="radio"
                          id={`area-${area.id}`}
                          value={area.id}
                          {...register("areaId")}
                          onChange={handleAreaOnChange}
                        />
                      </div>
                      <Styles.RadioLabel htmlFor={`area-${area.id}`}>
                        {area.areaName}
                      </Styles.RadioLabel>
                    </Styles.RadioInputLabelContainer>
                  ))}

                  <Styles.RadioInputLabelContainer>
                    <Icon
                      name={"add_area"}
                      className="action-icon"
                      onClick={() => {
                        setAddNewArea(true);
                        setValue("areaId", "");
                      }}
                    />
                    <Styles.RadioLabel>Add area</Styles.RadioLabel>
                  </Styles.RadioInputLabelContainer>
                </Styles.RadioContainer>
                <Styles.SubmitError>{errors.areaId?.message}</Styles.SubmitError>
              </Styles.InputAndErrorContainer>

              {addNewArea && (
                <Styles.InputAndErrorContainer>
                  <Styles.Input
                    type="text"
                    name="newArea"
                    placeholder="New area name"
                    {...register("newArea")}
                  />
                  <Styles.SubmitError>{errors.newArea?.message}</Styles.SubmitError>
                </Styles.InputAndErrorContainer>
              )}

              <Styles.InputAndErrorContainer>
                <Styles.Input
                  type="text"
                  name="machineOverlayLabel"
                  {...register("machineOverlayLabel")}
                  placeholder="Overlay label"
                />
                <Styles.SubmitError>{errors.machineOverlayLabel?.message}</Styles.SubmitError>
              </Styles.InputAndErrorContainer>
            </>
          )}
        </Styles.CameraFormContainer>

        <Styles.ButtonsContainer>
          {currentStep === 1 ? (
            <PrimaryBtn
              type="button"
              id="continue"
              label="Continue"
              width="420px"
              height="40px"
              handleClick={handleNextStep}
            />
          ) : (
            <Styles.SmallButtonsContainer>
              <SecondaryBtn
                label="Previous"
                handleClick={handlePrevStep}
                width="200px"
                height="40px"
              />
              <PrimaryBtn
                type="button"
                id="submit"
                label={currentStep === 4 ? (isUpdate ? "Update" : "Add") : "Next"}
                width="200px"
                height="40px"
                handleClick={currentStep === 4 ? handleSubmit(submitForm) : handleNextStep}
              />
            </Styles.SmallButtonsContainer>
          )}
        </Styles.ButtonsContainer>
      </Styles.Form>
    </ModalWithChildren>
  );
};

export default AddUpdateCamera;
