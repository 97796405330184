export const timeAgo = (timestamp) => {
  const timeNow = Date.now();
  const timeDifference = timeNow - timestamp;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) {
    return `${seconds} seconds ago`;
  }

  if (minutes < 60) {
    return `${minutes} minutes ago`;
  }

  if (hours < 24) {
    return `${hours} hours ago`;
  }

  return `${days} days ago`;
};

export const timeStampToDateString = (timestamp) => {
  if (!timestamp || timestamp === "0" || isNaN(timestamp)) return "";

  const timeStampInt = parseInt(timestamp, 10);

  // if timestamp is in seconds, convert it to milliseconds
  if (timeStampInt < 10000000000) {
    return new Date(timeStampInt * 1000).toLocaleDateString();
  }

  return new Date(timeStampInt).toLocaleDateString();
};

export const defineCurrentModelObj = (urlModelTitle) => {
  //compare url param and return a string
  if (urlModelTitle === "CrowdCounting") {
    return {
      title: "Crowd Counting",
      description: "",
    };
  }
  if (urlModelTitle === "ObjectDetection") {
    return {
      title: "Object Detection",
      description: "Detect custom-trained objects present in an image or video of interest.",
    };
  }
  if (urlModelTitle === "EmotionDetection") {
    return {
      title: "Emotion Detection",
      description: "Gather Insights about people’s sentiments based on their facial expressions.",
    };
  }
  if (urlModelTitle === "FaceComparison") {
    return {
      title: "Face Comparison",
      description:
        "Compare, match and search faces using a unique face embedding. You can identify the same person present in different images, determine the similarity between two faces or search for a face in an image or video.",
    };
  }
  if (urlModelTitle === "Antispoofing") {
    return {
      title: "Antispoofing",
      description:
        "Detect if the analyzed face is original or spoofed. It can be used in highly secure areas where access is granted based on facial biometrics.",
    };
  }
  if (urlModelTitle === "CarCounting") {
    return {
      title: "Car Counting",
      description:
        " Detect and count the number of cars entering and leaving the area of interest captured through the edge device.",
      defaultImageUrl1: "https://www.aranca.com/assets/uploads/blogs/parkedcarsban.jpg",
      defaultImage1Alt: "Parked cars model image",
      defaultImageUrl2:
        "https://www.ledgerinsights.com/wp-content/uploads/2020/05/parking-lot-cars.jpg",
      defaultImage2Alt: "Parking lot model image",
    };
  }
  if (urlModelTitle === "LogoDetection") {
    return {
      title: "Logo Detection",
      description:
        "Identification and classification of logos. An automated solution to detect logos on vehicles and provide the company details.",
    };
  }
};

export const jsonParse = (str) => {
  if (!str || str === "null") return [];

  try {
    const parseResult = JSON.parse(str);
    return parseResult;
  } catch (e) {
    return [];
  }
};

export const checkUserPermissionIsAdmin = (loggedUserAttributes, companyId, venueId) => {
  // console.log(loggedUserAttributes, `${companyId}, ${venueId}`);
  if (!loggedUserAttributes) return false;

  const { Companies: userCompanies, Venues: userVenues, isSuperAdmin } = loggedUserAttributes;

  if (isSuperAdmin) return true;

  // check for company ID level admin access first
  const userCompanyPermission = userCompanies.filter(
    (uCompany) => uCompany.permissionType === "ADMIN" && uCompany.companyId === companyId,
  );

  if (userCompanyPermission.length > 0) return true;

  // no venue id passed
  if (!venueId) return false;

  const userVenuePermission = userVenues.filter(
    (uCVenue) => uCVenue.permissionType === "ADMIN" && uCVenue.venueId === venueId,
  );

  // if admin given at venue level
  if (userVenuePermission.length > 0) return true;
};

export const getCheckedCameras = (areas) => {
  const checkedCameras = [];
  const areasWithCheckedCameras = [];
  areas.forEach((area) => {
    // if areaId is not in areasWithCheckedCameras, then add it
    let areaIndex = areasWithCheckedCameras.findIndex((areasAdded) => areasAdded.id === area.id);

    if (areaIndex === -1) {
      areasWithCheckedCameras.push({
        id: area.id,
        cameraCount: area.Machines.length,
      });

      areaIndex = areasWithCheckedCameras.findIndex((areasAdded) => areasAdded.id === area.id);
    }

    area.Machines.forEach((camera) => {
      if (camera.isChecked) {
        checkedCameras.push(camera);

        // find and update cameraCount in areasWithCheckedCameras
        areasWithCheckedCameras[areaIndex].cameraCount -= 1;
      }
    });
  });

  return {
    checkedCameras,
    areasWithCheckedCameras: areasWithCheckedCameras.filter((area) => area.cameraCount === 0),
  };
};
