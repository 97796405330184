import { arePointListEqual, arePointsEqual, Point } from "./helpers";

export const pathsActions = {
  ADD: "ADD",
  DELETE: "DELETE",
  MODIFY: "MODIFY",
  MOVE: "MOVE",
  RESET: "RESET",
  CHANGE: "CHANGE",
  FULL_RESET: "FULL_RESET",
};

export function pathsReducer(state, currentPathIndex, action) {
  const currentPath = state[currentPathIndex];
  const length = currentPath.points.length;
  const newState = [...state];

  switch (action.type) {
    case pathsActions.ADD: {
      // if already closed, do nothing
      if (currentPath.closed) return [state, false];

      // if points are duplicated, do nothing
      if (
        (length > 0 && arePointsEqual(currentPath.points[length - 1], action.payload)) ||
        (length > 1 && arePointsEqual(currentPath.points[length - 2], action.payload))
      ) {
        return [state, false];
      }

      newState[currentPathIndex].points.push(action.payload);

      const needToBeClosed = length > 2 && arePointsEqual(currentPath.points[0], action.payload);

      // closed
      newState[currentPathIndex].closed = needToBeClosed;

      // console.log("newState", newState);
      return [newState, true];
    }
    case pathsActions.MODIFY: {
      // get xy at index of points
      const { x: sx, y: sy } = currentPath.points[action.payload.index];

      const newPoints = currentPath.points.map(({ x, y }) => {
        if (x === sx && y === sy) {
          return {
            x: action.payload.x,
            y: action.payload.y,
          };
        }
        return { x, y };
      });
      newState[currentPathIndex].points = newPoints;
      return [newState, !!(action.payload.x || action.payload.y)];
    }
    case pathsActions.MOVE: {
      newState[currentPathIndex].points = currentPath.points.map(({ x, y }) => ({
        x: x + action.payload.x,
        y: y + action.payload.y,
      }));

      return [newState, !!(action.payload.x || action.payload.y)];
    }
    case pathsActions.DELETE: {
      newState[currentPathIndex].points = [
        ...currentPath.points.filter((_, idx) => action.payload !== idx),
      ];
      newState[currentPathIndex].closed = length > 4 && currentPath.closed;

      return [newState, true];
    }
    case pathsActions.CHANGE: {
      // override all previous points and paths
      const wasModified = true; // !arePointListEqual(action.payload, currentPath.points);

      // newState[currentPathIndex].points = action.payload;
      // newState[currentPathIndex].closed = wasModified ? action.payload.length > 2 : currentPath.closed

      return [action.payload, wasModified];
    }
    case pathsActions.RESET: {
      newState[currentPathIndex] = { points: [] };
      return [newState, true];
    }
    default:
      return [state, false];
  }
}
