import React from "react";
// Styles
import Styles from "./styles";
// Assets
import avatarImage from "../../assets/images/avatar.png";

const ProfileImage = (props) => {
  const { image, size } = props;
  return <Styles.Container image={image || avatarImage} size={size} />;
};

export default ProfileImage;
