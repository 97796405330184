import styled from "styled-components/macro";
import ModalImage from "react-modal-image";
import { ReactComponent as DeleteIcon } from "../../assets/icons/garbage_gray_icon.svg";

const styles = {
  ImageContainerOuter: styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #d0d5dd;
    padding: 5px;
  `,
  ImageContainer: styled.div`
    display: flex;
    align-items: center;
    width: 120px;
    height: 120px;
    margin: 5px;
    position: relative;
  `,
  Image: styled(ModalImage)`
    width: 120px;
    height: 120px;
    resize-mode: contain;
  `,
  Label: styled.div`
    font-family: "Poppins500";
    font-size: 14px;
    color: #37474f;
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `,
  DeleteIconContainer: styled.div`
    cursor: pointer;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 2px;
    right: 2px;
    border: 1px solid black;
    background-color: white;
    width: 25px;
    height: 25px;
    border-radius: 50%;
  `,
  DeleteIcon: styled(DeleteIcon)`
    display: flex;
    width: 18px;
    height: 18px;
  `,
};

export default styles;
