import React from "react";
//Styles
import Styles from "./styles";
//Components
import ForgotPasswordForm from "../../components/ForgotPasswordForm";

const ForgotPassword = () => {
  return (
    <Styles.PageContainer>
      <Styles.AlignmentContainer>
        <Styles.ForgotPasswordWrapper>
          <ForgotPasswordForm />
        </Styles.ForgotPasswordWrapper>
      </Styles.AlignmentContainer>
    </Styles.PageContainer>
  );
};

export default ForgotPassword;
