import React from "react";
import { Button } from "./styles";

const DefaultDashBtn = ({
  label,
  icon,
  handleClick,
  whitebg,
  orangebg,
  orangeborder,
  orangecolor,
  smallsize,
  mediumsize,
  sidebarSize,
}) => {
  return (
    <Button
      onClick={() => handleClick()}
      whitebg={whitebg ? true : false}
      orangebg={orangebg ? true : false}
      orangeborder={orangeborder ? true : false}
      orangecolor={orangecolor ? true : false}
      smallsize={smallsize ? true : false}
      mediumsize={mediumsize ? true : false}
      sidebarSize={sidebarSize ? true : false}
    >
      {label}
      {icon}
    </Button>
  );
};

export default DefaultDashBtn;
