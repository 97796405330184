import styled from "styled-components/macro";
import { Link } from "react-router-dom";

const styles = {
  Card: styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 2rem;
    cursor: grab;
    user-select: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    transition: all 0.1s ease-in-out;
    :hover {
      /* transform: scale(1.02); */
      box-shadow: rgba(50, 50, 93, 0.5) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
    height: 15rem;
  `,
  TitleAndDescription: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `,
  CardTitle: styled.h3`
    font-family: CircularStd700;
    font-size: 24px;
    color: #37474f;
    margin: 0;
  `,
  CardDescription: styled.p`
    margin: 0;
    font-family: "Roboto400";
    font-size: 14px;
    color: #37474f;
  `,
  CardLink: styled(Link)`
    cursor: pointer;
    align-items: center;
    text-decoration: none;
    font-family: CircularStdNormal;
    font-size: 18px;
    color: #37474f;
    :hover {
      color: #5ca6b3;
      text-decoration: underline;
    }
  `,
  CardLine: styled.hr`
    border: none;
    margin: 0;
    width: 100%;
    background-color: #37474f;
    height: 2px;
    margin: 0;
  `,
};

export default styles;
