import React from "react";
import { Helmet } from "react-helmet-async";
//Components
import SignInComponent from "../../components/Auth/SignIn";
import FormPageLayout from "../../components/Shared/FormPagesLayout";

const SignInPage = () => {
  return (
    <>
      <Helmet>
        <title>Padmé - Sign In!</title>
        <meta
          name="description"
          content="Sign In to access your dashboard and start using Padmé."
        />
        <link rel="canonical" href="/signin" />
      </Helmet>
      <FormPageLayout formComponent={<SignInComponent />} isSignIn={true}></FormPageLayout>
    </>
  );
};
export default SignInPage;
