import { gql } from "@apollo/client";

export const CORE_OBJECT_DETECTION_ITEM_FIELDS = gql`
  fragment CoreObjectDetectionItemFields on objectDetectionItem {
    id
    objectDetectionIndex
    objectDetectionKey
    objectDetectionLabel
    objectDetectionStatus
    createdAt
    updatedAt
  }
`;

export const CORE_API_FIELDS = gql`
  fragment CoreApiFields on api {
    id
    userId
    companyId
    apiStatus
    apiName
    apiKey
    lastUsed
    createdAt
    updatedAt
  }
`;

export const CORE_USER_FIELDS = gql`
  fragment CoreUserFields on user {
    id
    userFirstName
    userLastName
    userEmail
    userPhone
    userStatus
    createdAt
    updatedAt
    isSuperAdmin
  }
`;

export const CORE_MACHINE_THUMBNAIL_FIELDS = gql`
  fragment CoreMachineThumbnailFields on machineThumbnail {
    id
    machineThumbnailURL
    createdAt
    updatedAt
  }
`;

export const CORE_MACHINE_FIELDS = gql`
  ${CORE_MACHINE_THUMBNAIL_FIELDS}
  fragment CoreMachineFields on machine {
    id
    machineCustomId
    machineName
    machineRTSPUrl
    machineLastPingTime
    machineMaskingScale
    machineWidth
    machineHeight
    machineInferenceStatus
    machineSpec
    machineNotes
    machineStatus
    machineOutput
    machineVectors
    machineWssToken
    machineModel
    modelVersionId
    machineIntervalType
    machineInferenceInterval
    machineSelectedObjects
    dockerId
    dockerURL
    dockerNginxIP
    machineType
    machineOverlayLabel
    areaId
    venueId
    companyId
    createdAt
    updatedAt
    thumbnail {
      ...CoreMachineThumbnailFields
    }
  }
`;

export const CORE_MACHINE_AREA_FIELDS = gql`
  ${CORE_MACHINE_FIELDS}
  fragment CoreMachineAreaFields on area {
    id
    areaName
    createdAt
    updatedAt
    areaNotes
    venueId
    Machines {
      ...CoreMachineFields
    }
  }
`;

export const CORE_VENUE_FIELDS = gql`
  fragment CoreVenueFields on venue {
    id
    venueName
    venueStatus
    venueStreet
    venueAddress
    venueState
    venueCity
    venueZip
    venueCountry
    venueContact
    venueVoid
    venuePhone
    venueEmail
    companyId
    createdAt
    updatedAt
  }
`;

export const CORE_COMPANY_FIELDS = gql`
  ${CORE_VENUE_FIELDS}
  fragment CoreCompanyFields on company {
    id
    companyName
    companyStreet
    companyAddress
    companyState
    companyCity
    companyZip
    companyCountry
    companyContact
    companyPhone
    companyEmail
    createdAt
    updatedAt
    machineCount
    inferenceCount
    venueCount
    Venues {
      ...CoreVenueFields
    }
  }
`;

export const CORE_FILE_ASSET_FIELDS = gql`
  fragment CoreFileAssetFields on fileAsset {
    id
    fileAssetUrl
    fileSignedUrl
    fileAssetType
    fileAssetVoid
    fileAssetStatus
    modelId
    modelVersionId
    modelTrainingRequestId
    annotationRequestId
    collectionRequestId
    createdAt
    updatedAt
  }
`;

export const CORE_MODEL_VERSION_FIELDS = gql`
  ${CORE_FILE_ASSET_FIELDS}
  fragment CoreModelVersionFields on modelVersion {
    id
    modelId
    modelVersionNo
    modelVersionNotes
    modelVersionStatus
    createdAt
    updatedAt
    FileAssets {
      ...CoreFileAssetFields
    }
  }
`;

export const CORE_MODEL_FIELDS = gql`
  ${CORE_MODEL_VERSION_FIELDS}
  fragment CoreModelFields on model {
    id
    modelName
    modelDescription
    modelType
    modelStatus
    modelDetectionObjects
    modelBaseModel
    companyId
    createdAt
    updatedAt
    ModelVersions {
      ...CoreModelVersionFields
    }
  }
`;

export const CORE_DOCKER_FIELDS = gql`
  fragment CoreDockerFields on docker {
    id
    dockerName
    dockerNginxIP
    dockerCustomId
    dockerLastPingTime
    dockerURL
    dockerStatus
    dockerInferencePerTask
    dockerTaskCount
    dockerAutoRestart
    dockerAutoScale
    brokerId
    venueId
    companyId
    userId
    createdAt
    updatedAt
    venueName
    dockerVoid
  }
`;

export const CORE_BROKER_FIELDS = gql`
  fragment CoreBrokerFields on broker {
    id
    brokerURL
    brokerName
    brokerURLType
    brokerType
    brokerPort
    brokerHost
    brokerChannel
    brokerUser
    brokerPassword
    brokerSecret
    brokerStatus
    companyId
    userId
    createdAt
    updatedAt
    brokerVoid
  }
`;

export const CORE_CONFIG_FIELDS = gql`
  fragment CoreConfigFields on config {
    id
    configName
    configValue
    configValue2
    configType
    configOrder
    configStatus
    createdAt
    updatedAt
  }
`;

export const CORE_ANNOTATION_REQUEST_FIELDS = gql`
  fragment CoreAnnotationRequestFields on annotationRequest {
    id
    collectionRequestId
    companyId
    userId
    arProvider
    arStatus
    arManualReview
    arNotes
    arCollectionStartedDate
    arCollectionCompleteDate
    createdAt
    updatedAt
  }
`;

export const CORE_COLLECTION_REQUEST_FIELDS = gql`
  ${CORE_ANNOTATION_REQUEST_FIELDS}
  fragment CoreCollectionRequestFields on collectionRequest {
    id
    machineId
    companyId
    userId
    crModel
    crCollectionStatus
    crStartTimestamp
    crStartNow
    crEndTimestamp
    crInterval
    crMaxFrames
    crDetectionItems
    crManualReview
    crNotes
    crCollectionCompleteDate
    createdAt
    updatedAt
    AnnotationRequest {
      ...CoreAnnotationRequestFields
    }
  }
`;

export const CORE_TRAINING_REQUEST_FIELDS = gql`
  fragment CoreTrainingRequestFields on modelTrainingRequest {
    id
    companyId
    userId
    modelId
    modelVersionId
    mtDetectionItems
    mtModel
    mtName
    mtNewModel
    mtStartedDate
    mtCompleteDate
    mtManualReview
    mtNotes
    mtStatus
    createdAt
    updatedAt
    ModelTrainingAnnotations {
      id
      annotationRequestId
    }
  }
`;

export const CORE_RENTEDGPU_FIELDS = gql`
  fragment CoreRentedGPUFields on rentedGPU {
    id
    companyId
    userId
    modelTrainingRequestId
    rentedGPUsProviderDeviceId
    rentedGPUsMachineData
    rentedGPUsProvider
    rentedGPUsModel
    rentedGPUsSSH
    rentedGPUsStartedDate
    rentedGPUsEndDate
    rentedGPUsCost
    rentedGPUsDuration
    rentedGPUsNotes
    rentedGPUsStatus
    createdAt
    updatedAt
  }
`;
