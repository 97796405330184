import React, { useRef, useState, useEffect } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import Styles from "../styles";

import SpinnerComponent from "../../../components/Loader";

const VideoOutput = ({ machineVectors, wssUrl, setOutputBase64ToDownload, displayOutput }) => {
  const wsclient = useRef(null);
  const [outputIsPlaying, setOutputIsPlaying] = useState(false);
  const [outputError, setOutputError] = useState(false);
  const [wssError, setWssError] = useState(false);
  const [maskingThresholds, setMaskingThresholds] = useState({
    maskThreshold1: null,
    maskThreshold2: null,
  });
  const [outputData, setOutputData] = useState({ outputBase64: null, output: "0" });

  const loadingTimeout = useRef(null);

  // wss://wss.padme.ai:9495/devices?sender=docker
  useEffect(() => {
    console.log("VideoOutout WebSocket Client Connecting");
    const outPutUrl = wssUrl;
    wsclient.current = new W3CWebSocket(outPutUrl);

    wsclient.current.onerror = function (error) {
      console.log("VideoOutout WebSocket Client Connection Error");
      if (error.type === "error") {
        setWssError(true);
      }
    };

    wsclient.current.onopen = () => {
      console.log("VideoOutout WebSocket Client Connected");

      loadingTimeout.current = setTimeout(() => {
        // could not load video timeout
        handleNoOutput();
      }, 30000);
    };

    wsclient.current.onmessage = (message) => {
      const data = JSON.parse(message.data);
      // console.log("VideoOutout WebSocket Client Received: ", data);
      setOutputData({
        outputBase64: data.message,
        output: isNaN(data.output) ? null : parseInt(data.output),
      });
      setOutputBase64ToDownload(data.message);
    };

    // check for threshold
    if (machineVectors) {
      const maskings = JSON.parse(machineVectors);

      // support 1st masking only
      const masking = maskings[0];

      if (masking) {
        const { maskThreshold1, maskThreshold2 } = masking;
        setMaskingThresholds({
          maskThreshold1: isNaN(maskThreshold1) ? null : parseInt(maskThreshold1),
          maskThreshold2: isNaN(maskThreshold2) ? null : parseInt(maskThreshold2),
        });
      }
    }

    return () => {
      // unmounting output
      console.log("VideoOutout WebSocket Client Closed");
      wsclient?.current?.close();
    };
  }, []);

  useEffect(() => {
    if (outputData.outputBase64 && !outputIsPlaying) {
      setOutputIsPlaying(true);

      clearTimeout(loadingTimeout.current);
      loadingTimeout.current = null;
    }
  }, [outputData, outputIsPlaying]);

  const handleNoOutput = () => {
    setOutputError(true);
  };

  const renderWarnings = () => {
    const { maskThreshold1, maskThreshold2 } = maskingThresholds;
    const { output } = outputData;

    if (output == null || maskThreshold1 == null) return null;

    if (maskThreshold2 != null && output >= maskThreshold2) {
      return (
        <Styles.TextCritical>
          Warning, output greater than high threshold of {maskThreshold2}
        </Styles.TextCritical>
      );
    }

    if (output >= maskThreshold1) {
      return (
        <Styles.TextWarning>
          Warning, output greater than low threshold {maskThreshold1}
        </Styles.TextWarning>
      );
    }

    return null;
  };

  if (wssError) {
    return (
      <Styles.SpinnerContainer>
        <Styles.ErrorMessage>
          Unable to connect to Output server, please check your connection.
        </Styles.ErrorMessage>
      </Styles.SpinnerContainer>
    );
  }

  if (outputError) {
    return (
      <Styles.SpinnerContainer>
        <Styles.ErrorMessage>
          Connected to output server but no data are being received. Please check if inference is
          running.
        </Styles.ErrorMessage>
      </Styles.SpinnerContainer>
    );
  }

  return (
    <>
      {!outputIsPlaying ? (
        <Styles.SpinnerContainer>
          <SpinnerComponent width={50} height={50} />
        </Styles.SpinnerContainer>
      ) : (
        <Styles.Column>
          <Styles.LabelContainer>
            Output {displayOutput ? <>: {outputData.output}</> : null} {renderWarnings()}
          </Styles.LabelContainer>
          <img
            src={`data:image/jpg;base64,${outputData.outputBase64}`}
            alt="output"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Styles.Column>
      )}
    </>
  );
};

export default VideoOutput;
