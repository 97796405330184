import React, { useState } from "react";
//Styles
import Styles from "./styles";
//Tools
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";
//Components
import { FormGroup } from "../Shared/FormGroup";
import usePreventEnterAction from "../../hooks/PreventCloseOnEnterHook";

const signupSchema = yup.object().shape({
  firstName: yup.string().required("First Name is a required field."),
  lastName: yup.string().required("Last Name is a required field."),
  email: yup.string().email().required("Email is a required field."),
  userPhone: yup.string(), //Validate phone number with regex ?
  companyName: yup.string().required("Company Name is a required field."),
  message: yup.string(),
});

const RequestAccount = ({ requestAccountMutation }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signupSchema),
  });
  const [requestAccountErrors /* setRequestAccountErrors */] = useState("");

  const submitForm = (requestAccountFormData) => {
    requestAccountMutation({
      variables: {
        requestFirstName: requestAccountFormData.firstName,
        requestLastName: requestAccountFormData.lastName,
        requestEmail: requestAccountFormData.email,
        requestCompanyName: requestAccountFormData.companyName,
        requestPhoneNumber: requestAccountFormData.userPhone,
        requestNotes: requestAccountFormData.message,
      },
    });
    reset();
  };
  usePreventEnterAction(submitForm);

  return (
    <Styles.AuthContainer>
      <Styles.FormContainer>
        <Styles.Title>Request an account!</Styles.Title>
        <Styles.Form onSubmit={handleSubmit(submitForm)}>
          <FormGroup labelText={"First name"} errorMessage={errors.firstName?.message}>
            <Styles.Input type="text" name="firstName" {...register("firstName")} />
          </FormGroup>
          <FormGroup labelText={"Last name"} errorMessage={errors.lastName?.message}>
            <Styles.Input type="text" name="lastName" {...register("lastName")} />
          </FormGroup>
          <FormGroup labelText={"Email"} errorMessage={errors.email?.message}>
            <Styles.Input type="text" name="email" {...register("email")} />
          </FormGroup>
          <FormGroup labelText={"Company name"} errorMessage={errors.companyName?.message}>
            <Styles.Input type="text" name="companyName" {...register("companyName")} />
          </FormGroup>
          <FormGroup labelText={"Phone number"} errorMessage={errors.userPhone?.message}>
            <Styles.Input type="text" name="userPhone" {...register("userPhone")} />
          </FormGroup>
          <FormGroup labelText={"How can we help?"} errorMessage={errors.message?.message}>
            <Styles.TextArea type="text" name="message" {...register("message")} />
          </FormGroup>
          {requestAccountErrors ? (
            <Styles.SubmitServerError>{requestAccountErrors}</Styles.SubmitServerError>
          ) : null}
          <Styles.SubmitInput type="submit" value="Request Account" />
        </Styles.Form>
        <Styles.Paragraph>
          Have an account? <Link to="/signin">Sign in!</Link>
        </Styles.Paragraph>
      </Styles.FormContainer>
    </Styles.AuthContainer>
  );
};

export default RequestAccount;
