import React from "react";

//Styles
import Styles from "./styles";

const MaskingRow = ({
  padmeModelColors,
  padmeModelTypes,
  showWaitTime,
  data,
  deleteMask,
  activeMaskId,
  selectMask,
  updateMask,
}) => {
  return (
    <>
      <Styles.TableRowEntriesContainer active={data.maskId === activeMaskId}>
        <Styles.TableRowEntries>
          <Styles.Input
            value={data.maskName}
            onChange={(e) => {
              updateMask({
                ...data,
                maskName: e.target.value,
              });
            }}
          />
        </Styles.TableRowEntries>
        <Styles.TableRowEntries>
          <Styles.Select
            name="maskType"
            id="maskType"
            onChange={(e) => {
              // setMaskType(e.target.value);
              updateMask({
                ...data,
                maskType: e.target.value,
              });
            }}
            value={data.maskType}
          >
            {padmeModelTypes.map((defaultType) => (
              <option value={defaultType.value} key={defaultType.value}>
                {defaultType.label}
              </option>
            ))}
          </Styles.Select>
        </Styles.TableRowEntries>
        <Styles.TableRowEntries>
          <Styles.Select
            name="maskColor"
            id="maskColor"
            value={data.maskColor}
            onChange={(e) => {
              // find the color object
              const colorObj = padmeModelColors.find((color) => color.label === e.target.value);
              updateMask({
                ...data,
                maskColor: colorObj.label,
                maskStrokeColor: colorObj.stroke,
                maskFillColor: colorObj.fill,
              });
            }}
          >
            {padmeModelColors.map((defaultColor) => (
              <option value={defaultColor.label} key={defaultColor.label}>
                {defaultColor.label}
              </option>
            ))}
          </Styles.Select>
        </Styles.TableRowEntries>
        <Styles.TableRowEntries>
          {showWaitTime && (
            <Styles.Input
              value={data.maskWaitTime}
              onChange={(e) => {
                updateMask({
                  ...data,
                  maskWaitTime: e.target.value,
                });
              }}
            />
          )}
        </Styles.TableRowEntries>
        <Styles.TableRowEntries>
          <Styles.Input
            slim
            value={data.maskThreshold1}
            onChange={(e) => {
              updateMask({
                ...data,
                maskThreshold1: e.target.value,
              });
            }}
          />
          <Styles.Input
            slim
            value={data.maskThreshold2}
            onChange={(e) => {
              updateMask({
                ...data,
                maskThreshold2: e.target.value,
              });
            }}
          />
        </Styles.TableRowEntries>
        <Styles.TableRowEntries>
          <Styles.Input
            value={data.maskNote}
            onChange={(e) => {
              updateMask({
                ...data,
                maskNote: e.target.value,
              });
            }}
          />
        </Styles.TableRowEntries>
        <Styles.TableRowEntries>
          <Styles.RowActionsContainer>
            <Styles.DeleteIcon onClick={() => deleteMask(data)} />
            <Styles.EditIcon onClick={() => selectMask(data)} />
          </Styles.RowActionsContainer>
        </Styles.TableRowEntries>
      </Styles.TableRowEntriesContainer>
    </>
  );
};

export default MaskingRow;
