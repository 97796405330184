import React, { useRef, useEffect, useCallback, useContext, useState } from "react";

import { DashboardContext } from "../../../context/DashboardContext";
import SpinnerComponent from "../../../components/Loader";
import Styles from "../styles";

const MASKING_WIDTH = 960;
const MASKING_HEIGHT = 540;

const PREVIEW = "Preview";

const LivePreview = ({
  handleNoRtsp,
  selectedCamera,
  updateMachineResolution,
  openFrom = PREVIEW,
}) => {
  const machineMaskingScale = selectedCamera.machineMaskingScale
    ? parseFloat(selectedCamera.machineMaskingScale)
    : 1;

  const [maskingHeight, setMaskingHeight] = useState(
    selectedCamera.machineHeight
      ? parseInt(selectedCamera.machineHeight) * machineMaskingScale
      : MASKING_HEIGHT,
  );

  const [maskingWidth, setMaskingWidth] = useState(
    selectedCamera.machineWidth
      ? parseInt(selectedCamera.machineWidth) * machineMaskingScale
      : MASKING_HEIGHT,
  );

  const loadingTimeout = useRef(null);
  const canvasRef = useRef(null);
  const playerRef = useRef(null);
  const [videoLoading, setVideoLoading] = useState(true);
  const [videoFeedError, setVideoFeedError] = useState(false);
  const [videoStarted, setVideoStarted] = useState(false);

  const resolutionCheckTimer = useRef(null);
  const { padmeModelColors } = useContext(DashboardContext);
  const machineVectors = JSON.parse(selectedCamera.machineVectors);
  const maskPaths = machineVectors.length > 0 && machineVectors !== null ? machineVectors : null;

  const SvgPolygon = ({ mask, index }) => {
    const { points, maskStrokeColor, maskFillColor } = mask;
    const pathData = points.map(({ x, y }) => `${x},${y}`).join(" ");
    const vb = `0,0,${maskingWidth},${maskingHeight}`;

    return (
      <svg
        id="maskingSVG"
        style={{ position: "absolute" }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox={vb}
      >
        <polygon
          points={pathData}
          fill={maskFillColor || padmeModelColors[index].fill}
          stroke={maskStrokeColor || padmeModelColors[index].stroke}
          strokeWidth="2"
        />
      </svg>
    );
  };

  const updateMachineDims = useCallback(
    (deviceWidth, deviceHeight) => {
      // calculate machine yOffSet
      const maskingScale = String(MASKING_WIDTH / parseInt(deviceWidth));

      // only update if needed
      const { machineWidth, machineHeight, machineMaskingScale } = selectedCamera;

      if (
        (machineWidth !== deviceWidth ||
          machineHeight !== deviceHeight ||
          machineMaskingScale !== maskingScale) &&
        updateMachineResolution
      ) {
        updateMachineResolution({
          variables: {
            id: selectedCamera.id,
            machineWidth: deviceWidth,
            machineHeight: deviceHeight,
            machineMaskingScale: maskingScale,
          },
        });
      }
    },
    [selectedCamera, updateMachineResolution],
  );

  const handleOnPlay = useCallback(() => {
    console.log("LivePreview handleOnPlay");
    clearTimeout(loadingTimeout.current);
    loadingTimeout.current = null;

    // slight delay to remove loading spinner
    setTimeout(() => {
      setVideoLoading(false);
    }, 1500);

    if (!resolutionCheckTimer.current && updateMachineDims) {
      resolutionCheckTimer.current = setInterval(() => {
        if (
          playerRef.current?.video?.destination?.width &&
          playerRef.current?.video?.destination?.height
        ) {
          const deviceWidth = String(playerRef.current.video.destination.width);
          const deviceHeight = String(playerRef.current.video.destination.height);

          updateMachineDims(deviceWidth, deviceHeight);

          clearInterval(resolutionCheckTimer.current);
          resolutionCheckTimer.current = null;
        }
      }, 1000);
    }
  }, [updateMachineDims, setVideoLoading]);

  useEffect(() => {
    const connectToRTSP = async () => {
      console.log(`LivePreview connectToRTSP ${openFrom}`);
      const { machineRTSPUrl, dockerURL } = selectedCamera;
      if (machineRTSPUrl && dockerURL) {
        if (!canvasRef.current) throw new Error("Ref is null");

        const wssUrl = `wss://${dockerURL}.lotuslabs.co:9110/stream?rtsp=${machineRTSPUrl}`;
        try {
          const options = {
            canvas: canvasRef.current,
            audio: false,
            preserveDrawingBuffer: true,
            onPlay: handleOnPlay,
            onEnded: handleOnDisconnect,
            onVideoDecode: () => {
              if (!videoStarted) {
                setVideoStarted(true);
              }
            },
          };
          playerRef.current = new window.JSMpeg.Player(wssUrl, options);
        } catch (error) {
          console.error(`connectToRTSP error ${openFrom}`, error);
        }
      }
    };

    connectToRTSP();

    // set loading timeout
    loadingTimeout.current = setTimeout(() => {
      // could not load video
      handleNoRtsp && handleNoRtsp();
    }, 10000);

    return () => {
      console.log(`LivePreview unmount ${openFrom}`);
      playerRef.current?.stop();
      playerRef.current?.destroy();
    };
  }, []);

  const handleOnDisconnect = (data) => {
    console.log("LivePreview handleOnDisconnect", data);

    // if disconnect happens while loading, wss connection error is likely, prompt no rtsp instead of throwing error
    if (videoLoading) {
      playerRef.current?.stop();
      playerRef.current?.destroy();
      handleNoRtsp && handleNoRtsp();
      return;
    }
    setVideoFeedError(true);
  };

  // render loading spinner
  const renderLoadingSpinner = () => {
    if (!videoLoading) return null;

    return (
      <Styles.SpinnerContainer>
        <SpinnerComponent width={MASKING_WIDTH} height={MASKING_HEIGHT / 2} />
      </Styles.SpinnerContainer>
    );
  };

  const renderFeedError = () => {
    if (!videoFeedError) return null;

    return (
      <Styles.ErrorContainer>
        <Styles.ErrorMessage>
          There seems to be an issue with the rtsp video feed. Please check the camera connection
          and refresh this page to try again.
        </Styles.ErrorMessage>
      </Styles.ErrorContainer>
    );
  };

  const renderMasks = () => {
    if (videoFeedError || videoLoading || !maskPaths) return null;
    return maskPaths.map((mask, index) => <SvgPolygon mask={mask} index={index} key={index} />);
  };

  return (
    <Styles.PreviewContainer videoStarted={videoStarted} height={selectedCamera.machineHeight}>
      <Styles.LivePreviewCanvas ref={canvasRef} width={0} height={0} />

      {openFrom === PREVIEW && <Styles.LivePreviewMasks>{renderMasks()}</Styles.LivePreviewMasks>}

      <Styles.LivePreviewExtra>
        {renderLoadingSpinner()}
        {renderFeedError()}
      </Styles.LivePreviewExtra>
    </Styles.PreviewContainer>
  );
};

export default LivePreview;
