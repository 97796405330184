import React, { useRef, useEffect, useCallback } from "react";
import Styles from "./styles";

const ModalWithChildren = ({
  showModal,
  setShowModal,
  title,
  subtitle = "",
  children,
  maxWidth50,
  maxWidth500,
  width,
  slim = false,
}) => {
  const modalRef = useRef();

  //Close with ESC
  // const keyPress = useCallback(
  //   (e) => {
  //     if (e.key === "Escape" && showModal) {
  //       setShowModal(false);
  //     }
  //   },
  //   [setShowModal, showModal],
  // );

  // useEffect(() => {
  //   document.addEventListener("keydown", keyPress);
  //   return () => document.removeEventListener("keydown", keyPress);
  // }, [keyPress]);

  // const closeModal = (e) => {
  //   if (modalRef.current === e.target) {
  //     setShowModal(false);
  //   }
  // };

  return (
    <>
      {showModal ? (
        <Styles.Background ref={modalRef}>
          <Styles.ModalWrapper
            slim={slim}
            showModal={showModal}
            width={width}
            maxWidth50={maxWidth50 ? true : false}
            maxWidth500={maxWidth500 ? true : false}
          >
            <Styles.CloseModalButton
              aria-label="Close modal"
              onClick={() => setShowModal((prev) => !prev)}
            />
            <Styles.ModalContent>
              <Styles.TitleContainer>
                <Styles.Title>{title}</Styles.Title>
                <Styles.Subtitle>{subtitle}</Styles.Subtitle>
              </Styles.TitleContainer>
              {children}
            </Styles.ModalContent>
          </Styles.ModalWrapper>
        </Styles.Background>
      ) : null}
    </>
  );
};

export default ModalWithChildren;
