import styled from "styled-components/macro";
import { MdClose } from "react-icons/md";

const styles = {
  Background: styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1000;
  `,
  ModalWrapper: styled.div`
    padding: ${({ slim }) => (slim ? "0.5rem 3rem" : "2rem 5rem")};
    max-width: ${({ maxWidth50, maxWidth500 }) =>
      maxWidth50 ? "50%" : maxWidth500 ? "500px" : null};
    background: ${({ theme }) => theme.surfaces.background};
    position: relative;
    height: 100vh;
    width: ${({ width }) => (width ? `${width}px` : "500px")};
    overflow-y: auto;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  `,
  ModalContent: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
  `,
  TitleContainer: styled.div`
    width: 100%;
    text-align: left;
    margin-right: 5rem;
  `,
  Title: styled.h1`
    font-size: ${({ theme }) => theme.fontSizes.dashboardHeadline};
    color: ${({ theme }) => theme.text.primary};
    margin: 0;
  `,
  Subtitle: styled.p`
    margin: 5px 0 0 0;
    font-size: 12px;
  `,
  CloseModalButton: styled(MdClose)`
    cursor: pointer;
    position: absolute;
    color: ${({ theme }) => theme.colors.neutral.grey400};
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
  `,
  ButtonsContainer: styled.div`
    display: flex;
    gap: 1rem;
  `,
};

export default styles;
