import React from "react";
import { Button } from "./styles";

const BlueBtn = ({
  label,
  icon,
  handleClick,
  whitebg,
  hasBorder,
  width,
  height,
  blueText,
  fontSize,
  fontFamily,
  id,
}) => {
  return (
    <Button
      onClick={() => handleClick()}
      whitebg={whitebg ? true : false}
      hasBorder={hasBorder ? true : false}
      blueText={blueText ? true : false}
      fontSize={fontSize || 14}
      fontFamily={fontFamily}
      width={width}
      height={height}
      id={id}
    >
      {label}
      {icon}
    </Button>
  );
};

export default BlueBtn;
