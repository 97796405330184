import React, { useState, useContext, useEffect } from "react";
import Styles from "./styles";

const FileAssetCard = ({ file, handleFileDelete }) => {
  const { id, name, fileAssetUrl } = file;

  const handleClickDelete = (e) => {
    // delete new or existing file
    // console.log("handleClickDelete", file);
    handleFileDelete(file);

    // prevent event bubbling
    e.stopPropagation();
  };

  // split name to get extension
  const fileExtension = id ? fileAssetUrl.split(".").pop() : name.split(".").pop();
  return (
    <Styles.DragAndDropCardContainer>
      <Styles.DragAndDropCard newCard={!id}>{fileExtension.toUpperCase()}</Styles.DragAndDropCard>
      <Styles.DeleteIconContainer
        // onClick={handleClickDelete}
        onClickCapture={handleClickDelete}
      >
        <Styles.DeleteIcon />
      </Styles.DeleteIconContainer>
    </Styles.DragAndDropCardContainer>
  );
};

export default FileAssetCard;
