import React from "react";
import Styles from "../styles";
//Tools
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
//Components
import ModalWithChildren from "../../../components/Shared/ModalWithChildren";
import DefaultDashBtn from "../../../components/Shared/Buttons/DefaultDashBtn";
import usePreventEnterAction from "../../../hooks/PreventCloseOnEnterHook";
import PrimaryBtn from "components/Shared/Buttons/PrimaryBtn/PrimaryBtn";
import SecondaryBtn from "components/Shared/Buttons/SecondaryBtn/SecondaryBtn";

const addVenueSchema = yup.object().shape({
  venueName: yup.string().required("Venue name is a required field."),
});

const AddVenueModal = ({
  showModal,
  setShowModal,
  title,
  handleModalForwardClick,
  createVenue,
  companyId,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(addVenueSchema),
  });

  const handleClickCancelBtn = () => {
    reset();
    setShowModal(false);
  };

  //Handle submit Form, create Venue
  const submitForm = (venueFormData) => {
    createVenue({
      variables: {
        venueName: venueFormData.venueName,
        companyId: companyId,
      },
    });
    reset();
    setShowModal(false);
  };

  usePreventEnterAction(submitForm);

  return (
    <>
      <ModalWithChildren
        showModal={showModal}
        setShowModal={setShowModal}
        title={title}
        handleModalForwardClick={handleModalForwardClick}
      >
        <Styles.Form onSubmit={handleSubmit(submitForm)}>
          <Styles.InputAndErrorContainer>
            <Styles.Label>Venue Name</Styles.Label>
            <Styles.Input
              type="text"
              name="venueName"
              /* placeholder="Venue name ..." */
              {...register("venueName")}
            />
            <Styles.SubmitError>{errors.venueName?.message}</Styles.SubmitError>
          </Styles.InputAndErrorContainer>
          <Styles.ButtonsContainer>
            <SecondaryBtn
              label="Cancel"
              withIcon={false}
              width="420px"
              height="40px"
              onClick={handleClickCancelBtn}
            />
            <PrimaryBtn
              label="Add"
              withIcon={false}
              width="420px"
              height="40px"
              type="submit"
              id="submit"
            />
          </Styles.ButtonsContainer>
        </Styles.Form>
      </ModalWithChildren>
    </>
  );
};

export default AddVenueModal;
