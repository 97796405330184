import React, { useEffect } from "react";
import Styles from "../../components/Shared/FormPagesLayout/styles";
import { Helmet } from "react-helmet-async";
//Components
import RequestAccount from "../../components/Auth/RequestAccount";
import FormPageLayout from "../../components/Shared/FormPagesLayout";
//Images
import { ReactComponent as RobotImage } from "../../assets/images/Robot.svg";
//Graphql
import { useMutation } from "@apollo/client";
import { REQUEST_ACCOUNT } from "../../graphql/user";
//Tools
import { useToastModal } from "../../context/ToastModalContext";

const RequestAccountPage = () => {
  const [
    requestAccountMutation,
    //TODO Handle graphql loading
    {
      data: requestAccountData,
      /* loading: requestAccountLoading, */
      error: requestAccountError,
    },
  ] = useMutation(REQUEST_ACCOUNT, {
    context: { clientName: "user" },
  });

  const { addToast } = useToastModal();

  useEffect(() => {
    if (requestAccountData) {
      addToast("Account requested successfully! You will hear from us soon.", "success");
    }
    if (requestAccountError) {
      addToast("Something went wrong! Please try again", "error");
    }
  }, [requestAccountData, requestAccountError]);

  return (
    <>
      <Helmet>
        <title>Padmé - Request an Account</title>
        <meta name="description" content="Request an account to access Padmé's services." />
        <link rel="canonical" href="/request-account" />
      </Helmet>
      <FormPageLayout
        formComponent={<RequestAccount requestAccountMutation={requestAccountMutation} />}
      >
        <Styles.ImageContainer>
          <RobotImage />
        </Styles.ImageContainer>
      </FormPageLayout>
    </>
  );
};

export default RequestAccountPage;
