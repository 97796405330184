import React, { useState, useEffect } from "react";
//Styles
import Styles from "./styles";
//Tools
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Link } from "react-router-dom";

//Icons
import { BsEyeFill } from "react-icons/bs";
import { BsEyeSlashFill } from "react-icons/bs";
import { IconContext } from "react-icons";
//Components
import { FormGroup } from "../Shared/FormGroup";
import SpinnerComponent from "../Loader";
import usePreventEnterAction from "../../hooks/PreventCloseOnEnterHook";
import { useToastModal } from "../../context/ToastModalContext";

const resetPassSchema = yup.object().shape({
  password: yup.string().min(4).max(15).required(),
  confirmPassword: yup.string().oneOf([yup.ref("password"), null]),
});

const ResetPassswordForm = ({
  resetPasswordMutation,
  resetPasswordLoading,

  resetPassCode,
  resetPasswordData,
  resetPasswordError,
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(resetPassSchema),
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { addToast } = useToastModal();

  useEffect(() => {
    if (resetPasswordData) {
      addToast("Password reset successfully!", "success");
      reset();
      return;
    }
    if (resetPasswordError) {
      addToast("Something went wrong, please try again or contact our support!", "error");
      reset();
      return;
    }
  }, [resetPasswordData, resetPasswordError, reset]);

  const submitForm = (resetPasswordFormData) => {
    resetPasswordMutation({
      variables: {
        resetCode: resetPassCode,
        password: resetPasswordFormData.password,
      },
    });
  };
  usePreventEnterAction(submitForm);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <Styles.FormContainer>
      <div>
        <Styles.Title>Reset your password!</Styles.Title>
      </div>
      <Styles.Form onSubmit={handleSubmit(submitForm)}>
        <FormGroup labelText={"Type your new password"} errorMessage={errors.password?.message}>
          <Styles.Input
            type={showPassword ? "text" : "password"}
            name="password"
            {...register("password")}
          />
          <IconContext.Provider value={{ size: 25, color: "#37474f" }}>
            <Styles.IconWrapper onClick={handleShowPassword}>
              {showPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
            </Styles.IconWrapper>
          </IconContext.Provider>
        </FormGroup>
        <FormGroup
          labelText={"Confirm your new password"}
          errorMessage={errors?.confirmPassword && "Passwords must match!"}
        >
          <Styles.Input
            type={showConfirmPassword ? "text" : "password"}
            name="confirmPassword"
            {...register("confirmPassword")}
          />
          <IconContext.Provider value={{ size: 25, color: "#37474f" }}>
            <Styles.IconWrapper onClick={handleShowConfirmPassword}>
              {showConfirmPassword ? <BsEyeSlashFill /> : <BsEyeFill />}
            </Styles.IconWrapper>
          </IconContext.Provider>
        </FormGroup>
        {resetPasswordLoading ? (
          <SpinnerComponent />
        ) : (
          <Styles.SubmitInput type="submit" id="submit" value="Submit" />
        )}
      </Styles.Form>
      <Styles.Paragraph>
        Back to <Link to="/signin">Sign in!</Link>
      </Styles.Paragraph>
    </Styles.FormContainer>
  );
};

export default ResetPassswordForm;
