import React from "react";
import { withDraggable } from "./withDraggable";

export const SVGPoint = withDraggable(
  React.forwardRef(function SVGPoint(
    { x, y, onClickTouchEvent, draggable, style, color, idx, pathIndex, deletePointFromPath },
    ref,
  ) {
    const { cursor = draggable ? "move" : "default", ...rest } = style;

    return (
      <rect
        style={{
          cursor,
          ...rest,
        }}
        ref={ref}
        x={x - 10}
        y={y - 10}
        width="20px"
        height="20px"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onClickTouchEvent(e);
        }}
        onContextMenu={() => {
          deletePointFromPath(pathIndex, idx);
        }}
        onTouchEnd={(e) => {
          e.stopPropagation();
          e.preventDefault();
          onClickTouchEvent(e);
        }}
        fill="rgba(0, 0, 0, 0)"
        stroke={color || "white"}
        strokeWidth="2px"
        vectorEffect="non-scaling-stroke"
        // vectorEffect="non-scaling-size" will be featured in SVG 2.0!
      />
    );
  }),
);
