import React, { useState, createContext } from "react";
import { machineModelData } from "../configs/configEnviroment";

export const DashboardContext = createContext({});

const DashboardContextProvider = ({ children }) => {
  const [selectedCamera, setselectedCamera] = useState(null);
  const [companiesList, setCompaniesList] = useState([]);
  const [companiesDict, setCompaniesDict] = useState([]);
  const [objectDetectionItems, setObjectDetectionItems] = useState([]);
  const [cameraLoading, setCameraLoading] = useState(false);
  const [maskUpdated, setMaskUpdated] = useState(false);
  const [padmeModels, setPadmeModels] = useState(
    machineModelData
      // .filter((config) => config.status === 1)
      .map((config) => ({
        label: config.title,
        value: config.key,
        ...config,
      })),
  );
  const [padmeModelColors, setPadmeModelColors] = useState([]);
  const [padmeModelTypes, setPadmeModelTypes] = useState([]);
  const [availableModels, setAvailableModels] = useState([]);

  return (
    <DashboardContext.Provider
      value={{
        setObjectDetectionItems,
        objectDetectionItems,
        selectedCamera,
        setselectedCamera,
        companiesList,
        setCompaniesList,
        companiesDict,
        setCompaniesDict,
        cameraLoading,
        setCameraLoading,
        maskUpdated,
        setMaskUpdated,
        padmeModels,
        padmeModelColors,
        setPadmeModelColors,
        padmeModelTypes,
        setPadmeModelTypes,
        availableModels,
        setAvailableModels,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardContextProvider;
