import React from "react";

import Styles from "./styles";

const ProgressBar = ({ progress }) => {
  return (
    <Styles.ProgressBarContainer>
      <Styles.ProgressBar progress={progress} />
    </Styles.ProgressBarContainer>
  );
};

export default ProgressBar;
