import React, { useState, useEffect } from "react";
//Styles
import Styles from "./styles";
//Images
import { ReactComponent as RobotImage } from "../../assets/images/Robot.svg";
//Tools
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
//Components
import ValidateInvite from "../../components/Auth/ValidateInvite";
import FormPageLayout from "../../components/Shared/FormPagesLayout";
import SpinnerComponent from "../../components/Loader";
//Graphql
import { useMutation } from "@apollo/client";
import { VALIDATE_INVITE_CODE, COMPLETE_INVITE } from "../../graphql/user";

const ValidateInvitePage = () => {
  const [valideInvitationCode, setValideInvitationCode] = useState(false);
  const [valideRegistration, setValideRegistration] = useState(false);
  const [registrationError, setRegistrationError] = useState("");
  const [invalideCodeError, setInvalideCondeError] = useState("");
  const [userEmailAndCompany, setUserEmailAndCompany] = useState({});
  const navigate = useNavigate();
  const { inviteCode } = useParams();

  const [
    validateInviteCode,
    {
      data: validateInviteCodeData,
      loading: validateInviteCodeLoading,
      error: validateInviteCodeError,
    },
  ] = useMutation(VALIDATE_INVITE_CODE, {
    context: { clientName: "user" },
  });

  const [
    completeInvite,
    { data: completeInviteData, loading: completeInviteLoading, error: completeInviteError },
  ] = useMutation(COMPLETE_INVITE, {
    context: { clientName: "user" },
  });

  useEffect(() => {
    if (inviteCode) {
      validateInviteCode({
        variables: {
          inviteCode: inviteCode,
        },
      });
    }
  }, [inviteCode, validateInviteCode]);

  useEffect(() => {
    if (validateInviteCodeData) {
      if (validateInviteCodeData.checkInvite.inviteValid) {
        setValideInvitationCode(true);
        setUserEmailAndCompany({
          companyName: validateInviteCodeData.checkInvite.companyName,
          userEmail: validateInviteCodeData.checkInvite.userEmail,
        });
      }
    }
    if (validateInviteCodeError) {
      setInvalideCondeError(validateInviteCodeError.message);
    }
  }, [validateInviteCodeData, validateInviteCodeError]);

  useEffect(() => {
    if (completeInviteData) {
      setValideRegistration(true);
      navigate("/signin");
    }
    if (completeInviteError) {
      setRegistrationError(completeInviteError.message);
    }
  }, [completeInviteData, completeInviteError, navigate, valideRegistration]);

  return !validateInviteCodeLoading && valideInvitationCode ? (
    <FormPageLayout
      formComponent={
        <ValidateInvite
          completeInvite={completeInvite}
          inviteCode={inviteCode}
          registrationError={registrationError}
          completeInviteLoading={completeInviteLoading}
          userEmailAndCompany={userEmailAndCompany}
        />
      }
    >
      <Styles.ImageContainer validate>
        <RobotImage />
      </Styles.ImageContainer>
    </FormPageLayout>
  ) : invalideCodeError ? (
    <Styles.PageContainer>
      <Styles.AlignmentContainer>
        <Styles.ErrorAndLoadingContainer>
          <Styles.ErrorMessage>{invalideCodeError}!</Styles.ErrorMessage>
        </Styles.ErrorAndLoadingContainer>
      </Styles.AlignmentContainer>
    </Styles.PageContainer>
  ) : (
    <Styles.PageContainer>
      <Styles.AlignmentContainer>
        <Styles.ErrorAndLoadingContainer>
          <SpinnerComponent width={100} height={100} />
        </Styles.ErrorAndLoadingContainer>
      </Styles.AlignmentContainer>
    </Styles.PageContainer>
  );
};

export default ValidateInvitePage;
