export const infoSpacerText = {
  howItWorksTitle: "How Padme Works?",
  howItWorksDescription:
    "Padmé lets you easily configure a venue and its edge devices to run any machine learning model in a matter of a few clicks. The dashboard gives you a birds-eye view of the venues and edge devices involved along with the desired insights about the venue, such as crowd count, crowd surging, average time spent in a queue, and the number of cars entering or exiting, etc.",
  workCultureTitle: "Our Work Culture",
  workCultureDescription:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget tempus nunc, ac vulputate eros. Ut interdum, risus ut tristique maximus, lacus ante convallis turpis, ut volutpat ex lectus id nibh. Sed dapibus molestie ante a consectetur. Fusce malesuada vehicula eros ut vestibulum.",
};

export const talkToUsSectionText = "Ready to transform your company into an AI-driven enterprise?";

export const modalTitles = {
  noCamerasSelected: "No cameras selected, please select at least one camera!",
  stopAllInferencesConfirmation: "Stop Inference on all selected cameras?",
  deleteCamerasConfirmation: "Are you sure you want to delete the selected camera(s)?",
  deleteAreasConfimation: "Delete Area ?",
  startAllInferencesConfirmation:
    "Are you sure you want to start inference on the selected camera(s)?",
  removeUserFromVenueConfirmation:
    "Are you sure you want to remove the selected user from this venue?",
  removeUserFromCompanyConfirmation:
    "Are you sure you want to remove the selected user from this company?",
  startInferenceConfirmation: "Start Inference ?",
  stopInferenceConfirmation: "Stop Inference ?",
  updatedMasking: "Masking update warning:",
  restartInferenceConfirmation:
    "For changes to take effect you have to restart inference. Do you want to restart it now?",
};
