import React from "react";

// Styles
import Styles from "./styles";

// components
const SpinnerComponent = (props) => {
  const { width, height, color } = props;

  return (
    <Styles.Spinner viewBox="0 0 50 50" width={width} height={height} color={color}>
      <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="2" />
    </Styles.Spinner>
  );
};

export default SpinnerComponent;
