import React, { useState } from "react";
// Styles
import { sharedTableStyles as Styled } from "../../Shared/Shared.styled";
// Components
import Icon from "components/Shared/Icon/Icon";
// Utils
import { timeStampToDateString } from "configs/helpers";
import { basicStatus } from "configs/configEnviroment";

const Row = ({ item, updateItem }) => {
  const [isCopied, setIsCopied] = useState(false);
  const startDate = timeStampToDateString(item.createdAt);
  const lastUseDate = timeStampToDateString(item.lastUsed);

  const copyKey = async (text) => {
    if ("clipboard" in navigator) {
      // addToast("Output Link Copied successfully!", "success");
      setIsCopied(true);
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  return (
    <Styled.TableRowEntriesContainer>
      <Styled.TableRowEntries>{item.apiName}</Styled.TableRowEntries>
      <Styled.TableRowEntries narrow="true">
        <div onClick={() => copyKey(item.apiKey)}>{!isCopied ? "[Copy Link]" : "[Copied]"}</div>
      </Styled.TableRowEntries>
      <Styled.TableRowEntries>{startDate}</Styled.TableRowEntries>
      <Styled.TableRowEntries>{lastUseDate}</Styled.TableRowEntries>
      <Styled.TableRowEntries>{basicStatus[item.apiStatus]}</Styled.TableRowEntries>
      <Styled.TableRowEntries style={{ textAlign: "right" }}>
        <Icon name={"menu"} className="action-icon" size="15px" onClick={() => updateItem(item)} />
      </Styled.TableRowEntries>
    </Styled.TableRowEntriesContainer>
  );
};

const ApiTable = ({ data, updateItem }) => {
  const renderNestedRows = () => {
    return data.map((item) => {
      return (
        <Styled.TableRow key={item.id}>
          <Row item={item} updateItem={updateItem} />
        </Styled.TableRow>
      );
    });
  };

  return (
    <Styled.TableContainer>
      <Styled.Table className="api-table">
        <Styled.TableHeader>
          <Styled.TableHeaderEntriesContainer>
            <Styled.TableHeaderEntries>Name</Styled.TableHeaderEntries>
            <Styled.TableHeaderEntries narrow="true">Key</Styled.TableHeaderEntries>
            <Styled.TableHeaderEntries>Created</Styled.TableHeaderEntries>
            <Styled.TableHeaderEntries>Last used</Styled.TableHeaderEntries>
            <Styled.TableHeaderEntries>Status</Styled.TableHeaderEntries>
          </Styled.TableHeaderEntriesContainer>
        </Styled.TableHeader>

        {data.length > 0 ? (
          renderNestedRows()
        ) : (
          <Styled.EmptyMessage>No records found</Styled.EmptyMessage>
        )}
      </Styled.Table>
    </Styled.TableContainer>
  );
};

export default ApiTable;
