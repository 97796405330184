import React, { useEffect, useRef, useState } from "react";
// Styles
import Styles from "../../PlayerContainer/styles";
//Components
import MultiSelect from "../../PlayerContainer/Masking";
import LivePreview from "../../PlayerContainer/VideoPreview/LivePreview";
import { useToastModal } from "../../../context/ToastModalContext";
import BaseModal from "../BaseModal/BaseModal";
//Graphql
import { UPDATE_MACHINE_VECTORS } from "../../../graphql/graph";
import { useMutation } from "@apollo/client/react";

const MASKING_WIDTH = 960;
const MASKING_HEIGHT = 540;

const MaskingModal = ({
  showModal,
  setShowModal,
  title,
  handleModalForwardClick,
  selectedCamera,
  staticPreviewUrl,
}) => {
  const placeholderCanvasRef = useRef(null);
  const [imageBase64, setImageBase64] = useState(null);
  const [maskSetupComplete, setMaskSetupComplete] = useState(false);
  const [maskingHeight, setMaskingHeight] = useState(MASKING_HEIGHT);

  const [updateMachineVectors] = useMutation(UPDATE_MACHINE_VECTORS, {
    context: { clientName: "graph" },
  });

  useEffect(() => {
    // setup container with latest data
    if (showModal) {
      const machineMaskingScale = selectedCamera.machineMaskingScale
        ? parseFloat(selectedCamera.machineMaskingScale)
        : 1;
      const newMaskingHeight = selectedCamera.machineHeight
        ? parseInt(selectedCamera.machineHeight) * machineMaskingScale
        : MASKING_HEIGHT;

      const context = placeholderCanvasRef.current.getContext("2d");
      context.clearRect(0, 0, MASKING_WIDTH, newMaskingHeight);
      const imgPlaceholderBase64 = placeholderCanvasRef.current.toDataURL();

      setMaskingHeight(newMaskingHeight);

      setImageBase64(imgPlaceholderBase64);

      setMaskSetupComplete(true);
    }
  }, [showModal, selectedCamera, staticPreviewUrl]);

  const { addToast } = useToastModal();

  const saveMask = async (polyArray) => {
    // stores poly array
    let missingMaskWaitTime = false;
    const polyPayload = polyArray
      .filter((row) => row.points && row.points.length && row.closed)
      .map((row) => {
        const { points, closed, ...rest } = row;
        if (row.maskWaitTime === "") {
          missingMaskWaitTime = true;
        }
        return {
          points: points.map((point) => {
            return {
              x: point.x,
              y: point.y,
            };
          }),
          ...rest,
        };
      });

    if (missingMaskWaitTime) {
      addToast("Please enter a wait time for each mask", "error");
      return;
    }

    await updateMachineVectors({
      variables: {
        id: selectedCamera.id,
        machineVectors: JSON.stringify(polyPayload, null, 0),
      },
    });

    addToast("Masking updated!", "success");
  };

  return (
    <>
      <BaseModal
        showModal={showModal}
        setShowModal={setShowModal}
        title={title}
        slim
        handleModalForwardClick={handleModalForwardClick}
      >
        <Styles.MaskingWrapper width={MASKING_WIDTH} height={maskingHeight + 50}>
          <Styles.MaskingWrapperImg width={MASKING_WIDTH} height={maskingHeight}>
            {staticPreviewUrl ? (
              <img
                src={staticPreviewUrl}
                alt="staticPreviewUrl"
                style={{
                  width: MASKING_WIDTH,
                  height: maskingHeight,
                }}
              />
            ) : (
              <LivePreview
                selectedCamera={selectedCamera}
                width={MASKING_WIDTH}
                height={maskingHeight}
                openFrom="masking"
              />
            )}
          </Styles.MaskingWrapperImg>
          {maskSetupComplete && (
            <MultiSelect
              width={MASKING_WIDTH}
              height={maskingHeight}
              src={imageBase64}
              inferenceModel={selectedCamera.modelVersionId}
              vectors={selectedCamera.machineVectors}
              saveMask={saveMask}
              closeMaskingModal={() => setShowModal(false)}
            />
          )}
        </Styles.MaskingWrapper>
        <canvas
          id="PlaceHolderCanvas"
          width={"100%"}
          height={"100%"}
          ref={placeholderCanvasRef}
          style={{ display: "none" }}
        />
      </BaseModal>
    </>
  );
};

export default MaskingModal;
